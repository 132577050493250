<template>
  <div>
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>

    <v-form
      v-else
      @submit.prevent="submit"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <!-- <v-row justify="center">
        <v-col cols="5">
          <v-btn
            :loading="$store.state.loading"
            @click="$router.push(`${$route.path}/ergolavikh-symvash-ergou`)"
            block
            tile
            color="primary darken-2"
            >εργολαβικη συμβαση</v-btn
          >
          <v-btn
            :loading="$store.state.loading"
            @click="programmatikhSumvash"
            block
            tile
            color="primary darken-2"
            class="mt-2"
            >προγραμματικη συμβαση</v-btn
          >
          <v-btn
            :loading="$store.state.loading"
            @click="$router.push(`${$route.path}/financial-details`)"
            block
            tile
            color="primary darken-2"
            class="mt-2"
            >Διαχειριση οικονομικων στοιχειων</v-btn
          >
          <v-btn
            @click="$router.push(`${$route.path}/istoriko-tropopoihsewn`)"
            block
            tile
            color="primary darken-2"
            class="mt-2"
            >ιστορικο τροποποιησεων</v-btn
          >
          <v-btn
            @click="$router.push(`${$route.path}/tropopoihsh-stoixeiwn-ergou`)"
            block
            dark
            tile
            color="green"
            class="mt-2"
            >ΤΡΟΠΟΠΟΙΗΣΕΙΣ ΕΡΓΟΥ</v-btn
          >
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <v-col cols="5">
          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Τίτλος*"
                :error-messages="titleErrors"
                v-model="title"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <span>
              {{ title }}
            </span>
          </v-tooltip>

          <v-text-field label="ΚΑΕ" v-model="kae" readonly></v-text-field>

          <v-autocomplete
            label="Περιφερειακή ενότητα/τομέας"
            :error-messages="unitErrors"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.units"
            v-model="unitId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Φάση έργου αρχή έτους"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.phases"
            v-model="newYearJobPhaseId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Δήμος"
            item-value="id"
            item-text="name"
            :items="availableMunicipalities"
            v-model="municipalityId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Φάση έργου"
            item-value="id"
            :error-messages="jobPhaseErrors"
            item-text="name"
            :items="this.$store.state.projects.phases"
            v-model="jobPhaseId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Μελέτη"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.researches"
            v-model="jobResearchId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Είδος έργου"
            item-value="id"
            :error-messages="jobTypesErrors"
            item-text="name"
            :items="this.$store.state.projects.types"
            v-model="jobTypeId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <!-- <v-text-field label="Θέση" v-model="place"></v-text-field> -->

          <v-autocomplete
            label="Φορέας αρμοδιότητας"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            v-model="propertyEntity"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-autocomplete
            label="Φορέας υλοποίησης"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            v-model="implementingEntity"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <v-tooltip bottom max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Αποφάσεις απένταξης/τροποποίησης"
                v-model="decisions"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <span>{{ decisions }}</span>
          </v-tooltip>

          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-text-field
              class="mr-5"
              label="Αιτήματα που συσχετίζονται με το έργο"
              v-model="searchText"
            >
            </v-text-field>
            <v-btn class="mr-2" text outlined @click="search(searchText)"
              >Αναζητηση</v-btn
            >
            <v-btn text outlined @click="searchResults = []">Αποκρυψη</v-btn>
          </div>
        </v-col>

        <v-col cols="5" offset="1">
          <v-text-field
            label="Διευθύνουσα υπηρεσία"
            v-model="managementService"
            readonly
          ></v-text-field>

          <v-text-field
            label="Προϋπολογισμός (προ δημοπράτησης)*"
            :error-messages="budgetErrors"
            v-model="budgetBeforeAuction"
            :hint="budgetBeforeAuction | currency"
            persistent-hint
            readonly
          >
          </v-text-field>

          <v-text-field
            label="Εγκεκριμένες πιστώσεις*"
            v-model="credits"
            :hint="credits | currency"
            :error-messages="creditsErrors"
            persistent-hint
            readonly
          ></v-text-field>
          <!-- <v-text-field label="Ανάδοχος" v-model="contractor"></v-text-field> -->

          <v-autocomplete
            label="Κατηγορία έργου"
            item-value="id"
            :error-messages="jobCategoryErrors"
            class="mt-4"
            item-text="name"
            :items="this.$store.state.projects.categories"
            v-model="jobCategoryId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

          <!-- <v-text-field label="Αντισυμβαλλόμενοι" v-model="jobPartners"></v-text-field> -->
          <v-autocomplete
            label="Άξονας"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.axis"
            v-model="jobAxisId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>
          <v-autocomplete
            label="Μέτρο"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.measures"
            v-model="jobMeasureId"
            readonly
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>
          <v-autocomplete
            label="Στόχος"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.goals"
            readonly
            v-model="jobGoalId"
          >
            <template v-slot:append>
              <span></span>
            </template>
          </v-autocomplete>

           <v-text-field
               label="Εγκεκριμένος προϋπολογισμός(νομική δέσμευση)"
               v-model="confirmedBudget"
               readonly
           ></v-text-field>

          <v-text-field
            label="Ένταξη στο ΠΕΕ"
            v-model="budgetComments"
            readonly
          ></v-text-field>

          <v-col class="pa-0">
            <v-card>
              <v-card-title>
                <h1 class="title">Αρχεία</h1>
              </v-card-title>
              <v-card-text>
                <v-file-input chips multiple label="Αρχεία" v-model="files">
                  <template v-slot:selection="{ index, text }">
                    <v-chip close @click:close="deleteChip(index, text)">{{
                      text
                    }}</v-chip>
                  </template>
                </v-file-input>

                <v-btn :disabled="files.length < 1" @click="upload"
                  >Αρχειοθετηση</v-btn
                >

                <v-data-table
                  hide-default-footer
                  :headers="fileHeaders"
                  :items="peeJobAttachments"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex align-center">
                      <v-btn icon @click="deleteFile(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn icon @click="download(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-card v-if="selected.length > 0">
            <v-card-title
              >ΕΠΙΛΕΓΜΕΝΑ ΑΙΤΗΜΑΤΑ ΠΟΥ ΣΧΕΤΙΖΟΝΤΑΙ ΜΕ ΤΟ ΕΡΓΟ</v-card-title
            >
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>Λεπτομερειες</th>
                    <th>ΤΙΤΛΟΣ</th>
                    <th>ΤΟΜΕΑΣ</th>
                    <th>ΔΗΜΟΣ</th>
                    <th>ΠΡΩΤΟΚΟΛΛΟ</th>
                    <th>ΕΡΕΥΝΑ</th>
                    <th>ΕΙΔΟΣ ΕΡΓΟΥ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in selected" :key="item.id">
                    <td>
                      <div style="display: flex">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="seeMoreInfo(item)"
                              >mdi-information</v-icon
                            >
                          </template>
                          <span>Λεπτομέρειες</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="remove(item)" class="ml-3"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Διαγραφή</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.unit ? item.unit.name : "" }}</td>
                    <td>
                      {{ item.municipality ? item.municipality.name : "" }}
                    </td>
                    <td>{{ item.protocolNumber }}</td>
                    <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>
                    <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-divider v-if="selected.length > 0"></v-divider>

          <v-card class="mt-10" v-if="searchResults.length > 0">
            <v-card-title>ΑΠΟΤΕΛΕΣΜΑΤΑ</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>επιλογη</th>
                    <th>ΤΙΤΛΟΣ</th>
                    <th>ΤΟΜΕΑΣ</th>
                    <th>ΔΗΜΟΣ</th>
                    <th>ΠΡΩΤΟΚΟΛΛΟ</th>
                    <th>ΕΡΕΥΝΑ</th>
                    <th>ΕΙΔΟΣ ΕΡΓΟΥ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchResults" :key="item.id">
                    <td>
                      <!-- <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            
                          </template>
                          <span>Προσθήκη</span>
                      </v-tooltip>-->
                      <v-icon
                        v-if="check(item) && selected.length < 1"
                        @click="add(item)"
                        >mdi-plus</v-icon
                      >
                    </td>
                    <td>{{ item.title ? item.title : "" }}</td>
                    <td>{{ item.unit ? item.unit.name : "" }}</td>
                    <td>
                      {{ item.municipality ? item.municipality.name : "" }}
                    </td>
                    <td>{{ item.protocolNumber }}</td>
                    <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>
                    <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>

            <div style="display: flex; padding-top: 50px; padding-bottom: 40px">
              <v-icon
                v-if="page > 1"
                @click="
                  page -= 1;
                  search(searchText);
                "
                class="ml-12 pl-12"
                >mdi-arrow-left</v-icon
              >
              <v-spacer></v-spacer>
              <v-icon
                v-if="page < pages"
                @click="
                  page += 1;
                  nextSearch(searchText);
                "
                class="mr-12 pr-12"
                >mdi-arrow-right</v-icon
              >
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="11">
          <v-textarea
            outlined
            label="Παρατηρήσεις"
            v-model="comments"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row justify="center" class="mt-10">
        <v-btn
          width="20%"
          class="mr-2"
          :loading="$store.state.loading"
          dark
          tile
          color="primary darken-2"
          @click="submit"
          >αποθηκευση</v-btn
        >

        <v-btn
          width="20%"
          dark
          tile
          color="primary darken-2"
          @click="$router.push('/projects')"
          >ακυρο</v-btn
        >
      </v-row>
    </v-form>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>ΛΕΠΤΟΜΕΡΕΙΕΣ</v-card-title>
        <v-card-text>
          <v-text-field
            disabled
            label="ΤΙΤΛΟΣ"
            :value="detailedItem ? detailedItem.title : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΕΙΔΟΣ ΕΡΓΟΥ"
            :value="detailedItem.jobType ? detailedItem.jobType.name : ''"
          >
          </v-text-field>
          <v-text-field
            disabled
            label="ΕΡΕΥΝΑ"
            :value="
              detailedItem.jobResearch ? detailedItem.jobResearch.name : ''
            "
          ></v-text-field>
          <!-- <v-text-field disabled label="ΘΕΣΗ" :value="detailedItem ? detailedItem.place : ''"></v-text-field> -->
          <v-text-field
            disabled
            label="ΤΟΜΕΑΣ"
            :value="detailedItem.unit ? detailedItem.unit.name : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΔΗΜΟΣ"
            :value="
              detailedItem.municipality ? detailedItem.municipality.name : ''
            "
          ></v-text-field>
          <v-text-field
            disabled
            label="ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ"
            :value="detailedItem ? detailedItem.protocolNumber : ''"
          >
          </v-text-field>
          <v-text-field
            disabled
            label="ΗΜΕΡΟΜΗΝΙΑ ΠΡΩΤΟΚΟΛΛΟΥ"
            :value="detailedItem ? detailedItem.protocolNumberDate : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ"
            :value="detailedItem ? detailedItem.budget : ''"
          ></v-text-field>
          <v-text-field
            disabled
            class="mt-4"
            label="ΠΑΡΑΤΗΡΗΣΕΙΣ"
            :value="detailedItem ? detailedItem.comments : ''"
          >
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, decimal } from "vuelidate/lib/validators";
export default {
  async created() {
    try {
      await this.$store.dispatch(
        "setProjectEditing",
        this.$route.params.project_id
      );
      this.budgetBeforeAuction =
        this.$store.state.projects.projectEditing.budgetBeforeAuction;
      this.credits = this.$store.state.projects.projectEditing.credits || null;
      this.comments = this.$store.state.projects.projectEditing.comments;
      // contractor= this.$store.state.projects.projectEditing.contractor;
      this.implementingEntity =
        this.$store.state.projects.projectEditing.implementingEntity;
      // jobPartners= this.$store.state.projects.projectEditing.jobPartners;
      this.kae = this.$store.state.projects.projectEditing.kae;
      this.managementService =
        this.$store.state.projects.projectEditing.managementService;
      // place= this.$store.state.projects.projectEditing.place;
      this.propertyEntity =
        this.$store.state.projects.projectEditing.propertyEntity;
      this.title = this.$store.state.projects.projectEditing.title;
      this.unitId = this.$store.state.projects.projectEditing.unit
        ? this.$store.state.projects.projectEditing.unit.id
        : "";
      this.jobCategoryId = this.$store.state.projects.projectEditing.jobCategory
        ? this.$store.state.projects.projectEditing.jobCategory.id
        : "";
      this.jobPhaseId = this.$store.state.projects.projectEditing.jobPhase
        ? this.$store.state.projects.projectEditing.jobPhase.id
        : "";
      this.jobRequestsIds = this.$store.state.projects.projectEditing
        .jobRequests
        ? this.$store.state.projects.projectEditing.jobRequests.id
        : "";
      this.jobResearchId = this.$store.state.projects.projectEditing.jobResearch
        ? this.$store.state.projects.projectEditing.jobResearch.id
        : "";
      this.jobTypeId = this.$store.state.projects.projectEditing.jobType
        ? this.$store.state.projects.projectEditing.jobType.id
        : "";
      this.jobAxisId = this.$store.state.projects.projectEditing.jobAxis
        ? this.$store.state.projects.projectEditing.jobAxis.id
        : "";
      this.jobMeasureId = this.$store.state.projects.projectEditing.jobMeasure
        ? this.$store.state.projects.projectEditing.jobMeasure.id
        : "";
      this.jobGoalId = this.$store.state.projects.projectEditing.jobGoal
        ? this.$store.state.projects.projectEditing.jobGoal.id
        : "";
      this.newYearJobPhaseId = this.$store.state.projects.projectEditing
        .newYearJobPhase
        ? this.$store.state.projects.projectEditing.newYearJobPhase.id
        : "";
      this.municipalityId = this.$store.state.projects.projectEditing
        .municipality
        ? this.$store.state.projects.projectEditing.municipality.id
        : "";
      // fundingEntity: this.$store.state.projects.projectEditing.fundingEntity;
      this.budgetComments = this.$store.state.projects.projectEditing.budgetComments;
      this.confirmedBudget = this.$store.state.projects.projectEditing.confirmedBudget;
      this.peeJobAttachments = this.$store.state.projects.projectEditing.peeJobAttachments;
      this.selected = this.$store.state.projects.projectEditing.jobRequests;
      this.decisions = this.$store.state.projects.projectEditing.decisions;
      await this.$store.dispatch("getNewPeeJobData");
      this.pageLoader = false;
    } catch (e) {
      console.log(e);
      this.pageLoader = false;
    }


    // this.selected;

    // console.log("editing id", this.$store.state.projects.projectEditing.id);
    // console.log(
    //   this.$store.state.projects.projectEditing.hasPendingModifications
    // );
    if (this.$store.state.projects.projectEditing.hasPendingModifications) {
      this.color = "green darken-2";
    }
  },
  data() {
    return {
      pageLoader: true,

      resultsCounter: 0,
      page: 1,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      valid: true,
      dialog: null,

      jobConstructorContractTempDeliveryFiles: [],
      jobConstructorContractFinalDeliveryFiles: [],
      jobConstructorContractCompletionCertificateFiles: [],

      decisions: null,
      detailedItem: {},
      budgetBeforeAuction: 0,
      credits: 0,
      comments: "",
      implementingEntity: "",
      kae: "",
      managementService: "",
      propertyEntity: "",
      title: "",
      unitId: 0,
      jobCategoryId: 0,
      jobPhaseId: 0,
      jobResearchId: 0,
      jobTypeId: 0,
      jobAxisId: 0,
      jobMeasureId: 0,
      jobGoalId: 0,
      newYearJobPhaseId: 0,
      municipalityId: 0,
      budgetComments: "",
      confirmedBudget: 0,
      peeJobAttachments: [],


      searchText: "",
      searchResults: [],
      selected: [],

      fileHeaders: [
        {
          text: "Όνομα",
          value: "filename",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      files: [],
    };
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    save3(date) {
      this.$refs.menu3.save(date);
    },
    save4(date) {
      this.$refs.menu4.save(date);
    },
    save5(date) {
      this.$refs.menu5.save(date);
    },
    add(item) {
      if (!this.selected.some((e) => e.id == item.id)) {
        this.selected.push(item);

        this.title = item.title;
        this.comments = item.comments;
        this.unitId = item.unit.id;
        this.municipalityId = item.municipality.id;
        this.jobTypeId = item.jobType.id;
        this.budgetBeforeAuction = item.budget;

        return true;
      }
    },
    check(item) {
      if (!this.selected.some((e) => e.id == item.id)) {
        return true;
      }
    },
    remove(item) {
      for (let i in this.selected) {
        if (this.selected[i] == item) {
          this.selected.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      let jobRequestsIds = [];
      for (let item in this.selected) {
        jobRequestsIds.push(this.selected[item].id);
      }

      const credentials = {
        id: this.$store.state.projects.projectEditing.id,
        peeJobParameters: {
          comments: this.comments,
          jobRequestId: jobRequestsIds.length > 0 ? jobRequestsIds[0] : null,
          //end todo
          peeJobAttachmentsIds: this.peeJobAttachmentsIds,
          // budgetBeforeAuction: parseFloat(this.budgetBeforeAuction),
          // contractor: this.contractor,
          // implementingEntity: this.implementingEntity,
          // jobAxisId: parseInt(this.jobAxisId),
          // jobCategoryId: parseInt(this.jobCategoryId),
          // jobGoalId: parseInt(this.jobGoalId),
          // jobMeasureId: parseInt(this.jobMeasureId),
          // jobPartners: this.jobPartners,
          // jobPhaseId: parseInt(this.jobPhaseId),
          //todo
          // jobResearchId: parseInt(this.jobResearchId),
          // credits: parseFloat(this.credits),
          // jobTypeId: parseInt(this.jobTypeId),
          // kae: this.kae,
          // managementService: this.managementService,
          // newYearJobPhaseId: parseInt(this.newYearJobPhaseId),
          // municipalityId: parseInt(this.municipalityId),
          // place: this.place,
          // propertyEntity: this.propertyEntity,
          // title: this.title,
          // unitId: this.unitId,
          // fundingEntity: this.fundingEntity,
          // joinPee: this.joinPee,
        },
      };
      this.$store
        .dispatch("editPeeProject", credentials)
        .then((res) => {
          // console.log(res);
          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);

            return;
          }
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
          // this.$router.push("/projects");
          //test
        })
        .catch((err) => {
          console.log("erros", err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },
    search(text) {
      this.page = 1;
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/search/job/request?query=${text}&page=${this.page}`
        )
        .then((res) => {
          // console.log("searchRes", res);
          this.searchResults = res.data.data.documents;
          this.resultsCounter = res.data.data.resultsCounter;
          // console.log("results are : ", this.resultsCounter);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextSearch(text) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/search/job/request?query=${text}&page=${this.page}`
        )
        .then((res) => {
          // console.log("searchRes", res);
          this.searchResults = res.data.data.documents;
          this.resultsCounter = res.data.data.resultsCounter;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    seeMoreInfo(item) {
      this.detailedItem = item;
      this.dialog = true;
    },

    programmatikhSumvash() {
      this.$router.push(`${this.$route.path}/programmatikh-symvash-ergou`);
    },

    upload() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          // console.log(res);
          res.data.data.forEach((item) => {
            this.peeJobAttachments.push(item);
          });

          console.log(this.peeJobAttachments);
          this.files = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch(() => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    download(item) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/download/${item.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteFile(id) {
      // this.$store.dispatch('deleteFile', id)
      // .then(() => {
      //   this.requestAttachments.splice(this.requestAttachments.findIndex(item => item.id == id),1)
      // })
      // .catch(err => {
      //   console.log(err)
      // })

      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              peeJobId: this.$store.state.projects.projectEditing.id,
            },
          }
        )
        .then(() => {
          this.peeJobAttachments.splice(
            this.peeJobAttachments.findIndex((item) => item.id == id),
            1
          );

          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    greekFormatDate(date) {
      if (!date) return "";
      return moment(moment.utc(date).toDate()).local().format("DD/MM/YYYY");
    },
  },
  computed: {
    availableMunicipalities() {
      return this.$store.state.requests.municipalities.filter(
        (municipality) => {
          if (municipality.unit != null)
            if (municipality.unit.id == this.unitId) return municipality;
        }
      );
    },
    entitiesAndMunicipalities() {
      return this.$store.state.requests.municipalities.concat(
        this.$store.state.requests.entities
      );
    },
    project() {
      return this.$store.state.projects.projectEditing;
    },
    pages() {
      return Math.ceil(this.resultsCounter / 20);
    },
    // color() {
    //   if (this.$store.state.projects.projectEditing.hasPendingModifications)
    //     return "green";
    //   return "primary darken-2";
    // },
    peeJobAttachmentsIds() {
      if (this.peeJobAttachments.length == 0) return [];
      return this.peeJobAttachments.map((item) => item.id);
    },

    //errors
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.unitId.$dirty) return errors;
      if (!this.$v.unitId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobTypesErrors() {
      const errors = [];
      if (!this.$v.jobTypeId.$dirty) return errors;
      if (!this.$v.jobTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    budgetErrors() {
      const errors = [];
      if (!this.$v.budgetBeforeAuction.$dirty) return errors;
      if (!this.$v.budgetBeforeAuction.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.budgetBeforeAuction.decimal)
        errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },
    creditsErrors() {
      const errors = [];
      if (!this.$v.credits.$dirty) return errors;
      if (!this.$v.credits.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.credits.decimal) errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },
    jobCategoryErrors() {
      const errors = [];
      if (!this.$v.jobCategoryId.$dirty) return errors;
      if (!this.$v.jobCategoryId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobPhaseErrors() {
      const errors = [];
      if (!this.$v.jobPhaseId.$dirty) return errors;
      if (!this.$v.jobPhaseId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  validations: {
    title: { required },
    unitId: { required },
    jobTypeId: { required },
    budgetBeforeAuction: { required, decimal },
    credits: { decimal, required },
    jobCategoryId: { required },
    jobPhaseId: { required },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}
</style>
