import { ifNotAuth } from './index'

export default [
    {
        path: '/pde/projects',
        name: 'pde-projects',
        component: () => import('@/views/pde/PdeJobsListView.vue'),
        beforeEnter: ifNotAuth,
        meta: { title: 'Έργα ΠΔΕ' },
    },
    {
        path: '/pde/projects/:jobId',
        name: 'pde-project',
        component: () => import('@/views/pde/PdeJobViewWrapper.vue'),
        beforeEnter: ifNotAuth,
        meta: { title: 'Προβολή Έργου ΠΔΕ' },
    },
    {
        path: '/pde/projects/:jobId/sub-job/:subJobId',
        name: 'pde-sub-project',
        component: () => import('@/views/pde/PdeJobViewTabs/SubJobs/SubJobViewWrapper.vue'),
        beforeEnter: ifNotAuth,
        meta: { title: 'Προβολή Υποέργου ΠΔΕ' },
    },
    {
        path: '/pde/new-project',
        name: 'new-pde-project',
        component: () => import('@/views/pde/PdeJobAdd.vue'),
        beforeEnter: ifNotAuth,
        meta: { title: 'Νέο Έργο ΠΔΕ' },
    }
]