<template>
  <div class="wrapper">
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <div class="card mt-5" v-else>
      <v-alert text tile dense color="primary">Ειδοποιήσεις</v-alert>
      <v-row
        class="mx-2"
        justify="center"
        align="center"
        v-if="notificationsCounter > 0"
      >
        <v-col cols="8">
          <span class="body-1">
            <span class="font-weight-bold">{{ notificationsCounter }}</span>
            υπογεγραμμένες προγραμματικές συμβάσεις χωρίς επιτροπή.</span
          >
        </v-col>
        <v-col cols="4">
          <v-btn color="primary darken-3" @click="openDialog"
            >ΛΙΣΤΑ ΕΡΓΩΝ</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-else :style="'height:60px'"> </v-row>

      <v-dialog v-model="dialog" width="700px" max-width="700px">
        <v-card v-if="dialog">
          <v-card-text>
            <v-data-table
              :footer-props="{
                'disable-items-per-page': true,
              }"
              :options.sync="options"
              :server-items-length="notificationsCounter"
              :headers="headers"
              :items="notifications"
              :loading="tableLoader"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onView(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>ΠΡΟΒΟΛΗ</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
             </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASEURL}/api/notification/job/programmatic/contract/without/committee/counter`
      );
      this.notificationsCounter = res.data.data;
      this.pageLoader = false;
    } catch (e) {
      this.pageLoader = false;
      console.log(e);
    }
  },

  data() {
    return {
      pageLoader: true,
      dialog: false,
      options: {
        itemsPerPage: 20,
        page: 1,
      },
      tableLoader: false,

      headers: [
        {
          text: "Τίτλος",
          value: "title",
          sortable: false,
        },

        {
          text: "ΚΑΕ",
          value: "kae",
          sortable: false,
        },

        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],

      notificationsCounter: null,
      notifications: [],
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  methods: {
    async openDialog() {
      try {
        this.dialog = true;
      } catch (e) {
        console.log(e);
      }
    },

    async handler() {
      try {
        if (this.dialog) {
          this.tableLoader = true;
          const res = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/notification/job/programmatic/contract/without/committee/list/paging`,
            {
              params: {
                page: this.options.page,
              },
            }
          );
          this.notifications = res.data.data;
          this.tableLoader = false;
        }
      } catch (e) {
        this.notifications = [];
        console.log(e);
      }
    },

    onView(item) {
      let route = this.$router.resolve({
        path: `/projects/${item.peeJobId}/edit-project`,
      });
      window.open(route.href);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  -webkit-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.74);
  box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.74);
  background-color: #fff;
}
</style>