<template>
  <div>
    <v-breadcrumbs
      :items="[
        {
          text: 'ΡΥΘΜΙΣΕΙΣ',
          disabled: false,
          link: true,
          exact: true,
          to: '/settings',
        },
        { text: 'ΔΙΑΧΕΙΡΙΣΗ ΛΙΣΤΩΝ' },
      ]"
      divider=">"
    ></v-breadcrumbs>
    <v-row>
      <v-col cols="4">
        <v-btn
          color="primary darken-2 white--text btn mb-10"
          block
          depressed
          tile
          @click="$router.go(-1)"
          >Ακυρο</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('unit/list', 'ΠΕΡΙΦΕΡΕΙΑΚΕΣ ΕΝΟΤΗΤΕΣ / ΤΟΜΕΙΣ');
            selected = 'unit';
          "
          >περιφερειακες ενοτητες / τομεις</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/type/list', 'ΕΙΔΗ ΕΡΓΟΥ');
            selected = 'type';
          "
          >ειδη εργου</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/axis/list', 'ΑΞΟΝΕΣ');
            selected = 'axis';
          "
          >αξονες</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/measure/list', 'ΜΕΤΡΑ');
            selected = 'measure';
          "
          >μετρα</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/goal/list', 'ΣΤΟΧΟΙ');
            selected = 'goal';
          "
          >στοχοι</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/phase/list', 'ΦΑΣΕΙΣ ΕΡΓΟΥ');
            selected = 'phase';
          "
          >φασεις εργου</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('job/category/list', 'ΤΥΠΟΙ ΕΡΓΩΝ');
            selected = 'category';
          "
          >τυποι εργων</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('municipality/list', 'ΔΗΜΟΙ');
            selected = 'municipality';
          "
          >δημοι</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData(
              'job/programmatic/contract/phase/list',
              'ΦΑΣΕΙΣ ΠΡΟΓΡΑΜΜΑΤΙΚΗΣ ΣΥΜΒΑΣΗΣ'
            );
            selected = 'programmatic';
          "
          >φασεις προγραμματικησ συμβασης</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('entity/organization/list', 'ΦΟΡΕΙΣ');
            selected = 'entity';
          "
          >φορεις</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('pde/decision/list', 'ΑΠΟΦΑΣΕΙΣ');
            selected = 'decision';
          "
          >ΑΠΟΦΑΣΕΙΣ</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('pde/semester/list', 'ΤΡΙΜΗΝΑ');
            selected = 'semester';
          "
          >ΤΡΙΜΗΝΑ</v-btn
        >
        <v-btn
          color="primary"
          depressed
          block
          tile
          dark
          class="btn"
          @click="
            getListData('pde/subsector/list', 'ΤΟΜΕΙΣ / ΥΠΟΤΟΜΕΙΣ');
            selected = 'subsector';
          "
          >ΤΟΜΕΙΣ / ΥΠΟΤΟΜΕΙΣ</v-btn
        >

        <v-btn
            color="primary"
            depressed
            block
            tile
            dark
            class="btn"
            @click="
            getListData('job/cost/list', 'ΔΑΠΑΝΕΣ');
            selected = 'cost';
          "
        >ΔΑΠΑΝΕΣ</v-btn
        >
      </v-col>

      <v-col cols="8" class="mt-5 pl-12">
        <h4 class="mb-12">{{ title }}</h4>

        <v-card v-if="selected == 'axis'">
          <div style="display: flex">
            <v-btn text @click="addAxis">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΕΙΔΟΣ ΕΡΓΟΥ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>

                <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editAxis(
                            item.id,
                            item.name,
                            item.jobType.name,
                            item.jobType.id
                          )
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card v-else-if="selected == 'measure'">
          <div style="display: flex">
            <v-btn text @click="addMeasure">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΑΞΟΝΑΣ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>

                <td>{{ item.jobAxis ? item.jobAxis.name : "" }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editMeasure(
                            item.id,
                            item.name,
                            item.jobAxis.name,
                            item.jobAxis.id
                          )
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card v-else-if="selected == 'goal'">
          <div style="display: flex">
            <v-btn text @click="addGoal">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΜΕΤΡΟ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>

                <td>{{ item.jobMeasure ? item.jobMeasure.name : "" }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editGoal(
                            item.id,
                            item.name,
                            item.jobMeasure.name,
                            item.jobMeasure.id
                          )
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card v-else-if="selected == 'municipality'">
          <div style="display: flex">
            <v-btn text @click="addMunicipality">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΠΕΡΙΦΕΡΕΙΑΚΗ ΕΝΟΤΗΤΑ/ΤΟΜΕΑΣ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>

                <td>{{ item.unit ? item.unit.name : "" }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editMunicipality(
                            item.id,
                            item.name,
                            item.unit.name,
                            item.unit.id
                          )
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>


<!--&lt;!&ndash;        aaaaaaaa&ndash;&gt;-->
        <v-card v-else-if="selected == 'cost'">
          <div style="display: flex">
            <v-btn text @click="addCost">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
            <tr>
              <th>ΤΙΤΛΟΣ</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list" :key="item.id">
              <td>{{ item.name }}</td>

              <td>{{ item.cost ? item.cost.name : "" }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        @click="
                          editCost(
                            item.id,
                            item.name,
                            // item.cost.name,
                            // item.cost.id
                          )
                        "
                    >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Επεξεργασία</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                    >mdi-delete</v-icon
                    >
                  </template>
                  <span>Διαγραφή</span>
                </v-tooltip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card>
<!--&lt;!&ndash;        aaaaaaaa&ndash;&gt;-->

        <v-card v-else-if="selected == 'semester'">
          <div style="display: flex">
            <v-btn text @click="addDialogSemester = true">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΚΩΔΙΚΟΣ</th>
                <th>ΤΡΙΜΗΝΟ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.code }}</td>

                <td>{{ item.description }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editSemester({
                            id: item.id,
                            code: item.code,
                            description: item.description,
                          })
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card v-else-if="selected == 'subsector'">
          <div style="display: flex">
            <v-btn text @click="addDialogSubsector = true">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΚΩΔΙΚΟΣ</th>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.code }}</td>

                <td>{{ item.name }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editSubsector({
                            id: item.id,
                            code: item.code,
                            name: item.name,
                          })
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-card v-else>
          <div style="display: flex">
            <v-btn text @click="addDialogGeneral = true">
              ΠΡΟΣΘΗΚΗ
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΤΙΤΛΟΣ</th>
                <th>ΕΝΕΡΓΕΙΕΣ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>

                <!-- <td v-if="selected">
              <div v-for="test in item" :key="test+'1'">{{test.name}}</div>
                </td>-->
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          editing.name = item.name;
                          editing.id = item.id;
                          dialog = true;
                        "
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          deletingId = item.id;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogGoal" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            label="ΤΙΤΛΟΣ"
            v-model="editing.name"
            :value="editing.name"
          ></v-text-field>
          <v-autocomplete
            :items="measureList"
            item-value="id"
            item-text="name"
            label="ΜΕΤΡΟ"
            v-model="editing.jobMeasure.id"
            >dasdas</v-autocomplete
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="submitEditGoal(editing.name, editing.jobMeasure.id)"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogGoal = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<!--    ΑΑΑΑΑΑΑΑ-->
    <v-dialog v-model="dialogCost" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
              label="ΤΙΤΛΟΣ"
              v-model="editing.name"
              :value="editing.name"
          ></v-text-field>
<!--          <v-autocomplete-->
<!--              :items="costList"-->
<!--              item-value="id"-->
<!--              item-text="name"-->
<!--              label="ΔΑΠΑΝΕΣ"-->
<!--              v-model="editing.cost.id"-->
<!--          >dasdas</v-autocomplete-->
<!--          >-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="submitEditCost(editing.name, editing.cost.id)"
          >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogCost = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--    ΑΑΑΑΑΑΑΑ-->



    <v-dialog v-model="dialogMunicipality" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            label="ΤΙΤΛΟΣ"
            v-model="editing.name"
            :value="editing.name"
          ></v-text-field>
          <v-autocomplete
            :items="municipalityList"
            item-value="id"
            item-text="name"
            label="ΠΕΡΙΦΕΡΕΙΑΚΗ ΕΝΟΤΗΤΑ/ΤΟΜΕΑΣ"
            v-model="editing.unit.id"
            >dasdas</v-autocomplete
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="submitEditMunicipality(editing.name, editing.unit.id)"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogMunicipality = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TODOLINO -->
    <v-dialog v-model="dialogAxis" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editing.name"
            label="ΤΙΤΛΟΣ"
            :value="editing.name"
          ></v-text-field>
          <v-autocomplete
            :items="typeList"
            item-value="id"
            item-text="name"
            label="ΕΙΔΟΣ ΕΡΓΟΥ"
            v-model="editing.jobType.id"
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="submitEditAxis(editing.name, editing.jobType.id)"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogAxis = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialogAxis" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field v-model="addingAxis.name" label="ΤΙΤΛΟΣ"></v-text-field>
          <v-autocomplete
            :items="typeList"
            item-value="id"
            item-text="name"
            label="ΕΙΔΟΣ ΕΡΓΟΥ"
            v-model="addingAxis.id"
            >asdasdas</v-autocomplete
          >
        </v-card-text>
<!--        {{ addingAxis }}-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddAxis">αποθηκευση</v-btn>
          <v-btn text color="red" @click="dialogAxis = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMeasure" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editing.name"
            label="ΤΙΤΛΟΣ"
            :value="editing.name"
          ></v-text-field>
          <v-autocomplete
            :items="axisList"
            item-value="id"
            item-text="name"
            label="ΑΞΟΝΑΣ"
            v-model="editing.jobAxis.id"
            >asdasdas</v-autocomplete
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="submitEditMeasure(editing.name, editing.jobAxis.id)"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogMeasure = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialogMeasure" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="addingMeasure.name"
            label="ΤΙΤΛΟΣ"
          ></v-text-field>
          <v-autocomplete
            :items="axisList"
            item-value="id"
            item-text="name"
            label="ΑΞΟΝΑΣ"
            v-model="addingMeasure.id"
            >asdasdas</v-autocomplete
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddMeasure"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogMeasure = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialogGoal" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field v-model="addingGoal.name" label="ΤΙΤΛΟΣ"></v-text-field>
          <v-autocomplete
            :items="measureList"
            item-value="id"
            item-text="name"
            label="ΜΕΤΡΟ"
            v-model="addingGoal.id"
            >asdasdas</v-autocomplete
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddGoal">αποθηκευση</v-btn>
          <v-btn text color="red" @click="dialogGoal = false">ακυρωση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="addDialogMunicipality" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="addingMunicipality.name"
            label="ΤΙΤΛΟΣ"
          ></v-text-field>
          <v-autocomplete
            :items="municipalityList"
            item-value="id"
            item-text="name"
            label="ΠΕΡΙΦΕΡΕΙΑΚΗ ΕΝΟΤΗΤΑ/ΤΟΜΕΑΣ"
            v-model="addingMunicipality.id"
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddMunicipality"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogMunicipality = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    aaaaaaaaaaaaaaaaa-->
    <v-dialog v-model="addDialogCost" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field
              v-model="addingCost.name"
              label="ΤΙΤΛΟΣ"
          ></v-text-field>
<!--          <v-autocomplete-->
<!--              :items="costList"-->
<!--              item-value="id"-->
<!--              item-text="name"-->
<!--              label="ΔΑΠΑΝΕΣ"-->
<!--              v-model="addingCost.id"-->
<!--          ></v-autocomplete>-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddCost"
          >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="dialogCost = false"
          >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--    aaaaaaaaaaaaaaaa-->

    <v-dialog v-model="addDialogSemester" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field
            label="ΚΩΔΙΚΟΣ"
            v-model="addingSemester.code"
          ></v-text-field>

          <v-text-field
            v-model="addingSemester.description"
            label="ΤΡΙΜΗΝΟ"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddSemester"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="addDialogSemester = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialogSemester" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editing.code"
            label="ΚΩΔΙΚΟΣ"
            :value="editing.code"
          ></v-text-field>
          <v-text-field
            label="ΤΡΙΜΗΝΟ"
            v-model="editing.description"
            :value="editing.description"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitEditSemester()"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="editDialogSemester = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialogSubsector" width="400">
      <v-card>
        <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-text-field
            type="number"
            label="ΚΩΔΙΚΟΣ"
            v-model="addingSubsector.code"
          ></v-text-field>

          <v-text-field
            v-model="addingSubsector.name"
            label="ΤΙΤΛΟΣ"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitAddSubsector"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="addDialogSubsector = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialogSubsector" width="400">
      <v-card>
        <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editing.code"
            label="ΚΩΔΙΚΟΣ"
            :value="editing.code"
          ></v-text-field>
          <v-text-field
            label="ΤΙΤΛΟΣ"
            v-model="editing.name"
            :value="editing.name"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="submitEditSubsector()"
            >αποθηκευση</v-btn
          >
          <v-btn text color="red" @click="editDialogSubsector = false"
            >ακυρωση</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <div v-if="!this.$store.state.loading">
          <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
          <v-card-text>
            <v-text-field
              label="ΤΙΤΛΟΣ"
              :value="editing.name"
              v-model="editing.name"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="submitEditGeneral(editing.id, editing.name)"
              >αποθηκευση</v-btn
            >
            <v-btn text color="red" @click="dialog = false">ακυρωση</v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-progress-circular :size="30" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialogGeneral" width="400">
      <v-card>
        <div v-if="!this.$store.state.loading">
          <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
          <v-card-text>
            <v-text-field label="ΤΙΤΛΟΣ" v-model="adding.name"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="submitAddGeneral"
              >προσθηκη</v-btn
            >
            <v-btn text color="red" @click="addDialogGeneral = false"
              >ακυρωση</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>
          <v-progress-circular :size="30" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <div v-if="!this.$store.state.loading">
          <v-card-title>ΔΙΑΓΡΑΦΗ</v-card-title>
          <v-card-text>
            <p>Ειστε σίγουροι ότι θελετε να γίνει η διαγραφή;</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="deleteItem">διαγραφη</v-btn>
            <v-btn text color="red" @click="deleteDialog = false"
              >ακυρωση</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>
          <v-progress-circular :size="30" indeterminate></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.value">
      {{ snackbar.text }}
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbar: {
        value: false,
        text: "",
      },
      title: "",
      url: "",
      dialog: null,
      dialogGoal: null,
      dialogMeasure: null,
      dialogAxis: null,
      dialogMunicipality: null,
      editDialogSemester: null,
      editDialogSubsector: null,
      dialogCost: null,
      editDialogCost: null,

      addingAxis: {
        name: "",
        id: "",
      },

      addingMeasure: {
        name: "",
        id: "",
      },

      addingGoal: {
        name: "",
        id: "",
      },

      addingMunicipality: {
        name: "",
        id: "",
      },

      addingSemester: {
        code: "",
        description: "",
      },

      addingSubsector: {
        code: "",
        name: "",
      },
      addingCost: {
        name: "",
        id: "",
      },

      editing: {
        id: "",
        name: "",
        code: "",
        description: "",
        jobAxis: {
          name: "",
          id: "",
        },
        jobType: {
          name: "",
          id: "",
        },
        jobMeasure: {
          name: "",
          id: "",
        },
        unit: {
          name: "",
          id: "",
        },
        cost: {
          name: "",
          id: "",
        },
      },
      selected: "",
      refreshListUrl: "",
      addDialogGeneral: null,
      addDialogAxis: null,
      addDialogMeasure: null,
      addDialogGoal: null,
      addDialogMunicipality: null,
      addDialogSemester: false,
      addDialogSubsector: false,
      addDialogCost: null,
      adding: {
        name: "",
      },
      deletingId: "",
      deleteDialog: null,
    };
  },
  methods: {
    getListData(url, title) {
      this.refreshListUrl = url;
      this.$store
        .dispatch("getListData", url)
        .then((res) => {
          this.title = title;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit(props) {
      this.toEdit = props.item;
    },
    submitAddGeneral() {
      let url;
      if (this.selected == "unit") {
        url = "/api/unit/add";
      } else if (this.selected == "type") {
        url = "/api/job/type/add";
      } else if (this.selected == "phase") {
        url = "/api/job/phase/add";
      } else if (this.selected == "category") {
        url = "/api/job/category/add";
      } else if (this.selected == "municipality") {
        url = "/api/municipality/add";
      } else if (this.selected == "programmatic") {
        url = "/api/job/programmatic/contract/phase/add";
      } else if (this.selected == "entity") {
        url = "/api/entity/organization/add";
      } else if (this.selected == "decision") {
        url = "/api/pde/decision/add";
      }else if (this.selected == "cost") {
        url = "/api/job/cost/add";
      }

      // } else if (this.selected == "semester") {
      //   url = "/api/pde/semester/add";
      // } else if (this.selected == "subsector") {
      //   url = "/api/pde/subsector/add";
      // }

      let credentials = {
        url,
        name: this.adding.name,
        refreshListUrl: this.refreshListUrl,
      };

      this.$store
        .dispatch("addGeneral", credentials)
        .then((res) => {
          this.addDialogGeneral = false;
          this.snackbar.text = "Προσθήκη επιτυχής";
          this.snackbar.value = true;
          this.adding.name = "";
        })
        .catch((err) => {
          console.log(err);
          this.snackbar.text = "Προσθήκη ανεπιτυχής";
          this.snackbar.value = true;
          //   this.refresh();
        });
    },
    deleteItem() {
      let path;
      if (this.selected == "municipality") {
        path = `api/municipality/delete/${this.deletingId}`;
      } else if (this.selected == "type") {
        path = `api/job/type/delete/${this.deletingId}`;
      } else if (this.selected == "phase") {
        path = `api/job/phase/delete/${this.deletingId}`;
      } else if (this.selected == "unit") {
        path = `api/unit/delete/${this.deletingId}`;
      } else if (this.selected == "programmatic") {
        path = `api/job/programmatic/contract/phase/delete/${this.deletingId}`;
      } else if (this.selected == "category") {
        path = `api/job/category/delete/${this.deletingId}`;
      } else if (this.selected == "axis") {
        path = `api/job/axis/delete/${this.deletingId}`;
      } else if (this.selected == "measure") {
        path = `api/job/measure/delete/${this.deletingId}`;
      } else if (this.selected == "goal") {
        path = `api/job/goal/delete/${this.deletingId}`;
      } else if (this.selected == "entity") {
        path = `api/entity/organization/delete/${this.deletingId}`;
      } else if (this.selected == "decision") {
        path = `api/pde/decision/delete/${this.deletingId}`;
      } else if (this.selected == "semester") {
        path = `api/pde/semester/delete/${this.deletingId}`;
      } else if (this.selected == "subsector") {
        path = `api/pde/subsector/delete/${this.deletingId}`;
      }else if (this.selected == "cost") {
        path = `api/job/cost/delete/${this.deletingId}`;
      }

      this.$store
        .dispatch("delete", {
          path,
          refreshListUrl: this.refreshListUrl,
          id: this.deletingId,
        })
        .then((res) => {
          this.deleteDialog = false;
          this.snackbar.text = "Διαγραφη Επιτυχής";
          this.snackbar.value = true;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar.text = "Διαγραφη Ανεπιτυχης";
          this.snackbar.value = true;
          this.refresh();
        });
    },
    submitEditGeneral(id, name) {
      let credentials;
      if (this.selected == "municipality") {
        credentials = {
          path: `api/municipality/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "type") {
        credentials = {
          path: `api/job/type/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "phase") {
        credentials = {
          path: `api/job/phase/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "unit") {
        credentials = {
          path: `api/unit/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "programmatic") {
        credentials = {
          path: `api/job/programmatic/contract/phase/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "category") {
        credentials = {
          path: `api/job/category/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "entity") {
        credentials = {
          path: `api/entity/organization/edit/${id}`,
          name: name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "decision") {
        credentials = {
          path: `api/pde/decision/edit/${id}`,
          name,
          refreshListUrl: this.refreshListUrl,
        };
      } else if (this.selected == "cost") {
        credentials = {
          path: `api/job/cost/edit/${id}`,
          name,
          refreshListUrl: this.refreshListUrl,
        };
      }


      this.$store
        .dispatch("editGeneral", credentials)
        .then((res) => {
          this.dialog = false;
          this.snackbar.text = "Επεξεργασία επιτυχής";
          this.snackbar.value = true;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar.text = "Επεξεργασία ανεπιτυχής";
          this.snackbar.value = true;
          this.refresh();
        });
    },
    editMeasure(id, name, jobAxisName, jobAxisId) {
      this.editing.id = id;
      this.editing.name = name;
      this.editing.jobAxis.name = jobAxisName;
      this.editing.jobAxis.id = jobAxisId;
      this.$store
        .dispatch("getAxisData")
        .then((res) => {
          this.dialogMeasure = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editAxis(id, name, jobTypeName, jobTypeId) {
      this.editing.id = id;
      this.editing.name = name;
      this.editing.jobType.name = jobTypeName;
      this.editing.jobType.id = jobTypeId;

      this.$store
        .dispatch("getTypeData")
        .then((res) => {
          this.dialogAxis = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editGoal(id, name, jobMeasureName, jobMeasureId) {
      this.editing.id = id;
      this.editing.name = name;
      this.editing.jobMeasure.name = jobMeasureName;
      this.editing.jobMeasure.id = jobMeasureId;

      this.$store
        .dispatch("getMeasureData")
        .then((res) => {
          this.dialogGoal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editMunicipality(id, name, unitName, unitId) {
      this.editing.id = id;
      this.editing.name = name;
      this.editing.unit.name = unitName;
      this.editing.unit.id = unitId;

      this.$store
        .dispatch("getMunicipalityData")
        .then((res) => {
          this.dialogMunicipality = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editCost(id, name) {
      console.log(id)
      console.log(name)
      console.log(this.editing)
      this.editing.id = id;
      this.editing.name = name;
      // this.editing.cost.name = costName;
      // this.editing.cost.id = costId;

      this.$store
          .dispatch("getCostData")
          .then((res) => {
            this.dialogCost = true;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    editSemester({ id, code, description }) {
      this.editing.id = id
      this.editing.code = code;
      this.editing.description = description;
      this.editDialogSemester = true;
    },
    editSubsector({ id, code, name }) {
      this.editing.id = id
      this.editing.code = code;
      this.editing.name = name;
      this.editDialogSubsector = true;
    },

    addAxis() {
      this.$store
        .dispatch("getTypeData")
        .then(() => {
          this.addDialogAxis = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addMeasure() {
      this.$store
        .dispatch("getAxisData")
        .then(() => {
          this.addDialogMeasure = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addGoal() {
      this.$store
        .dispatch("getMeasureData")
        .then(() => {
          this.addDialogGoal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addMunicipality() {
      this.$store
        .dispatch("getMunicipalityData")
        .then(() => {
          this.addDialogMunicipality = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addCost() {
      this.$store
          .dispatch("getCostData")
          .then(() => {
            this.addDialogCost = true;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    submitEditMeasure(name, jobAxisId) {
      const credentials = {
        id: this.editing.id,
        jobAxisId: jobAxisId,
        name: name,
      };
      this.$store
        .dispatch("editMeasure", credentials)
        .then((res) => {
          this.dialogMeasure = false;
          // this.$router.go();
        })
        .catch((err) => {
          console.log("erra", err);
        });
    },
    submitEditAxis(name, jobTypeId) {
      const credentials = {
        id: this.editing.id,
        jobTypeId: jobTypeId,
        name: name,
      };
      this.$store
        .dispatch("editAxis", credentials)
        .then((res) => {
          this.dialogAxis = false;
        })
        .catch((err) => {
          console.log("erra", err);
        });
    },
    submitEditGoal(name, jobMeasureId) {
      const credentials = {
        id: this.editing.id,
        jobMeasureId: jobMeasureId,
        name: name,
      };
      this.$store
        .dispatch("editGoal", credentials)
        .then((res) => {
          this.dialogGoal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
submitEditCost(name, costId) {
  const credentials = {
    id: this.editing.id,
    costId: costId,
    name: name,
  };
  this.$store
      .dispatch("editCost", credentials)
      .then((res) => {
        this.dialogCost = false;
      })
      .catch((err) => {
        console.log(err);
      });
},
    submitEditMunicipality(name, unitId) {
      const credentials = {
        id: this.editing.id,
        unitId: unitId,
        name: name,
      };
      this.$store
        .dispatch("editMunicipality", credentials)
        .then((res) => {
          this.dialogMunicipality = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitEditSemester() {
      const credentials = {
        id: this.editing.id,
        code: this.editing.code,
        description: this.editing.description,
      };
      this.$store
        .dispatch("editSemester", credentials)
        .then((res) => {
          this.editDialogSemester = false;
        })
    },
    submitEditSubsector() {
      const credentials = {
        id: this.editing.id,
        code: this.editing.code,
        name: this.editing.name,
      };
      this.$store
        .dispatch("editSubsector", credentials)
        .then((res) => {
          this.editDialogSubsector = false;
        })
    },
    submitAddAxis() {
      const credentials = {
        jobTypeId: this.addingAxis.id,
        name: this.addingAxis.name,
      };
      this.$store
        .dispatch("addAxis", credentials)
        .then((res) => {
          this.addingAxis.id = "";
          this.addingAxis.name = "";
          this.addDialogAxis = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAddMeasure() {
      const credentials = {
        jobAxisId: this.addingMeasure.id,
        name: this.addingMeasure.name,
      };
      this.$store
        .dispatch("addMeasure", credentials)
        .then((res) => {
          this.addingMeasure.id = "";
          this.addingMeasure.name = "";
          this.addDialogMeasure = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAddGoal() {
      const credentials = {
        jobMeasureId: this.addingGoal.id,
        name: this.addingGoal.name,
      };
      this.$store
        .dispatch("addGoal", credentials)
        .then((res) => {
          this.addingGoal.id = "";
          this.addingGoal.name = "";
          this.addDialogGoal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAddMunicipality() {
      const credentials = {
        unitId: this.addingMunicipality.id,
        name: this.addingMunicipality.name,
      };
      this.$store
        .dispatch("addMunicipality", credentials)
        .then((res) => {
          this.addingMunicipality.id = "";
          this.addingMunicipality.name = "";
          this.addDialogMunicipality = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAddCost() {
      const credentials = {
        costId: this.addingCost.id,
        name: this.addingCost.name,
      };
      this.$store
          .dispatch("addCost", credentials)
          .then((res) => {
            this.addingCost.id = "";
            this.addingCost.name = "";
            this.addDialogCost = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    submitAddSemester() {
      const credentials = {
        code: this.addingSemester.code,
        description: this.addingSemester.description,
      };
      this.$store.dispatch("addSemester", credentials).then((res) => {
        (this.addingSemester.code = ""),
          (this.addingSemester.description = ""),
          (this.addDialogSemester = false);
      });
    },
    submitAddSubsector() {
      const credentials = {
        code: Number(this.addingSubsector.code),
        name: this.addingSubsector.name,
      };
      this.$store.dispatch("addSubsector", credentials).then((res) => {
        (this.addingSubsector.code = ""),
          (this.addingSubsector.name = ""),
          (this.addDialogSubsector = false);
      });
    },
    // submitAddSemester() {
    //   const credentials = {
    //     code: this.addingSemester.code,
    //     name: this.addingSemester.name,
    //   };
    //   this.$store.dispatch("addSemester", credentials).then((res) => {
    //     this.addingSemester.code = "";
    //     this.addingSemester.name = "";
    //   });
    // },
    refresh() {
      this.$router.go();
    },
  },
  computed: {
    list() {
      console.log(this.$store.state.settings);
      return this.$store.state.settings.data;
    },
    axisList() {
      return this.$store.state.settings.axisData;
    },
    typeList() {
      return this.$store.state.settings.typeData;
    },
    measureList() {
      return this.$store.state.settings.measureData;
    },
    municipalityList() {
      return this.$store.state.settings.municipalityData;
    },
    costList() {
      return this.$store.state.settings.costData;
    },
  },
};
</script>

<style scoped>
.btn {
  margin-top: 15px;
  width: 25%;
}

#icon {
  cursor: pointer;
}

/* table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f5f5f5;
} */

table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  table-layout: auto;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  overflow-x: scroll;
}
table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 3px 2px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}
table.blueTable thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(
    top,
    #5592bb 0%,
    #327cad 66%,
    #1c6ea4 100%
  );
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(
    top,
    #dcebf7 0%,
    #d4e6f6 66%,
    #d0e4f5 100%
  );
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}
</style>
