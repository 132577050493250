import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import vuelidate from 'vuelidate'
import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(vuelidate)
Vue.use(
  VueCurrencyFilter,
  {
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 3,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  }
)

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// axios config
if (store.getters.isAuth) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('perifereiaJwtToken')}`
}
axios.defaults.baseURL = process.env.VUE_APP_BASEURL
axios.interceptors.response.use(
  function (response) {
    // show error message for status -1
    if (response.data.status == -1) {
      store.commit('toggleSnackbar', {
        text: "Παρουσιάστηκε Σφάλμα!"
      })
      throw new Error("status -1 error")
    }
    return response
  }, function (error) {
    console.log(error.response.status)
    if (error.response.status === 401) {
      store.dispatch('logout').then(() => router.push('/login'))
    }
    return Promise.reject(error)
  }
)