<template>
  <div>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-card-title>ΑΠΟΤΕΛΕΣΜΑΤΑ</v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="results">
              <template v-slot:[`item.confirmedBudget`]="{ item }">{{
                thousands_separators(item.confirmedBudget)
              }}</template>
              <template
                v-slot:[`item.peeJobHistoricalModificationReasons`]="{ item }"
                >{{
                  mapped(item.peeJobHistoricalModificationReasons)
                }}</template
              >
              <template v-slot:[`item.suggestedBudget`]="{ item }">{{
                thousands_separators(item.suggestedBudget)
              }}</template>
              <template v-slot:[`item.confirmedCredits`]="{ item }">{{
                thousands_separators(item.confirmedCredits)
              }}</template>
              <template v-slot:[`item.suggestedCredits`]="{ item }">{{
                thousands_separators(item.suggestedCredits)
              }}</template>
            </v-data-table>

            <div
              style="display: flex; width: 100%; align-items: center"
              class="px-12 py-5"
            >
              <v-icon
                v-if="page > 1"
                @click="
                  page -= 1;
                  updatePagination({ page: page });
                "
                >mdi-arrow-left</v-icon
              >
              <v-spacer></v-spacer>
              <p style="justify-self: center">
                Σελίδα {{ page }} από {{ pages }}
              </p>
              <v-spacer></v-spacer>
              <v-icon
                v-if="page < pages"
                @click="
                  page += 1;
                  updatePagination({ page: page });
                "
                >mdi-arrow-right</v-icon
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    axios
      .get(
        `${process.env.VUE_APP_BASEURL}/api/pee/job/historical/modification/list/counter/${this.$route.params.project_id}`
      )
      .then((res) => {
        this.counter = res.data.data;
        return axios.get(
          `${process.env.VUE_APP_BASEURL}/api/pee/job/historical/modification/list/pee/job/paging?peeJobId=${this.$route.params.project_id}&page=1`
        );
      })
      .then((res) => {
        this.results = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      page: 1,
      counter: 0,
      results: [],

      headers: [
        {
          text: "Έτος",
          value: "year",
        },
        {
          text: "ΠΕΕ",
          value: "serialNumber",
        },
        {
          text: "Αρ. Απόφασης ΠΕ.ΣΥ.",
          value: "pesyDecisionNumber",
        },

        {
          text: "Σύνοψη Τροποποιήσεων",
          value: "peeJobHistoricalModificationReasons",
        },

        {
          text: "Προϋπολογισμός (προ δημοπράτησης)",
          value: "budgetBeforeAuction",
        },

        {
          text: "Παλιός Προϋπολογισμός",
          value: "confirmedBudget",
        },
        {
          text: "Νέος Προϋπολογισμός",
          value: "suggestedBudget",
        },
        {
          text: "Τίτλος",
          value: "title",
        },
        {
          text: "Νεός Τίτλος",
          value: "newTitle",
        },
        {
          text: "Παλιές Πιστώσεις",
          value: "confirmedCredits",
        },
        {
          text: "Νέες Πιστώσεις",
          value: "suggestedCredits",
        },
      ],
    };
  },
  methods: {
    updatePagination(pagination) {
      this.page = pagination.page;

      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/pee/job/historical/modification/list/pee/job/paging?peeJobId=${this.$route.params.project_id}&page=${pagination.page}`
        )
        .then((res) => {
          this.results = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    thousands_separators(num) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    mapped(arr) {
      let string = "";
      arr.forEach((i) => {
        string += i.name + ",";
      });
      return string;
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.counter / 20);
    },
  },
};
</script>