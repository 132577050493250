var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary darken-3"}},[(!!_vm.$store.state.auth.token)?_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-btn',{attrs:{"text":"","to":"/requests"}},[_vm._v("Αιτηματα")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("ΠΕΕ")])]}}],null,false,648798182)},[_c('v-list',_vm._l((_vm.PeeItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.$router
                .push(item.to)
                .then()
                .catch(function (err) {})}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("ΠΔΕ")])]}}],null,false,4145463495)},[_c('v-list',_vm._l((_vm.PdeItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.$router
                .push(item.to)
                .then()
                .catch(function (err) {})}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1),_c('v-btn',{attrs:{"text":"","to":"/settings"}},[_vm._v("Ρυθμισεις")]),_c('v-btn',{attrs:{"text":"","to":"/searches"}},[_vm._v("Αναζητησεις")])],1):_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(!!_vm.$store.state.auth.token)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$store.state.auth.user ? _vm.$store.state.auth.user.firstName : "")+" "+_vm._s(_vm.$store.state.auth.user ? _vm.$store.state.auth.user.lastName : "")+" ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"large":""}},on),[_vm._v("mdi-account")])]}}])},[_c('v-list',[(!!!_vm.$store.state.auth.token)?_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Σύνδεση")])],1):_c('v-list-item',{on:{"click":_vm.logout}},[_vm._v(" Αποσύνδεση ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }