<template>
  <div>
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>

    <v-card v-else class="elevation-0">
      <v-form
        @submit.prevent="submitErgolabikh"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row justify="center">
          <v-col cols="8">
            <v-text-field
              label="Τίτλος*"
              :rules="requiredRules"
              readonly
              :value="$store.state.projects.projectEditing.title"
            ></v-text-field>

            <v-text-field
              label="Ποσό αρχικής σύμβασης*"
              type="number"
              :rules="requiredRules"
              :hint="ergolabikhSumvashObj.amount | currency"
              persistent-hint
              v-model="ergolabikhSumvashObj.amount"
            >
            </v-text-field>

            <v-text-field
              label="Μέση έκπτωση"
              type="number"
              class="mt-2"
              :hint="ergolabikhSumvashObj.meanDiscount | currency"
              persistent-hint
              v-model="ergolabikhSumvashObj.meanDiscount"
            >
            </v-text-field>

            <v-text-field
              label="Τελευταίος ΑΠΕ"
              type="number"
              class="mt-2"
              :hint="ergolabikhSumvashObj.lastApe | currency"
              persistent-hint
              v-model="ergolabikhSumvashObj.lastApe"
            >
            </v-text-field>


            <v-text-field
                class="mt-2"
                label="Τελικό συμβατικό"
                v-model="ergolabikhSumvashObj.contractor"
            ></v-text-field>


            <v-text-field
              class="mt-2"
              label="Ανάδοχος"
              v-model="ergolabikhSumvashObj.contractor"
            ></v-text-field>



            <v-select
                :items="yesOrNo"
                v-model="contractTermination"
                label="Διάλυση Σύμβασης"
                color="primary"
            > </v-select>


            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="greekFormatDate(ergolabikhSumvashObj.startDate)"
                  label="Ημερομηνία έναρξης εργολαβικής σύμβασης*"
                  append-icon="mdi-calendar"
                  readonly
                  class="mt-4"
                  :rules="requiredRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <!-- <v-calendar v-model="ergolabikhSumvashObj.startDate" @change="save"></v-calendar> -->
              <v-date-picker
                v-model="ergolabikhSumvashObj.startDate"
                @change="save"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Ημερομηνία λήξης εργολαβικής σύμβασης*"
                  :value="greekFormatDate(ergolabikhSumvashObj.endDate)"
                  append-icon="mdi-calendar"
                  readonly
                  :rules="requiredRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="ergolabikhSumvashObj.endDate"
                @change="save2"
              ></v-date-picker>
            </v-menu>

            <v-card class="mt-5">
              <v-card-title>
                <h1 class="title">Αρχεία</h1>
              </v-card-title>
              <v-card-text>
                <v-file-input
                  label="Αρχεία"
                  chips
                  multiple
                  v-model="ergolabikhFiles"
                ></v-file-input>
                <v-btn
                  :disabled="ergolabikhFiles.length < 1"
                  @click="uploadErgolabikh"
                  >αρχειοθετηση</v-btn
                >

                <v-data-table
                  hide-default-footer
                  :headers="fileHeaders"
                  :items="
                    ergolabikhSumvashObj.jobConstructorContractAttachments
                  "
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="deleteErgolabikhFile(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="download(item)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-card-title>
                <h1 class="title">Βεβαίωση περαίωσης</h1>
                <v-card-text>
                  <v-text-field
                    label="Αριθμός πρωτοκόλλου"
                    v-model="
                      ergolabikhSumvashObj
                        .jobConstructorContractCompletionCertificate
                        .protocolNumber
                    "
                  >
                  </v-text-field>

                  <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          greekFormatDate(
                            ergolabikhSumvashObj
                              .jobConstructorContractCompletionCertificate
                              .protocolNumberDate
                          )
                        "
                        label="Ημερομηνία πρωτοκόλλου"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <!-- <v-calendar v-model="ergolabikhSumvashObj.startDate" @change="save"></v-calendar> -->
                    <v-date-picker
                      v-model="
                        ergolabikhSumvashObj
                          .jobConstructorContractCompletionCertificate
                          .protocolNumberDate
                      "
                      @change="save5"
                    ></v-date-picker>
                  </v-menu>

                  <v-card class="mt-5">
                    <v-card-title>
                      <h1 class="title">Αρχεία</h1>
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        label="Αρχεία"
                        chips
                        multiple
                        v-model="
                          jobConstructorContractCompletionCertificateFiles
                        "
                      ></v-file-input>
                      <v-btn
                        :disabled="
                          jobConstructorContractCompletionCertificateFiles.length <
                          1
                        "
                        @click="uploadVevaiwsh"
                        >αρχειοθετηση</v-btn
                      >

                      <v-data-table
                        hide-default-footer
                        :headers="fileHeaders"
                        :items="
                          ergolabikhSumvashObj
                            .jobConstructorContractCompletionCertificate
                            .jobConstructorContractCompletionCertificateAttachments
                        "
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon @click="deleteVevaiwshFile(item.id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn icon @click="download(item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card-title>
            </v-card>

            <v-card class="mt-5">
              <v-card-title>
                <h1 class="title">Προσωρινή παραλαβή</h1>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Αριθμός πρωτοκόλλου"
                  v-model="
                    ergolabikhSumvashObj.jobConstructorContractTempDelivery
                      .protocolNumber
                  "
                >
                </v-text-field>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="
                        greekFormatDate(
                          ergolabikhSumvashObj
                            .jobConstructorContractTempDelivery
                            .protocolNumberDate
                        )
                      "
                      label="Ημερομηνία πρωτοκόλλου"
                      append-icon="mdi-calendar"
                      readonly
                      class="mt-4"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <!-- <v-calendar v-model="ergolabikhSumvashObj.startDate" @change="save"></v-calendar> -->
                  <v-date-picker
                    v-model="
                      ergolabikhSumvashObj.jobConstructorContractTempDelivery
                        .protocolNumberDate
                    "
                    @change="save3"
                  ></v-date-picker>
                </v-menu>

                <v-card class="mt-5">
                  <v-card-title>
                    <h1 class="title">Αρχεία</h1>
                  </v-card-title>
                  <v-card-text>
                    <v-file-input
                      label="Αρχεία"
                      chips
                      multiple
                      v-model="jobConstructorContractTempDeliveryFiles"
                    >
                    </v-file-input>
                    <v-btn
                      :disabled="
                        jobConstructorContractTempDeliveryFiles.length < 1
                      "
                      @click="uploadProsorini"
                      >αρχειοθετηση</v-btn
                    >

                    <v-data-table
                      hide-default-footer
                      :headers="fileHeaders"
                      :items="
                        ergolabikhSumvashObj.jobConstructorContractTempDelivery
                          .jobConstructorContractTempDeliveryAttachments
                      "
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon @click="deleteProsoriniFile(item.id)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="download(item)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-card-title>
                <h1 class="title">Οριστική παραλαβή</h1>
                <v-card-text>
                  <v-text-field
                    label="Αριθμός πρωτοκόλλου"
                    v-model="
                      ergolabikhSumvashObj.jobConstructorContractFinalDelivery
                        .protocolNumber
                    "
                  >
                  </v-text-field>

                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          greekFormatDate(
                            ergolabikhSumvashObj
                              .jobConstructorContractFinalDelivery
                              .protocolNumberDate
                          )
                        "
                        label="Ημερομηνία πρωτοκόλλου"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <!-- <v-calendar v-model="ergolabikhSumvashObj.startDate" @change="save"></v-calendar> -->
                    <v-date-picker
                      v-model="
                        ergolabikhSumvashObj.jobConstructorContractFinalDelivery
                          .protocolNumberDate
                      "
                      @change="save4"
                    ></v-date-picker>
                  </v-menu>

                  {{
                    ergolabikhSumvashObj.jobConstructorContractFinalDelivery
                      .attachments
                  }}

                  <v-card class="mt-5">
                    <v-card-title>
                      <h1 class="title">Αρχεία</h1>
                    </v-card-title>
                    <v-card-text>
                      <v-file-input
                        label="Αρχεία"
                        chips
                        multiple
                        v-model="jobConstructorContractFinalDeliveryFiles"
                      >
                      </v-file-input>
                      <v-btn
                        :disabled="
                          jobConstructorContractFinalDeliveryFiles.length < 1
                        "
                        @click="uploadOristiki"
                        >αρχειοθετηση</v-btn
                      >

                      <v-data-table
                        hide-default-footer
                        :headers="fileHeaders"
                        :items="
                          ergolabikhSumvashObj
                            .jobConstructorContractFinalDelivery
                            .jobConstructorContractFinalDeliveryAttachments
                        "
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn icon @click="deleteOristikiFile(item.id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn icon @click="download(item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card-title>
            </v-card>

            <v-card class="mt-5" v-if="ergolabikhSumvashObj.id != null">
              <v-card-title>
                <h1 class="title">Επιπλέον δαπάνες</h1>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :footer-props="{
                    'disable-items-per-page': true,
                  }"
                  :options.sync="additionalCostsOptions"
                  :headers="additionalCostsHeaders"
                  :items="additionalCosts"
                  :server-items-length="additionalCostsCounter">

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      icon
                      @click="
                        currentAdditionalCostsObj.additionalCostsAmount = item.additionalCostsAmount;
                        currentAdditionalCostsObj.additionalCostsDate =
                          item.additionalCostsDate;
                        currentAdditionalCostsObj.id = item.id;

                        additionalCostsEditDialog = true;
                      "
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="deleteAdditionalCost(item.id)" icon>
                      <v-icon >mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:[`item.additionalCostsDate`]="{ item }">

                    <span v-if="item.additionalCostsDate != null">{{
                      new Date(item.additionalCostsDate).toLocaleDateString(
                        "el"
                      )
                    }}</span>

                  </template>

                  <template v-slot:[`item.additionalCostsAmount`]="{ item }">
                    <span v-if="item.additionalCostsAmount != null">{{
                      item.additionalCostsAmount | currency
                    }}</span>

                  </template>
                </v-data-table>


                <v-btn
                  class="mt-5"
                  @click="
                    additionalCostsObj.costType.id = null;
                    additionalCostsObj.additionalCostsAmount = 0.0;
                    additionalCostsObj.additionalCostsDate = null;
                    additionalCostsAddDialog = true;
                  "
                >
                  Προσθηκη
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-btn
                tile color="primary darken-2"
                @click="submitErgolabikh"
            >αποθηκευση</v-btn>
<!--            <v-btn tile color="primary darken-2" @click="submitErgolabikh"-->
<!--              >αποθηκευση</v-btn-->
<!--            >-->
            <!-- <v-btn
              class="ml-2"
              tile
              color="primary darken-2"
              @click="$router.go(-1)"
              >ΑΚΥΡΟ</v-btn
            > -->
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-dialog v-model="additionalCostsAddDialog" width="400">
      <v-card>
        <v-card-title> ΠΡΟΣΘΗΚΗ</v-card-title>
        <v-card-text>
          <v-form ref="additionalCostsAddForm">

            <v-select
                label="Δαπάνη"
                :items="costData"
                item-text="name"
                item-value="id"
                v-model="additionalCostsObj.costType.id"
            ></v-select>

            <v-text-field
              type="number"
              label="Ποσό"
              :rules="requiredRules"
              :hint="additionalCostsObj.additionalCostsAmount | currency"
              persistent-hint
              v-model="additionalCostsObj.additionalCostsAmount"
            ></v-text-field>

            <v-menu
              ref="menu6"
              v-model="menu6"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="
                    greekFormatDate(additionalCostsObj.additionalCostsDate)
                  "
                  label="Ημερομηνία"
                  append-icon="mdi-calendar"
                  readonly
                  class="mt-4"
                  v-on="on"
                  clearable
                  @click:clear="
                    additionalCostsObj.additionalCostsDate = null
                  "
                >
                  ></v-text-field
                >
              </template>
              <v-date-picker
                v-model="additionalCostsObj.additionalCostsDate"
                @change="save6"
              ></v-date-picker>
            </v-menu>

            <v-btn @click="addAdditionalCost" :loading="addLoader"
              >Προσθηκη</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="additionalCostsEditDialog" width="400">
      <v-card>
        <v-card-title> ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
        <v-card-text>
          <v-form ref="additionalCostsEditForm">
            <v-text-field
              type="number"
              label="Ποσό"
              :rules="requiredRules"
              :hint="currentAdditionalCostsObj.additionalCostsAmount | currency"
              persistent-hint
              v-model="currentAdditionalCostsObj.additionalCostsAmount"
            ></v-text-field>

            <v-menu
              ref="menu7"
              v-model="menu7"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="
                    greekFormatDate(
                      currentAdditionalCostsObj.additionalCostsDate
                    )
                  "
                  label="Ημερομηνία"
                  append-icon="mdi-calendar"
                  readonly
                  class="mt-4"
                  v-on="on"
                  clearable
                  @click:clear="
                    currentAdditionalCostsObj.additionalCostsDate = null
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="currentAdditionalCostsObj.additionalCostsDate"
                @change="save7"
              >
              </v-date-picker>
            </v-menu>

            <v-btn @click="editAdditionalCost" :loading="editLoader"
              >Επεξεργασία</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {mapState} from "vuex";

export default {
  async created() {
    try {
      await this.$store.dispatch("getCostData");

      const res = await this.$store.dispatch(
        "getErgolabikhSumbash",
        this.$route.params.project_id
      );

      // console.log(res.data.status);
      if (res.data.status == 0) {
        // console.log(res.data.data);

        if (res.data.data.jobConstructorContractTempDelivery == null) {
          res.data.data.jobConstructorContractTempDelivery = {
            protocolNumber: null,
            protocolNumberDate: null,
            jobConstructorContractTempDeliveryAttachments: [],
          };
        }
        if (res.data.data.jobConstructorContractFinalDelivery == null) {
          res.data.data.jobConstructorContractFinalDelivery = {
            protocolNumber: null,
            protocolNumberDate: null,
            jobConstructorContractFinalDeliveryAttachments: [],
          };
        }
        if (res.data.data.jobConstructorContractCompletionCertificate == null) {
          res.data.data.jobConstructorContractCompletionCertificate = {
            protocolNumber: null,
            protocolNumberDate: null,
            jobConstructorContractCompletionCertificateAttachments: [],
          };
        }
        this.ergolabikhSumvashObj = res.data.data;
        if(res.data.data && res.data.data.contractTermination !== "")
          this.contractTermination = res.data.data?.contractTermination;


        this.ergolabikhEdit = true;
        await this.additionalCostsHandler();
        this.additionalCostsObj.jobConstructorContractId =
          this.ergolabikhSumvashObj.id;

        this.currentAdditionalCostsObj.jobConstructorContractId =
          this.ergolabikhSumvashObj.id;
      }

      this.ergolabikhSumvashDialog = true;
      this.pageLoader = false;
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      pageLoader: true,
      addLoader: false,
      editLoader: false,
      currentAdditionalCostsObj: {
        id: null,
        additionalCostsAmount: null,
        jobConstructorContractId: null,
        additionalCostsDate: null,
      },
      additionalCostsAddDialog: false,
      additionalCostsEditDialog: false,
      additionalCostsObj: {
        costType: {
          id:null,
          name:null
        },
        additionalCostsAmount: 0.0,
        jobConstructorContractId: null,
        additionalCostsDate: null,
      },
      additionalCosts: [],
      additionalCostsOptions: {
        itemsPerPage: 20,
        page: 1,
      },

      additionalCostsHeaders: [
        {
          text: "Δαπάνη",
          value: "costType.name",
          sortable: false,
        },

        {
          text: "Ποσό",
          value: "additionalCostsAmount",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "additionalCostsDate",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        }

      ],

      additionalCostsCounter: null,
      //yesOrNo: [{label:'ΟΧΙ', itemValue:false},{label:'ΝΑΙ', itemValue:true}],
      yesOrNo: ["ΟΧΙ", "ΝΑΙ"],
      contractTermination: "ΟΧΙ",
      ergolabikhSumvashDialog: null,
      ergolabikhSumvashObj: {
        id: null,
        amount: "",
        endDate: "",
        startDate: "",
        title: "",
        meanDiscount: null,
        lastApe: "",
        contractor: "",
        jobConstructorContractAttachments: [],


        //proswrinh paralavh

        jobConstructorContractTempDelivery: {
          protocolNumber: null,
          protocolNumberDate: null,
          jobConstructorContractTempDeliveryAttachments: [],
        },
        jobConstructorContractFinalDelivery: {
          protocolNumber: null,

          protocolNumberDate: null,
          jobConstructorContractFinalDeliveryAttachments: [],
        },
        jobConstructorContractCompletionCertificate: {
          protocolNumber: null,
          protocolNumberDate: null,
          jobConstructorContractCompletionCertificateAttachments: [],
        },
      },
      ergolabikhFiles: [],
      ergolabikhEdit: false,
      valid: true,
      requiredRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,

      jobConstructorContractTempDeliveryFiles: [],
      jobConstructorContractFinalDeliveryFiles: [],
      jobConstructorContractCompletionCertificateFiles: [],

      fileHeaders: [
        {
          text: "Όνομα",
          value: "filename",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    async additionalCostsOptions() {
      await this.additionalCostsHandler();
    },
  },

  computed: {
    ...mapState({
      costData: state => state.settings.costData
    }),


    jobConstructorContractAttachmentsIds() {
      if (
        this.ergolabikhSumvashObj.jobConstructorContractAttachments.length == 0
      )
        return [];
      return this.ergolabikhSumvashObj.jobConstructorContractAttachments.map(
        (item) => item.id
      );
    },
  },

  methods: {
    async additionalCostsHandler() {
      try {
        if (this.ergolabikhSumvashObj.id != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/constructor/additional/costs/list/paging/${this.ergolabikhSumvashObj.id}`,
            {
              params: {
                page: this.additionalCostsOptions.page,
              },
            }
          );
          this.additionalCosts = res.data.data;

          const res1 = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/constructor/additional/costs/list/counter/${this.ergolabikhSumvashObj.id}`
          );
          this.additionalCostsCounter = res1.data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    save3(date) {
      this.$refs.menu3.save(date);
    },
    save4(date) {
      this.$refs.menu4.save(date);
    },
    save5(date) {
      this.$refs.menu5.save(date);
    },

    save6(date) {
      this.$refs.menu6.save(date);
    },
    save7(date) {
      this.$refs.menu7.save(date);
    },

    async addAdditionalCost() {
      if (this.$refs.additionalCostsAddForm.validate()) {
        try {
          this.addLoader = true;
          let data = {...this.additionalCostsObj}
          data.additionalCostsAmount = Number(data.additionalCostsAmount)
          data.costType = data.costType.id
          console.log(data)
          const res = await axios.post(
            `${process.env.VUE_APP_BASEURL}/api/job/constructor/additional/costs/add`,
              data

          );
          this.addLoader = false;

          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);
            return;
          }
          await this.additionalCostsHandler();
          this.additionalCostsAddDialog = false;
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        } catch (e) {
          this.addLoader = false;
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(e);
        }
      }
    },

    async editAdditionalCost() {
      if (this.$refs.additionalCostsEditForm.validate()) {
        try {
          this.editLoader = true;
          const res = await axios.put(
            `${process.env.VUE_APP_BASEURL}/api/job/constructor/additional/costs/edit/${this.currentAdditionalCostsObj.id}`,
            this.currentAdditionalCostsObj
          );
          this.editLoader = false;

          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);
            return;
          }
          await this.additionalCostsHandler();
          this.additionalCostsEditDialog = false;
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        } catch (e) {
          this.editLoader = false;
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(e);
        }
      }
    },

    async deleteAdditionalCost(id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_BASEURL}/api/job/constructor/additional/costs/delete/${id}`,
          this.currentAdditionalCostsObj
        );
        if (res.data.status === -1) {
          this.$store.dispatch("errorHandler", res.data.message);
          return;
        }
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });

        if (
          this.additionalCosts.length == 1 &&
          this.additionalCostsOptions.page != 1
        )
          this.additionalCostsOptions.page -= 1;
        else await this.additionalCostsHandler();
      } catch (e) {
        console.log(e);
        this.$store.commit("toggleSnackbar", { open: true, text: "Αποτυχία" });
      }
    },

    submitErgolabikh() {
      console.log('aaa')
      if (this.$refs.form.validate()) {
        // console.log(this.ergolabikhSumvashObj.title);
        const credentials = {
          obj: {
            amount: parseFloat(this.ergolabikhSumvashObj.amount),
            endDate: this.ergolabikhSumvashObj.endDate,
            jobPeeId: this.$store.state.projects.projectEditing.id,
            startDate: this.ergolabikhSumvashObj.startDate,
            meanDiscount: parseFloat(this.ergolabikhSumvashObj.meanDiscount),
            title: this.$store.state.projects.projectEditing.title,
            contractor: this.ergolabikhSumvashObj.contractor,
            lastApe: parseFloat(this.ergolabikhSumvashObj.lastApe),
            jobConstructorContractAttachmentsIds:
              this.jobConstructorContractAttachmentsIds,
            contractTermination: this.contractTermination,
            //temp
            jobConstructorContractTempDeliveryProtocolNumber:
              this.ergolabikhSumvashObj.jobConstructorContractTempDelivery
                .protocolNumber,
            jobConstructorContractTempDeliveryProtocolNumberDate:
              this.ergolabikhSumvashObj.jobConstructorContractTempDelivery
                .protocolNumberDate,
            jobConstructorContractTempDeliveryAttachmentsIds:
              this.ergolabikhSumvashObj.jobConstructorContractTempDelivery.jobConstructorContractTempDeliveryAttachments.map(
                (item) => item.id
              ),

            //final
            jobConstructorContractFinalDeliveryProtocolNumber:
              this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery
                .protocolNumber,
            jobConstructorContractFinalDeliveryProtocolNumberDate:
              this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery
                .protocolNumberDate,
            jobConstructorContractFinalDeliveryAttachmentsIds:
              this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery.jobConstructorContractFinalDeliveryAttachments.map(
                (item) => item.id
              ),

            //certificate
            jobConstructorContractCompletionCertificateProtocolNumber:
              this.ergolabikhSumvashObj
                .jobConstructorContractCompletionCertificate.protocolNumber,
            jobConstructorContractCompletionCertificateProtocolNumberDate:
              this.ergolabikhSumvashObj
                .jobConstructorContractCompletionCertificate.protocolNumberDate,
            jobConstructorContractCompletionCertificateAttachmentsIds:
              this.ergolabikhSumvashObj.jobConstructorContractCompletionCertificate.jobConstructorContractCompletionCertificateAttachments.map(
                (item) => item.id
              ),
          },
          id: this.ergolabikhSumvashObj.id,
          //
        };
        console.log('eeee')
        if (this.ergolabikhEdit) {
          this.$store
            .dispatch("editErgolabikhSumbash", credentials)
            .then((res) => {
              // console.log(res);

              this.ergolabikhSumvashDialog = false;
              // console.log("1", res.data.status);
              if (res.data.status == 0) {
                this.$store.commit("toggleDialog", {
                  open: true,
                  text: "Επιτυχία",
                });
                this.$router.push(
                  `/projects/${this.$route.params.project_id}/edit-project`
                );
              } else {
                if (res.data.status === -1) {
                  this.$store.dispatch("errorHandler", res.data.message);
                  return;
                }
              }
            })
            .catch((err) => {
              console.log(err);
              this.ergolabikhSumvashDialog = false;
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });
            });
        } else {
          this.$store
            .dispatch("setErgolabikhSumbash", credentials)
            .then((res) => {
              //  console.log(res);
              this.ergolabikhSumvashDialog = false;
              // console.log("2", res.data.status);
              if (res.data.status == 0) {
                this.$store.commit("toggleDialog", {
                  open: true,
                  text: "Επιτυχία",
                });
                this.$router.push(
                  `/projects/${this.$route.params.project_id}/edit-project`
                );
              } else {
                if (res.data.status === -1) {
                  this.$store.dispatch("errorHandler", res.data.message);
                  return;
                }
              }
            })
            .catch((err) => {
              console.log(err);

              this.ergolabikhSumvashDialog = false;
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });
            });
        }
      }
    },
    uploadErgolabikh() {
      let formData = new FormData();
      this.ergolabikhFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });


      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          // console.log(res);
          res.data.data.forEach((item) => {
            this.ergolabikhSumvashObj.jobConstructorContractAttachments.push(
              item
            );
          });
          this.ergolabikhFiles = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },
    download(item) {
      console.log(item);
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/download/${item.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          console.log(item.filename);
          fileLink.setAttribute("download", item.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteErgolabikhFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobConstructionContractId: this.ergolabikhSumvashObj.id
                ? this.ergolabikhSumvashObj.id
                : 0,
            },
          }
        )
        .then(() => {
          this.ergolabikhSumvashObj.jobConstructorContractAttachments.splice(
            this.ergolabikhSumvashObj.jobConstructorContractAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    deleteProsoriniFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobConstructionContractId: this.ergolabikhSumvashObj.id
                ? this.ergolabikhSumvashObj.id
                : 0,
            },
          }
        )

        .then(() => {
          this.ergolabikhSumvashObj.jobConstructorContractTempDelivery.jobConstructorContractTempDeliveryAttachments.splice(
            this.ergolabikhSumvashObj.jobConstructorContractTempDelivery.jobConstructorContractTempDeliveryAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    deleteOristikiFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobConstructionContractId: this.ergolabikhSumvashObj.id
                ? this.ergolabikhSumvashObj.id
                : 0,
            },
          }
        )

        .then(() => {
          this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery.jobConstructorContractFinalDeliveryAttachments.splice(
            this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery.jobConstructorContractFinalDeliveryAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    deleteVevaiwshFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobConstructionContractId: this.ergolabikhSumvashObj.id
                ? this.ergolabikhSumvashObj.id
                : 0,
            },
          }
        )

        .then(() => {
          this.ergolabikhSumvashObj.jobConstructorContractCompletionCertificate.jobConstructorContractCompletionCertificateAttachments.splice(
            this.ergolabikhSumvashObj.jobConstructorContractCompletionCertificate.jobConstructorContractCompletionCertificateAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    greekFormatDate(date) {
      if (!date) return "";
      return moment(moment.utc(date).toDate()).local().format("DD/MM/YYYY");
    },

    uploadProsorini() {
      let formData = new FormData();
      this.jobConstructorContractTempDeliveryFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          // console.log(res);
          res.data.data.forEach((item) => {
            this.ergolabikhSumvashObj.jobConstructorContractTempDelivery.jobConstructorContractTempDeliveryAttachments.push(
              item
            );
          });
          this.jobConstructorContractTempDeliveryFiles = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    uploadOristiki() {
      let formData = new FormData();
      this.jobConstructorContractFinalDeliveryFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          // console.log(res);
          res.data.data.forEach((item) => {
            this.ergolabikhSumvashObj.jobConstructorContractFinalDelivery.jobConstructorContractFinalDeliveryAttachments.push(
              item
            );
          });
          this.jobConstructorContractFinalDeliveryFiles = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    uploadVevaiwsh() {
      let formData = new FormData();
      this.jobConstructorContractCompletionCertificateFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          // console.log(res);
          res.data.data.forEach((item) => {
            this.ergolabikhSumvashObj.jobConstructorContractCompletionCertificate.jobConstructorContractCompletionCertificateAttachments.push(
              item
            );
          });
          this.jobConstructorContractCompletionCertificateFiles = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
