<!-- TODO: add perifereia logo -->
<!-- TODO: centralize nav items -->
<!-- TODO: change profile icon to button -->

<template>
  <div>
    <v-toolbar dense dark color="primary darken-3">
      <v-toolbar-items v-if="!!$store.state.auth.token">
        <v-btn text to="/">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn text to="/requests">Αιτηματα</v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">ΠΕΕ</v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in PeeItems"
              :key="index"
              @click="
                $router
                  .push(item.to)
                  .then()
                  .catch((err) => {})
              "
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">ΠΔΕ</v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in PdeItems"
              :key="index"
              @click="
                $router
                  .push(item.to)
                  .then()
                  .catch((err) => {})
              "
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text to="/settings">Ρυθμισεις</v-btn>
        <v-btn text to="/searches">Αναζητησεις</v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <p class="mb-0" v-if="!!$store.state.auth.token">
          {{ $store.state.auth.user ? $store.state.auth.user.firstName : "" }}
          {{ $store.state.auth.user ? $store.state.auth.user.lastName : "" }}
        </p>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon large v-on="on">mdi-account</v-icon>
          </template>
          <v-list>
            <v-list-item v-if="!!!$store.state.auth.token">
              <v-list-item-title @click="$router.push('/login')"
                >Σύνδεση</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-else @click="logout"> Αποσύνδεση </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      PeeItems: [
        { name: "ΕΡΓΑ", to: "/projects" },
        { name: "ΝΕΟ ΠΕΕ", to: "/new-pee" },
        {
          name: "ΕΝΗΜΕΡΩΣΗ ΟΙΚΟΝΟΜΙΚΩΝ ΣΤΟΙΧΕΙΩΝ",
          to: "/upload-financial-details",
        },
        { name: "ΑΝΑΦΟΡΕΣ", to: "/reports" },
      ],
      PdeItems: [
        { name: "ΕΡΓΑ", to: "/pde/projects" },
        { name: "ΑΝΑΦΟΡΕΣ", to: "" },
      ],
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
