const axios = require('axios');

export default {
    state: {
        data: [],
        axisData: [],
        typeData: [],
        measureData: [],
        municipalityData: [],
        costData: []
    },
    getters: {

    },
    mutations: {
        setList(state, payload) {
            state.data = payload.data;
        },
        setAxisList(state, payload) {
            state.axisData = payload.data;
        },
        setTypeList(state, payload) {
            state.typeData = payload.data;
        },
        setMeasureList(state, payload) {
            state.measureData = payload.data;
        },
        setMunicipalityList(state, payload) {
            state.municipalityData = payload.data;
        },
        setCostList(state, payload) {
            state.costData = payload.data;
        }
    },
    actions: {
        getListData({ commit }, url) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/${url}`)
                    .then(res => {
                        commit('setList', res.data);
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            })
        },
        editGeneral({ commit, dispatch }, credentials) {
            console.log(credentials)

            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/${credentials.path}`, { name: credentials.name })
                    .then(res => {
                        dispatch('getListData', credentials.refreshListUrl)
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            }).catch(err => {
                                reject(err);
                            })

                    })
                    .catch(err => {
                        reject(err);
                    });
            })
        },
        addGeneral({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}${credentials.url}`, { name: credentials.name })
                    .then(res => {
                        dispatch('getListData', credentials.refreshListUrl)
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        delete({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {

                axios.delete(`${process.env.VUE_APP_BASEURL}/${credentials.path}`, { id: credentials.id })
                    .then(res => {
                        dispatch('getListData', credentials.refreshListUrl)
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            })
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
        },
        getAxisData({ commit }) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/axis/list`)
                    .then(res => {
                        commit('setAxisList', res.data);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getTypeData({ commit }) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/type/list`)
                    .then(res => {
                        commit('setTypeList', res.data);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getMeasureData({ commit }) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/measure/list`)
                    .then(res => {
                        commit('setMeasureList', res.data);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getMunicipalityData({ commit }) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/unit/list`)
                    .then(res => {
                        commit('setMunicipalityList', res.data);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getCostData({ commit }) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/cost/list`)
                    .then(res => {
                        commit('setCostList', res.data);
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editMeasure({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/measure/edit/${credentials.id}`, { jobAxisId: credentials.jobAxisId, name: credentials.name })
                    .then(res => {
                        dispatch('getListData', 'job/measure/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editAxis({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/axis/edit/${credentials.id}`, { jobTypeId: credentials.jobTypeId, name: credentials.name })
                    .then(res => {
                        dispatch('getListData', 'job/axis/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editGoal({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/goal/edit/${credentials.id}`, { jobMeasureId: credentials.jobMeasureId, name: credentials.name })
                    .then(res => {
                        dispatch('getListData', 'job/goal/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editMunicipality({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/municipality/edit/${credentials.id}`, { unitId: credentials.unitId, name: credentials.name })
                    .then(res => {
                        dispatch('getListData', 'municipality/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        editCost({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/cost/edit/${credentials.id}`, { costId: credentials.costId, name: credentials.name })
                    .then(res => {
                        dispatch('getListData', 'api/job/cost/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        async editSemester({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            try {
                await axios.put(`${process.env.VUE_APP_BASEURL}/api/pde/semester/edit/${credentials.id}`, {
                    code: credentials.code,
                    description: credentials.description
                })
                dispatch('getListData', 'pde/semester/list')
            } catch (err) {
                console.log(err)
            } finally {
                commit('setLoading', false);
            }
        },
        async editSubsector({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            try {
                await axios.put(`${process.env.VUE_APP_BASEURL}/api/pde/subsector/edit/${credentials.id}`, {
                    code: credentials.code,
                    name: credentials.name
                })
                dispatch('getListData', 'pde/subsector/list')
            } catch (err) {
                console.log(err)
            } finally {
                commit('setLoading', false);
            }
        },
        addAxis({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/axis/add`, { ...credentials })
                    .then(res => {
                        dispatch('getListData', 'job/axis/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addMeasure({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/measure/add`, { ...credentials })
                    .then(res => {
                        dispatch('getListData', 'job/measure/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addGoal({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/goal/add`, { ...credentials })
                    .then(res => {
                        dispatch('getListData', 'job/goal/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        addMunicipality({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/municipality/add`, { ...credentials })
                    .then(res => {
                        dispatch('getListData', 'municipality/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addCost({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/cost/add`, { ...credentials })
                    .then(res => {
                        dispatch('getListData', 'api/job/cost/list')
                            .then(() => {
                                commit('setLoading', false);
                                resolve(res);
                            })
                            .catch(err => {
                                reject(err);
                            });

                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        async addSemester({ commit, dispatch }, credentials) {
            commit('setLoading', true);
            try {
                await axios.post(`${process.env.VUE_APP_BASEURL}/api/pde/semester/add`, { ...credentials })
                dispatch('getListData', 'pde/semester/list')
            } catch (error) {
                console.log(error)
            } finally {
                commit('setLoading', false)
            }
        },
        async addSubsector({ commit, dispatch }, credentials) {
            console.log(credentials)

            commit('setLoading', true);
            try {
                await axios.post(`${process.env.VUE_APP_BASEURL}/api/pde/subsector/add`, { ...credentials })
                dispatch('getListData', 'pde/subsector/list')
            } catch (error) {
                console.log(error)
            } finally {
                commit('setLoading', false)
            }
        }
    }
}