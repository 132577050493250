<template>
  <div>
    <v-breadcrumbs
      :items="[
        {
          text: 'ΑΝΑΖΗΤΗΣΕΙΣ',
          disabled: false,
          link: true,
          exact: true,
          to: '/searches',
        },
        { text: 'ΑΝΑΖΗΤΗΣΗ ΜΕΛΟΥΣ ΕΠΙΤΡΟΠΗΣ' },
      ]"
      divider=">"
    ></v-breadcrumbs>
    <v-row justify="center">
      <h2 class="display-1">ΑΝΑΖΗΤΗΣΗ ΜΕΛΟΥΣ ΕΠΙΤΡΟΠΗΣ</h2>
    </v-row>
    <v-row justify="center" class="mt-12">
      <v-col cols="6">
        <v-text-field v-model="query" label="Όνομα μέλους"></v-text-field>
        <v-row align-content="center">
          <v-col cols="6">
            <v-checkbox v-model="chairCommittee" label="Πρόεδρος"></v-checkbox>
            <v-checkbox
              v-model="viceChairCommittee"
              label="Αναπληρωτής πρόεδρος"
            ></v-checkbox>
            <v-checkbox
              v-model="currentCommittee"
              label="Ενεργή επιτροπή"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="member" label="Μέλος"></v-checkbox>
            <v-checkbox v-model="pee" label="ΠΕΕ"></v-checkbox>
            <v-checkbox v-model="pde" label="ΠΔΕ"></v-checkbox>
          </v-col>
          <v-btn block @click="search(1)">αναζητηση</v-btn>
        </v-row>
      </v-col> </v-row
    >,
    <v-row justify="center">
      <v-card>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>ΚΑΕ/Κωδικός έργου</th>
                <th>Τίτλος Έργου</th>
                <th>Τύπος</th>
                <th>Πρόεδρος</th>
                <th>Αναπληρωτής Πρόεδρος</th>
                <th>Μέλος</th>
                <th>ΑΔΑ</th>
                <th>Μεταβαση</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="item in results" :key="item.id">
                <td>
                  <p>{{ item.kae }}</p>
                </td>
                <td>
                  <p>{{ item.job_title }}</p>
                </td>
                <td>
                  <p>{{ item.job_type }}</p>
                </td>
                <td>
                  <p>{{ item.chair_committee }}</p>
                </td>
                <td>
                  <p>{{ item.vice_chair_committee }}</p>
                </td>
                <td>
                  <p>{{ item.members_planning_department }}</p>
                </td>
                <td>
                  <p>{{ item.ada }}</p>
                </td>
                <td @click="check(item.pee_job_id)">
                  <v-icon style="cursor: pointer" large
                    >mdi-arrow-right-box</v-icon
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      results: [],
      chairCommittee: false,
      viceChairCommittee: false,
      currentCommittee: false,
      member: false,
      pee: false,
      pde: false,
      query: "",
      loading: true,
    };
  },
  methods: {
    search(page) {
      this.loading = true;
      let actualQuery;
      if (this.query == "") {
        actualQuery = "*";
      } else {
        actualQuery = this.query;
      }
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/search/job/programmatic/contract/committee?query=${actualQuery}&page=${page}&chairCommittee=${this.chairCommittee}&viceChairCommittee=${this.viceChairCommittee}&member=${this.member}&pee=${this.pee}&pde=${this.pde}&currentCommittee=${this.currentCommittee}`
        )
        .then((res) => {
          console.log(res);
          this.results = res.data.data.documents;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },
    check(pee_job_id) {
      console.log(pee_job_id);
      axios
        .get(`${process.env.VUE_APP_BASEURL}/api/pee/job/${pee_job_id}`)
        .then((res) => {
          const credentials = {
            fromSearch: false,
            itemSelected: res.data.data,
          };
          this.$store
            .dispatch("setProjectEditing", credentials)
            .then(() => {
              this.$router.push("/projects/edit-project");
            })
            .catch((err) => {
              console.log(err);
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
                color: "red",
              });
            });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
            color: "red",
          });
        });
    },
  },
};
</script>
