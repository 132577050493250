import axios from 'axios';
//import createPersistedState from 'vuex-persistedstate';
export default {
    state: {
        units: [],
        categories: [],
        phases: [],
        researches: [],
        types: [],
        axis: [],
        measures: [],
        goals: [],
        municipalities: [],
        projectEditing: JSON.parse(localStorage.getItem("projectEditing")) || null
    },
    getters: {

    },
    mutations: {
        setErgolabikh(state, payload) {
            state.projectEditing.jobConstructorContract = payload
            localStorage.setItem('projectEditing', JSON.stringify(state.projectEditing))
        },
        setUnits(state, payload) {
            state.units = payload;
        },
        setCategories(state, payload) {
            state.categories = payload;
        },
        setPhases(state, payload) {
            state.phases = payload;
        },
        setResearches(state, payload) {
            state.researches = payload;
        },
        setType(state, payload) {
            state.types = payload;
        },
        setAxis(state, payload) {
            state.axis = payload;
        },
        setMeasures(state, payload) {
            state.measures = payload;
        },
        setGoals(state, payload) {
            state.goals = payload;
        },
        setProjectEditing(state, payload) {
            state.projectEditing = payload;
        },
        setMunicipalities(state, payload) {
            state.municipalities = payload
        },
        setEntities(state, payload) {
            state.entities = payload
        }
    },
    actions: {
        getNewPeeJobData({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/unit/list`)
                    .then(res => {
                        commit('setUnits', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/category/list`);
                    })
                    .then(res => {
                        commit('setCategories', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/phase/list`);
                    })
                    .then(res => {
                        commit('setPhases', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/research/list`)
                    })
                    .then(res => {
                        commit('setResearches', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/type/list`)
                    })
                    .then(res => {
                        commit('setType', res.data.data)
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/axis/list`);
                    })
                    .then(res => {
                        commit('setAxis', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/measure/list`)
                    })
                    .then(res => {
                        commit('setMeasures', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/goal/list`)
                    })
                    .then(res => {
                        commit('setGoals', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/municipality/list`)
                        //    resolve();
                    })
                    .then(res => {
                        commit('setMunicipalities', res.data.data)
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/entity/organization/list`)
                        //resolve()
                    })
                    .then(res => {
                        commit('setEntities', res.data.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        newPeeProject({commit}, credentials) {
            commit('setLoading', true)
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/job/add`, {...credentials})
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        async setProjectEditing({commit}, id) {
            // if (!credentials.fromSearch) {
            //     localStorage.setItem("projectEditing", JSON.stringify(credentials.itemSelected));
            //     commit('setProjectEditing', credentials.itemSelected);
            // } else {
            //     console.log('from search')
            try {

                const peeJob = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/job/${id}`)

                const res = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/job/requests/${id}`)

                peeJob.data.data.jobRequests = res.data.data;
                commit('setProjectEditing', peeJob.data.data);

            } catch (e) {
                console.log(e)
            }


        },
        async exportDevices() {
            const response = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/excel/export`, {responseType: "arraybuffer"})
            const url = window.URL.createObjectURL(
                //new Blob([res.data], {type: "application/vnd.ms-excel"})
                new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Στοιχεία_ΠΕΕ.xlsx");
            document.body.appendChild(link);
            link.click();
        },

        async exportFinancialExcel(){
            const res = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/job/financial/export`, {responseType: "arraybuffer"})

            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Οικονομικά_Στοιχεία.xlsx");
            document.body.appendChild(link);
            link.click();
        },
        // async importFinancialExcel(){
        //     const res = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/excel/import/financial`)
        // },
        // async importDevices(){
        //     const res = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/excel/import`)
        // },
        updateProjectEditing({ commit }, credentials) {
            localStorage.setItem("projectEditing", JSON.stringify(credentials));
            commit('setProjectEditing', credentials)
        },
        editPeeProject({ commit }, credentials) {
            commit('setLoading', true)
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/pee/job/edit/${credentials.id}`, credentials.peeJobParameters)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res)
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    });
            })
        },
        searchProject({ commit }, credentials) {
            commit('setLoading', true);
            console.log(credentials.unit);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/search/pee/job`, {
                    params: {
                        query: credentials.query,
                        page: credentials.page,
                        jobType: credentials.jobType.join(','),
                        jobResearch: credentials.jobResearch.join(','),
                        jobPhase: credentials.jobPhase.join(','),
                        newYearJobPhase: credentials.newYearJobPhase.join(','),
                        jobCategory: credentials.jobCategory.join(','),
                        jobAxis: credentials.jobAxis.join(','),
                        jobMeasure: credentials.jobMeasure.join(','),
                        jobGoal: credentials.jobGoal.join(','),
                        unit: credentials.unit.join(','),
                        year: credentials.year.join(','),
                        kae: credentials.kae,
                        title: credentials.title
                    }
                })
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    });


            });
        },
        getErgolabikhSumbash({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/constructor/contract/peejob/${credentials}`)
                    .then(res => {
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        getProgrammatikhSumvash({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/peejob/${credentials}`)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .then(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        setErgolabikhSumbash({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/constructor/contract/add`, credentials.obj)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        setProgrammatikhSumbash({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/add`, credentials.obj)
                    .then(res => {
                        commit('setErgolabikh', res.data.data)
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject('error', err);
                    })
            })
        },
        editErgolabikhSumbash({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/constructor/contract/edit/${credentials.id}`, credentials.obj)
                    .then(res => {
                        commit('setErgolabikh', res.data.data)
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    });
            })
        },
        editProgrammatikhSumbash({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/edit/${credentials.id}`, credentials.obj)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        submitNewCommittee({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/committee/add`, { ...credentials })
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        submitEditCommittee({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/committee/edit/${credentials.id}`, credentials.obj)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);

                    })
            })
        },
        getFinancialDetails({ commit }, credentials) {
            // console.log('id', credentials)
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/job/financial/details/peejob/${credentials}`)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    });
            });
        },
        addFinancialDetails({ commit }, credentials) {
            // console.log('tha gamhsw?',credentials)
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/job/financial/details/add`, credentials)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        editFinancialDetails({ commit }, credentials) {
            commit('setLoading', true)
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/pee/job/financial/details/edit/${credentials.id}`, credentials.body)
                    .then(res => {
                        commit('setLoading', false)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('setLoading', false)
                        reject(err)
                    })
            })
        },
        newPee({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/add`, { ...credentials })
                    .then(res => {
                        commit('setLoading', false)
                        resolve(res)
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err)
                    })
            })
        }
    }
}