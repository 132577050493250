<template>
  <div>
    <v-footer class="justify-center py-3" color="#424242">
      <p class="body-2 text-center mb-0 white--text">
        &copy;&nbsp;{{ year.getFullYear() }} - Περιφέρεια Αττικής
        <!-- <a id="devbrains">Dev Brains</a> -->
      </p>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date(),
    };
  },
};
</script>
