<template>
  <div>
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>

    <v-row v-else align="center" justify="center">
      <v-col cols="3" v-if="!editModification">
        <v-btn class="primary darken-2" @click="addingDialog = true"
          >ΠΡΟΣΘΗΚΗ ΤΡΟΠΟΠΟΙΗΣΗΣ</v-btn
        >
      </v-col>

      <v-col cols="8" v-else>
        <v-form @submit.prevent="submit" class="ml-12">
          <v-row justify="center">
            <v-col cols="5">
              <v-text-field
                label="Τίτλος*"
                :error-messages="titleErrors"
                v-model="title"
              ></v-text-field>
              <v-text-field label="ΚΑΕ" v-model="kae"></v-text-field>

              <v-autocomplete
                label="Περιφερειακή ενότητα/τομέας"
                :error-messages="unitErrors"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.units"
                v-model="unitId"
              ></v-autocomplete>

              <v-autocomplete
                label="Φάση έργου αρχή έτους"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.phases"
                v-model="newYearJobPhaseId"
              ></v-autocomplete>

              <v-autocomplete
                label="Δήμος"
                item-value="id"
                item-text="name"
                :items="availableMunicipalities"
                v-model="municipalityId"
              ></v-autocomplete>

              <v-autocomplete
                label="Φάση έργου"
                item-value="id"
                :error-messages="jobPhaseErrors"
                item-text="name"
                :items="this.$store.state.projects.phases"
                v-model="jobPhaseId"
              ></v-autocomplete>

              <v-autocomplete
                label="Μελέτη"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.researches"
                v-model="jobResearchId"
              ></v-autocomplete>

              <v-autocomplete
                label="Είδος έργου"
                item-value="id"
                :error-messages="jobTypesErrors"
                item-text="name"
                :items="this.$store.state.projects.types"
                v-model="jobTypeId"
              ></v-autocomplete>

              <!-- <v-text-field label="Θέση" v-model="place"></v-text-field> -->

              <v-autocomplete
                label="Φορέας αρμοδιότητας"
                :items="entitiesAndMunicipalities"
                item-text="name"
                item-value="name"
                v-model="propertyEntity"
              ></v-autocomplete>

              <v-autocomplete
                label="Φορέας υλοποίησης"
                :items="entitiesAndMunicipalities"
                item-text="name"
                item-value="name"
                v-model="implementingEntity"
              ></v-autocomplete>
            </v-col>

            <v-col cols="5" offset="1">
              <v-text-field
                label="Διευθύνουσα υπηρεσία"
                v-model="managementService"
              ></v-text-field>

              <v-text-field
                label="Προϋπολογισμός (προ δημοπράτησης)*"
                :error-messages="budgetErrors"
                v-model="budgetBeforeAuction"
                :hint="budgetBeforeAuction | currency"
                persistent-hint
              ></v-text-field>

              <v-text-field
                label="Προτεινόμενες Πιστώσεις*"
                v-model="suggestedCredits"
                :hint="suggestedCredits | currency"
                :error-messages="suggestedCreditsErrors"
                persistent-hint
              ></v-text-field>
              <!-- <v-text-field label="Ανάδοχος" v-model="contractor"></v-text-field> -->

              <v-autocomplete
                label="Κατηγορία έργου"
                item-value="id"
                :error-messages="jobCategoryErrors"
                class="mt-4"
                item-text="name"
                :items="this.$store.state.projects.categories"
                v-model="jobCategoryId"
              >
              </v-autocomplete>

              <!-- <v-text-field label="Αντισυμβαλλόμενοι" v-model="jobPartners"></v-text-field> -->
              <v-autocomplete
                label="Άξονας"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.axis"
                v-model="jobAxisId"
              ></v-autocomplete>
              <v-autocomplete
                label="Μέτρο"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.measures"
                v-model="jobMeasureId"
              ></v-autocomplete>
              <v-autocomplete
                label="Στόχος"
                item-value="id"
                item-text="name"
                :items="this.$store.state.projects.goals"
                v-model="jobGoalId"
              ></v-autocomplete>

              <v-text-field
                  label="Προτεινόμενος προϋπολογισμός (νομική δέσμευση)*"
                  v-model="suggestedBudget"
                  :hint="suggestedBudget | currency"
                  persistent-hint
              ></v-text-field>

              <!-- <v-text-field label="Φορέας χρηματοδότησης" v-model="fundingEntity"></v-text-field> -->
              <v-text-field
                label="Ένταξη στο ΠΕΕ"
                v-model="budgetComments"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-list shaped elevation="5">
            <v-list-item-group v-model="selected" multiple>
              <template v-for="(item, i) in reasons">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                <v-list-item
                  v-else
                  :key="`item-${i}`"
                  :value="item.id"
                  active-class="primary--text"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group> -->
          <!-- </v-list> -->
          <v-row justify="center" align="center" class="mt-2">
            <v-col cols="3">
              <v-btn
                block
                dark
                :loading="submitLoader"
                color="primary darken-2"
                type="submit"
                >αποθηκευση
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                v-if="editModification"
                block
                dark
                color="red"
                @click="deleteDialog = true"
                >διαγραφη
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-dialog width="500px" max-width="500px" v-model="deleteDialog">
        <v-card v-if="deleteDialog">
          <v-card-title> ΔΙΑΓΡΑΦΗ </v-card-title>
          <v-card-text class="d-flex flex-column align-center justify-center">
            <span class="body-1"
              >Είστε σίγουρος οτι θέλετε να διαγράψετε την τροποποίηση του
              έργου;</span
            >
            <div class="d-flex align-center justify-center mt-2">
              <v-btn
                outlined
                color="error"
                class="mr-2"
                @click="deleteModification"
                :loading="deleteLoader"
                >ΝΑΙ</v-btn
              >

              <v-btn color="primary darken-2" @click="deleteDialog = false"
                >ΟΧΙ</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addingDialog" width="1000" max-width="1000">
        <v-card>
          <v-card-title>ΠΡΟΣΘΗΚΗ</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form @submit.prevent="submit" class="ml-12">
                  <v-row justify="center">
                    <v-col cols="5">
                      <v-text-field
                        label="Τίτλος*"
                        :error-messages="titleErrors"
                        v-model="title"
                      ></v-text-field>
                      <v-text-field label="ΚΑΕ" v-model="kae"></v-text-field>

                      <v-autocomplete
                        label="Περιφερειακή ενότητα/τομέας"
                        :error-messages="unitErrors"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.units"
                        v-model="unitId"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Φάση έργου αρχή έτους"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.phases"
                        v-model="newYearJobPhaseId"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Δήμος"
                        item-value="id"
                        item-text="name"
                        :items="availableMunicipalities"
                        v-model="municipalityId"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Φάση έργου"
                        item-value="id"
                        :error-messages="jobPhaseErrors"
                        item-text="name"
                        :items="this.$store.state.projects.phases"
                        v-model="jobPhaseId"
                      >
                      </v-autocomplete>

                      <v-autocomplete
                        label="Μελέτη"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.researches"
                        v-model="jobResearchId"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Είδος έργου"
                        item-value="id"
                        :error-messages="jobTypesErrors"
                        item-text="name"
                        :items="this.$store.state.projects.types"
                        v-model="jobTypeId"
                      ></v-autocomplete>

                      <!-- <v-text-field label="Θέση" v-model="place"></v-text-field> -->

                      <v-autocomplete
                        label="Φορέας αρμοδιότητας"
                        :items="entitiesAndMunicipalities"
                        item-text="name"
                        item-value="name"
                        v-model="propertyEntity"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Φορέας υλοποίησης"
                        :items="entitiesAndMunicipalities"
                        item-text="name"
                        item-value="name"
                        v-model="implementingEntity"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="5" offset="1">
                      <v-text-field
                        label="Διευθύνουσα υπηρεσία"
                        v-model="managementService"
                      ></v-text-field>

                      <v-text-field
                        label="Προϋπολογισμός (προ δημοπράτησης)*"
                        :error-messages="budgetErrors"
                        v-model="budgetBeforeAuction"
                        :hint="budgetBeforeAuction | currency"
                        persistent-hint
                      >
                      </v-text-field>

                      <v-text-field
                        label="Προτεινόμενες Πιστώσεις*"
                        v-model="suggestedCredits"
                        :hint="suggestedCredits | currency"
                        :error-messages="suggestedCreditsErrors"
                        persistent-hint
                      ></v-text-field>
                      <!-- <v-text-field label="Ανάδοχος" v-model="contractor"></v-text-field> -->

                      <v-autocomplete
                        label="Κατηγορία έργου"
                        item-value="id"
                        :error-messages="jobCategoryErrors"
                        class="mt-4"
                        item-text="name"
                        :items="this.$store.state.projects.categories"
                        v-model="jobCategoryId"
                      ></v-autocomplete>

                      <!-- <v-text-field label="Αντισυμβαλλόμενοι" v-model="jobPartners"></v-text-field> -->
                      <v-autocomplete
                        label="Άξονας"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.axis"
                        v-model="jobAxisId"
                      ></v-autocomplete>
                      <v-autocomplete
                        label="Μέτρο"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.measures"
                        v-model="jobMeasureId"
                      ></v-autocomplete>
                      <v-autocomplete
                        label="Στόχος"
                        item-value="id"
                        item-text="name"
                        :items="this.$store.state.projects.goals"
                        v-model="jobGoalId"
                      ></v-autocomplete>

                      <v-text-field
                          label="Προτεινόμενος προϋπολογισμός (νομική δέσμευση)*"
                          v-model="suggestedBudget"
                          :hint="suggestedBudget | currency"
                          persistent-hint
                      ></v-text-field>

                      <!-- <v-text-field label="Φορέας χρηματοδότησης" v-model="fundingEntity"></v-text-field> -->
                      <v-text-field
                        label="Ένταξη στο ΠΕΕ"
                        v-model="budgetComments"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center" align="center">
                    <v-col cols="3">
                      <v-btn
                        class="mt-2"
                        block
                        dark
                        :loading="submitLoader"
                        color="primary darken-2"
                        type="submit"
                      >
                        αποθηκευση
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

import { required, decimal } from "vuelidate/lib/validators";
export default {
  async created() {
    try {
      await this.$store.dispatch("getNewPeeJobData");

      const res = await axios.get(
        `${process.env.VUE_APP_BASEURL}/api/pee/job/modification/paging/${this.$store.state.projects.projectEditing.id}`,
        {
          params: {
            page: 1,
            active: 1,
          },
        }
      );
      console.log(res.data.data[0])
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
      if (res.data.data.length > 0) {
        console.log("sto prwtooooooo")
        this.unitId = res.data.data[0].unit.id;
        this.budgetBeforeAuction = res.data.data[0].budgetBeforeAuction;
        this.credits = res.data.data[0].credits;
        this.implementingEntity = res.data.data[0].implementingEntity;

        this.jobAxisId =
          res.data.data[0].jobAxis != null ? res.data.data[0].jobAxis.id : "";
        this.jobCategoryId =
          res.data.data[0].jobCategory != null
            ? res.data.data[0].jobCategory.id
            : "";
        this.jobGoalId =
          res.data.data[0].jobGoal != null ? res.data.data[0].jobGoal.id : "";
        this.jobMeasureId =
          res.data.data[0].jobMeasure != null
            ? res.data.data[0].jobMeasure.id
            : "";
        this.jobPhaseId =
          res.data.data[0].jobPhase != null ? res.data.data[0].jobPhase.id : "";
        this.suggestedCredits = res.data.data[0].suggestedCredits;
        this.jobResearchId =
          res.data.data[0].jobResearch != null
            ? res.data.data[0].jobResearch.id
            : "";
        this.jobTypeId =
          res.data.data[0].jobType != null ? res.data.data[0].jobType.id : "";
        this.joinPee = res.data.data[0].joinPee;
        this.suggestedBudget = res.data.data[0].suggestedBudget;
        this.budgetComments = res.data.data[0].budgetComments;
        this.suggestedCredits = res.data.data[0].suggestedCredits;
        this.kae = res.data.data[0].kae;
        this.managementService = res.data.data[0].managementService;
        this.municipalityId =
          res.data.data[0].municipality != null
            ? res.data.data[0].municipality.id
            : "";
        this.newYearJobPhaseId =
          res.data.data[0].newYearJobPhase != null
            ? res.data.data[0].newYearJobPhase.id
            : "";
        this.propertyEntity = res.data.data[0].propertyEntity;
        this.title = res.data.data[0].title;
        this.id = res.data.data[0].id;

        this.editModification = true;
      } else {
        console.log("edw mesa")
        this.unitId = this.$store.state.projects.projectEditing.unit.id;
        this.budgetBeforeAuction =
          this.$store.state.projects.projectEditing.budgetBeforeAuction;
        this.credits = this.$store.state.projects.projectEditing.credits;
        this.implementingEntity =
          this.$store.state.projects.projectEditing.implementingEntity;

        this.jobAxisId =
          this.$store.state.projects.projectEditing.jobAxis != null
            ? this.$store.state.projects.projectEditing.jobAxis.id
            : "";

        this.jobCategoryId =
          this.$store.state.projects.projectEditing.jobCategory != null
            ? this.$store.state.projects.projectEditing.jobCategory.id
            : "";

        this.jobCategory =
          this.$store.state.projects.projectEditing.jobGoal != null
            ? this.$store.state.projects.projectEditing.jobGoal.id
            : "";

        this.jobMeasureId =
          this.$store.state.projects.projectEditing.jobMeasure != null
            ? this.$store.state.projects.projectEditing.jobMeasure.id
            : "";

        this.jobPhaseId =
          this.$store.state.projects.projectEditing.jobPhase != null
            ? this.$store.state.projects.projectEditing.jobPhase.id
            : "";

        this.jobResearchId =
          this.$store.state.projects.projectEditing.jobResearch != null
            ? this.$store.state.projects.projectEditing.jobResearch.id
            : "";

        this.jobTypeId =
          this.$store.state.projects.projectEditing.jobType != null
            ? this.$store.state.projects.projectEditing.jobType.id
            : "";

        this.joinPee = this.$store.state.projects.projectEditing.joinPee;
        this.kae = this.$store.state.projects.projectEditing.kae;
        this.managementService =
          this.$store.state.projects.projectEditing.managementService;

        this.municipalityId =
          this.$store.state.projects.projectEditing.municipality != null
            ? this.$store.state.projects.projectEditing.municipality.id
            : "";

        this.newYearJobPhaseId =
          this.$store.state.projects.projectEditing.newYearJobPhase != null
            ? this.$store.state.projects.projectEditing.newYearJobPhase.id
            : "";

        this.propertyEntity =
          this.$store.state.projects.projectEditing.propertyEntity;
        this.title = this.$store.state.projects.projectEditing.title;
        this.id = this.$store.state.projects.projectEditing.id;
      }
      this.pageLoader = false;
      console.log(this.$store.state.projects.projectEditing);
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      deleteDialog: false,

      addingDialog: false,
      submitLoader: false,
      deleteLoader: false,
      editModification: false,
      pageLoader: true,
      id: null,
      budgetBeforeAuction: 0,
      credits: 0,
      comments: "",
      implementingEntity: "",
      kae: "",
      managementService: "",
      propertyEntity: "",
      title: "",
      unitId: 0,
      jobCategoryId: 0,
      jobPhaseId: 0,
      jobResearchId: 0,
      jobTypeId: 0,
      jobAxisId: 0,
      jobMeasureId: 0,
      jobGoalId: 0,
      newYearJobPhaseId: 0,
      municipalityId: 0,
      joinPee: "",
      peeJobAttachments: [],
      suggestedBudget: 0,
      budgetComments: "",
      suggestedCredits: 0
    };
  },
  methods: {
    submit() {
      console.log("submit")
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitLoader = true;
        let jobRequestsIds = [];
        for (let item in this.selected) {
          jobRequestsIds.push(this.selected[item].id);
        }

        const credentials = {
          budgetBeforeAuction: parseFloat(this.budgetBeforeAuction),
          credits: parseFloat(this.credits),
          implementingEntity: this.implementingEntity,
          // jobAxisId: parseInt(this.jobAxisId),
          // jobCategoryId: parseInt(this.jobCategoryId),
          // jobGoalId: parseInt(this.jobGoalId),
          // jobMeasureId: parseInt(this.jobMeasureId),
          // jobPhaseId: parseInt(this.jobPhaseId),
          // jobResearchId: parseInt(this.jobResearchId),
          jobTypeId: parseInt(this.jobTypeId),
          joinPee: this.joinPee,
          kae: this.kae,
          managementService: this.managementService,
          municipalityId: parseInt(this.municipalityId),
          newYearJobPhaseId: parseInt(this.newYearJobPhaseId),
          propertyEntity: this.propertyEntity,
          title: this.title,
          unitId: this.unitId,
          peeJobId: this.$store.state.projects.projectEditing.id,
          suggestedBudget: this.suggestedBudget,
          suggestedCredits: this.suggestedCredits
        };

        if (this.jobAxisId != "")
          credentials.jobAxisId = parseInt(this.jobAxisId);

        if (this.jobCategoryId != "")
          credentials.jobCategoryId = parseInt(this.jobCategoryId);

        if (this.jobGoalId != "")
          credentials.jobGoalId = parseInt(this.jobGoalId);

        if (this.jobMeasureId != "")
          credentials.jobMeasureId = parseInt(this.jobMeasureId);

        if (this.jobPhaseId != "")
          credentials.jobPhaseId = parseInt(this.jobPhaseId);

        if (this.jobResearchId != "")
          credentials.jobResearchId = parseInt(this.jobResearchId);

        if (this.jobTypeId != "")
          credentials.jobTypeId = parseInt(this.jobTypeId);

        if (!this.editModification) {
          console.log("patima")
          axios
            .post(
              `${process.env.VUE_APP_BASEURL}/api/pee/job/modification/add`,
              credentials
            )
            .then((res) => {
              if (res.data.status == 0) {
                this.submitLoader = false;

                return this.$store.commit("toggleDialog", {
                  open: true,
                  text: "Επιτυχία",
                });
              } else {
                this.submitLoader = false;

                return this.$store.commit("toggleSnackbar", {
                  open: true,
                  text: "Αποτυχία",
                });
              }
            })
            .then(() => {
              this.$router.go();
            })
            .catch(() => {
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });

              this.$router.go();
            });
        } else {
          axios
            .put(
              `${process.env.VUE_APP_BASEURL}/api/pee/job/modification/edit/${this.id}`,
              credentials
            )
            .then((res) => {
              if (res.data.status == 0) {
                this.submitLoader = false;

                return this.$store.commit("toggleDialog", {
                  open: true,
                  text: "Επιτυχία",
                });
              } else {
                this.submitLoader = false;

                return this.$store.commit("toggleSnackbar", {
                  open: true,
                  text: "Αποτυχία",
                });
              }
            })
            .then(() => {
              this.$router.go();
            })
            .catch(() => {
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });

              this.$router.go();
            });
        }
      }
    },

    async deleteModification() {
      try {
        this.deleteLoader = true;
        const res = await axios.delete(
          `${process.env.VUE_APP_BASEURL}/api/pee/job/modification/delete/${this.id}`
        );

        this.deleteLoader = false;
        if (res.data.status === -1) {
          this.$store.dispatch("errorHandler", res.data.message);
          return;
        }
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });
        this.deleteDialog = false;
        this.$router.go();
      } catch (e) {
        this.deleteLoader = false;
        this.$store.commit("toggleSnackbar", { open: true, text: "Αποτυχία" });

        this.deleteDialog = false;

        this.$router.go();

        console.log(e);
      }
    },
  },

  validations: {
    title: { required },
    unitId: { required },
    jobTypeId: { required },
    budgetBeforeAuction: { required, decimal },
    //credits: { decimal, required },
    suggestedCredits : {decimal, required},
    jobCategoryId: { required },
    jobPhaseId: { required },
  },

  computed: {
    availableMunicipalities() {
      return this.$store.state.requests.municipalities.filter(
        (municipality) => {
          if (municipality.unit != null)
            if (municipality.unit.id == this.unitId) return municipality;
        }
      );
    },

    entitiesAndMunicipalities() {
      return this.$store.state.requests.municipalities.concat(
        this.$store.state.requests.entities
      );
    },
    project() {
      return this.$store.state.projects.projectEditing;
    },

    //errors
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.unitId.$dirty) return errors;
      if (!this.$v.unitId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobTypesErrors() {
      const errors = [];
      if (!this.$v.jobTypeId.$dirty) return errors;
      if (!this.$v.jobTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    budgetErrors() {
      const errors = [];
      if (!this.$v.budgetBeforeAuction.$dirty) return errors;
      if (!this.$v.budgetBeforeAuction.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.budgetBeforeAuction.decimal)
        errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },
    // creditsErrors() {
    //   const errors = [];
    //   if (!this.$v.credits.$dirty) return errors;
    //   if (!this.$v.credits.required) errors.push("Υποχρεωτικό πεδίο");
    //   if (!this.$v.credits.decimal) errors.push("Πρέπει να είναι αριθμός");
    //   return errors;
    // },
    suggestedCreditsErrors() {
      const errors = [];
      if (!this.$v.suggestedCredits.$dirty) return errors;
      if (!this.$v.suggestedCredits.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.suggestedCredits.decimal) errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },
    jobCategoryErrors() {
      const errors = [];
      if (!this.$v.jobCategoryId.$dirty) return errors;
      if (!this.$v.jobCategoryId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobPhaseErrors() {
      const errors = [];
      if (!this.$v.jobPhaseId.$dirty) return errors;
      if (!this.$v.jobPhaseId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
};
</script>
