import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Requests from '@/views/requests/Requests.vue'
import NewRequest from '@/views/requests/NewRequest.vue'
import EditRequest from '@/views/requests/EditRequest.vue'
import Reports from '@/views/Reports.vue'
import Settings from '@/views/Settings.vue'
import ManageLists from '@/views/ManageLists.vue'
import Test from '@/views/Test.vue'
import Searches from '@/views/Searches.vue'
import SearchesMember from '@/views/SearchesMember.vue'
import Login from '@/views/auth/Login.vue'

import PeeRoutes from './peeRoutes'
import PdeRoutes from './pdeRoutes'

import store from '../store/index'

export const ifNotAuth = (to, from, next) => {
  if (store.getters.isAuth) {
    next()
  } else {
    next('/login')
  }
};

export const ifAuth = (to, from, next) => {
  if (store.getters.isAuth) {
    next('/')
  } else {
    next()
  }
};

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name != "NavigationDuplicated") {
      throw err;
    }
  });
};

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: ifNotAuth,
      meta: { title: 'Περιφέρεια' }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: ifAuth,
      meta: { title: 'Είσοδος' }
    },
    {
      path: '/requests',
      name: 'requests',
      component: Requests,
      beforeEnter: ifNotAuth,
      meta: { title: 'Αιτήματα' }
    },
    {
      path: '/requests/newrequest',
      name: 'newrequest',
      component: NewRequest,
      beforeEnter: ifNotAuth,
      meta: { title: 'Νέο Αίτημα' }
    },
    {
      path: '/requests/edit-request',
      name: 'editRequest',
      component: EditRequest,
      beforeEnter: ifNotAuth,
      meta: { title: 'Επεξεργασία Αιτήματος' }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      beforeEnter: ifNotAuth,
      meta: { title: 'Αναφορές' }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      beforeEnter: ifNotAuth,
      meta: { title: 'Ρυθμίσεις' }
    },
    {
      path: '/settings/manage-lists',
      name: 'manageLists',
      component: ManageLists,
      beforeEnter: ifNotAuth,
      meta: { title: 'Λίστες' }
    },
    {
      path: '/reports/test',
      name: 'test',
      component: Test,
      beforeEnter: ifNotAuth,
      meta: { title: 'ΤΕΣΤ' }
    },
    {
      path: '/searches',
      name: 'searches',
      component: Searches,
      beforeEnter: ifNotAuth,
      meta: { title: 'Αναζητήσεις' }
    },
    {
      path: '/searches/member',
      name: 'searchesMember',
      component: SearchesMember,
      beforeEnter: ifNotAuth,
      meta: { title: 'Αναζήτηση Μέλους Επιτροπής' }
    },
    ...PeeRoutes,
    ...PdeRoutes,
  ]
})
