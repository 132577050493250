import Projects from '@/views/pee/Projects.vue'
import NewProject from '@/views/pee/NewProject.vue'
import EditProject from '@/views/pee/EditProject.vue'
import ViewProject from '@/views/pee/ViewProject.vue'
import EditErgolavikhSymvashErgou from '@/views/pee/EditErgolavikhSymvashErgou'
import EditProjectProgrammatikhSymvashErgou from '@/views/pee/EditProjectProgrammatikhSymvashErgou'
import EditProjectTropopoihshStoixeiwnErgou from '@/views/pee/EditProjectTropopoihshStoixeiwnErgou'
import EditProjectFinancialDetails from '@/views/pee/EditProjectFinancialDetails'
import EditProjectIstorikoTropopoihsewn from '@/views/pee/EditProjectIstorikoTropopoihsewn'
import EnhmerwshOikonomikwnStoixeiwn from '@/views/pee/EnhmerwshOikonomikwnStoixeiwn.vue'
import NewPee from '@/views/pee/NewPee'

import { ifNotAuth } from './index'

export default [
    {
        path: '/projects',
        name: 'projects',
        component: Projects,
        beforeEnter: ifNotAuth,
        meta: { title: 'Έργα ΠΕΕ' }
    },
    {
        path: '/upload-financial-details',
        name: 'uploadFinancialDetails',
        component: EnhmerwshOikonomikwnStoixeiwn,
        beforeEnter: ifNotAuth,
        meta: { title: 'Ενημέρωση Οικονομικών Στοιχείων' }
    },
    {
        path: '/projects/:project_id/edit-project',
        component: ViewProject,
        beforeEnter: ifNotAuth,
        children: [
            {
                path: "",
                component: EditProject,
                meta: { title: 'Προβολή Έργου' },
            },

            {
                path: 'ergolavikh-symbash-ergou',
                component: EditErgolavikhSymvashErgou,
                meta: { title: 'Εργολαβική Σύμβαση' }
            },

            {
                path: 'programmatikh-symvash-ergou',
                component: EditProjectProgrammatikhSymvashErgou,
                meta: { title: 'Προγραμματική Σύμβαση' }
            },

            {
                path: 'tropopoihsh-stoixeiwn-ergou',
                component: EditProjectTropopoihshStoixeiwnErgou,
                meta: { title: 'Τροποποιήσεις Έργου' }
            },

            {
                path: 'financial-details',
                component: EditProjectFinancialDetails,
                meta: { title: 'Οικονομικά Στοιχεία Έργου' }
            },

            {
                path: '/projects/:project_id/edit-project/istoriko-tropopoihsewn',
                component: EditProjectIstorikoTropopoihsewn,
                meta: { title: 'Ιστορικό Τροποποιήσεων' }
            },
        ]
    },
    {
        path: '/projects/new-project',
        name: 'newProject',
        component: NewProject,
        beforeEnter: ifNotAuth,
        meta: { title: 'Νέο Έργο' }
    },
    {
        path: '/new-pee',
        name: 'newPee',
        component: NewPee,
        beforeEnter: ifNotAuth,
        meta: { title: 'Νέο ΠΕΕ' }
    },
]