<template>
  <div>
    <v-breadcrumbs
      :items="[
        {
          text: 'AITHMATA',
          disabled: false,
          link: true,
          exact: true,
          to: '/requests',
        },
        { text: 'ΕΠΕΞΕΡΓΑΣΙΑ ΑΙΤΗΜΑΤΟΣ' },
      ]"
      divider=">"
    ></v-breadcrumbs>
    <v-form @submit.prevent="submit" ref="form" lazy-validation>
      <v-row class="text-center">
        <v-col>
          <h1 class="headline">Επεξεργασία Αιτήματος</h1>
          <v-divider class="my-12"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="5">
          <v-autocomplete
            label="Περιφερειακή ενότητα/Τομέας*"
            :items="this.$store.state.requests.units"
            item-value="id"
            item-text="name"
            @change="municipalityId = null"
            :rules="validationRule"
            v-model="unitId"
          ></v-autocomplete>

          <v-autocomplete
            label="Είδος έργου*"
            :items="this.$store.state.requests.jobTypes"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="jobTypeId"
          ></v-autocomplete>

          <!-- <v-text-field label="Θέση έργου" v-model="place" :value="request.place"></v-text-field> -->

          <v-autocomplete
            label="­Δήμος*"
            :items="availableMunicipalities"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="municipalityId"
          ></v-autocomplete>

          <v-autocomplete
            label="Φορέας/Δήμος"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            clearable
            v-model="entityMunicipality"
          ></v-autocomplete>

          <v-row no-gutters>
            <v-text-field
              label="Αριθμός Πρωτόκολου"
              v-model="protocolNumber"
              :value="request.protocolNumber"
              class="mr-3"
            ></v-text-field>

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="greekFormatDate"
                  label="Ημερομηνία"
                  append-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="protocolNumberDate"
                locale="el-GR"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-row>

          <v-col cols="12">
            <v-card>
              <v-card-title>
                <h1 class="title">Αρχεία</h1>
              </v-card-title>
              <v-card-text>
                <v-file-input chips multiple label="Αρχεία" v-model="files">
                  <template v-slot:selection="{ index, text }">
                    <v-chip close @click:close="deleteChip(index, text)">{{
                      text
                    }}</v-chip>
                  </template>
                </v-file-input>

                <v-btn :disabled="files.length < 1" @click="upload"
                  >Αρχειοθετηση</v-btn
                >

                <v-data-table
                  hide-default-footer
                  :headers="fileHeaders"
                  :items="requestAttachments"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="deleteFile(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>

        <v-col cols="5" offset="1">
          <v-text-field
            label="Τίτλος αιτήματος*"
            :rules="validationRule"
            v-model="title"
            :value="request.title"
          ></v-text-field>

          <v-autocomplete
            label="Έχει ενταχθεί;"
            :items="this.$store.state.requests.status"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="jobRequestStatusId"
          ></v-autocomplete>

          <v-text-field
            label="Προϋπολογισμός"
            v-model="budget"
            :hint="budget | currency"
            persistent-hint
            prefix="€"
            type="decimal"
          ></v-text-field>

          <v-autocomplete
            label="Κατάσταση μελέτης"
            class="mt-4"
            :items="this.$store.state.requests.research"
            item-value="id"
            item-text="name"
            v-model="jobResearchId"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="11">
          <v-textarea
            outlined
            label="Παρατηρήσεις"
            :value="request.comments"
            v-model="comments"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row justify="center" class="mt-10"></v-row>
      <v-row justify="center" class="mt-2">
        <v-btn
          class="mr-2"
          :loading="$store.state.loading"
          width="20%"
          dark
          tile
          color="primary darken-2"
          type="submit"
          >Αποθηκευση</v-btn
        >

        <v-btn
          width="20%"
          dark
          tile
          color="primary darken-2"
          @click="$router.go(-1)"
          >ακυρο</v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      menu: false,
      validationRule: [(v) => !!v || "Υποχρεωτικό πεδίο"],
      id: this.$store.state.requests.requestEdit.id,
      unitId: this.$store.state.requests.requestEdit.unit.id,
      title: this.$store.state.requests.requestEdit.title,
      jobTypeId: this.$store.state.requests.requestEdit.jobType.id,
      // place: this.$store.state.requests.requestEdit.place,
      municipalityId: this.$store.state.requests.requestEdit.municipality.id,
      entityMunicipality:
        this.$store.state.requests.requestEdit.entityMunicipality,
      protocolNumber: this.$store.state.requests.requestEdit.protocolNumber,
      protocolNumberDate:
        this.$store.state.requests.requestEdit.protocolNumberDate,
      jobRequestStatusId:
        this.$store.state.requests.requestEdit.jobRequestStatus.id,
      budget: this.$store.state.requests.requestEdit.budget,
      jobResearchId: this.$store.state.requests.requestEdit.jobResearch
        ? this.$store.state.requests.requestEdit.jobResearch.id
        : "",
      comments: this.$store.state.requests.requestEdit.comments,
      requestAttachments:
        this.$store.state.requests.requestEdit.requestAttachments || [],
      fileHeaders: [
        {
          text: "Όνομα",
          value: "filename",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      files: [],
    };
  },
  created() {
    this.$store
      .dispatch("getNewRequestData")
      .then(() => {})
      .catch(() => {});
  },
  watch: {
    menu(val) {
      // val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const credentials = {
          unitId: this.unitId,
          title: this.title,
          jobTypeId: this.jobTypeId,
          // place: this.place,
          municipalityId: this.municipalityId,
          entityMunicipality: this.entityMunicipality,
          protocolNumber: this.protocolNumber,
          protocolNumberDate: this.protocolNumberDate,
          jobRequestStatusId: this.jobRequestStatusId,
          budget: this.budget,
          jobResearchId: this.jobResearchId,
          comments: this.comments,
          requestAttachmentsIds: this.requestAttachmentsIds,
        };
        this.$store
          .dispatch("editRequest", { id: this.id, credentials: credentials })
          .then((res) => {
            if (res.data.status == 0) {
              this.$router.go(-1);
              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });
            } else {
              this.$store.dispatch("errorHandler", res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });
          });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },

    deleteChip(index) {
      // Prompt here with text if required
      this.files.splice(index, 1);
    },

    upload() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          console.log(res);
          res.data.data.forEach((item) => {
            this.requestAttachments.push(item);
          });
          this.files = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    deleteFile(id) {
      // this.$store.dispatch('deleteFile', id)
      // .then(() => {
      //   this.requestAttachments.splice(this.requestAttachments.findIndex(item => item.id == id),1)
      // })
      // .catch(err => {
      //   console.log(err)
      // })

      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobRequestId: this.$store.state.requests.requestEdit.id,
            },
          }
        )
        .then(() => {
          this.requestAttachments.splice(
            this.requestAttachments.findIndex((item) => item.id == id),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(err);
        });
    },

    download(item) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/download/${item.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    entitiesAndMunicipalities() {
      return this.$store.state.requests.municipalities.concat(
        this.$store.state.requests.entities
      );
    },
    request() {
      return this.$store.state.requests.requestEdit;
    },
    availableMunicipalities() {
      return this.$store.state.requests.municipalities.filter(
        (municipality) => {
          if (municipality.unit != null)
            if (municipality.unit.id == this.unitId) return municipality;
        }
      );
    },
    greekFormatDate() {
      if (this.protocolNumberDate == null) {
        return "";
      } else {
        const d = new Date(this.protocolNumberDate);
        return moment(moment.utc(d).toDate()).local().format("DD/MM/YYYY");
      }
    },

    requestAttachmentsIds() {
      if (this.requestAttachments.length == 0) return null;
      return this.requestAttachments.map((item) => item.id);
    },
  },
};
</script>
