var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("ΑΠΟΤΕΛΕΣΜΑΤΑ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results},scopedSlots:_vm._u([{key:"item.confirmedBudget",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.thousands_separators(item.confirmedBudget)))]}},{key:"item.peeJobHistoricalModificationReasons",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.mapped(item.peeJobHistoricalModificationReasons)))]}},{key:"item.suggestedBudget",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.thousands_separators(item.suggestedBudget)))]}},{key:"item.confirmedCredits",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.thousands_separators(item.confirmedCredits)))]}},{key:"item.suggestedCredits",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.thousands_separators(item.suggestedCredits)))]}}],null,true)}),_c('div',{staticClass:"px-12 py-5",staticStyle:{"display":"flex","width":"100%","align-items":"center"}},[(_vm.page > 1)?_c('v-icon',{on:{"click":function($event){_vm.page -= 1;
                _vm.updatePagination({ page: _vm.page });}}},[_vm._v("mdi-arrow-left")]):_vm._e(),_c('v-spacer'),_c('p',{staticStyle:{"justify-self":"center"}},[_vm._v(" Σελίδα "+_vm._s(_vm.page)+" από "+_vm._s(_vm.pages)+" ")]),_c('v-spacer'),(_vm.page < _vm.pages)?_c('v-icon',{on:{"click":function($event){_vm.page += 1;
                _vm.updatePagination({ page: _vm.page });}}},[_vm._v("mdi-arrow-right")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }