<template>
  <div>
    <v-breadcrumbs
      :items="[{ text: 'ΠΕΕ' }, { text: 'ΕΡΓΑ' }]"
      divider=">"
    ></v-breadcrumbs>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-card-title class="title" style="width: 100%">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
              "
              class="my-10"
            >
              <div class="d-flex flex-column">
                <div class="d-flex" >
                  <v-btn
                    class="mr-2 flex-grow-1"
                    dark
                    tile
                    color="primary darken-2"
                    @click="$router.push('/projects/new-project')"
                    >Νεο εργο</v-btn
                  >

                  <v-btn dark tile color="primary darken-2"
                         class="flex-grow-1"
                         @click="$store.dispatch('exportDevices')"
                  >εξαγωγη λιστας τροποποιησεων
                  </v-btn>
                </div>

                <div class="d-flex mt-4">
                  <v-btn
                      dark
                      tile
                      class="mr-2 flex-grow-1"
                      color="primary darken-2"
                      @click="$store.dispatch('exportFinancialExcel')"
                  >Εξαγωγη Οικονομικων Στοιχειων</v-btn>
                </div>

                <div class="d-flex mt-4 align-center justify-center">
                <v-file-input
                    append-icon="mdi-upload"
                    @click:append = "importExcel"
                    counter
                    show-size
                    truncate-length="26000"
                    accept="xlsx/*"
                    label="Εισαγωγή Λίστας Τροποποιήσεων"
                    ref="peeXlsx"
                    v-model = "peeJobImport"
                ></v-file-input>


                </div>
                <div class="d-flex mt-4 align-center justify-center">
<!--@click="$store.dispatch('importDevices')"-->
                <v-file-input
                    append-icon="mdi-upload"
                    @click:append = "importFinancialExcel"
                    counter
                    show-size
                    truncate-length="26000"
                    accept="xlsx/*"
                    label="Εισαγωγή Οικονομικών Στοιχείων"
                    ref="financialXlsx"
                    v-model = "financialDetailsImport"
                ></v-file-input>

              </div>
<!--@click="$store.dispatch('importFinancialExcel')"-->


              </div>


              <v-spacer></v-spacer>
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <v-text-field
                    class="mt-3 ml-1 flex-grow-0"
                    label="Αναζήτηση"
                    v-model="query"
                    style="width: 400px"
                    append-icon="mdi-magnify"
                  ></v-text-field>

                  <div class="d-flex flex-column" v-if="customSearch">
                    <div class="d-flex">
                      <v-autocomplete
                        clearable
                        v-model="jobType"
                        style="width: 200px; margin-left: 10px"
                        :items="$store.state.projects.types"
                        item-value="id"
                        item-text="name"
                        label="Είδος έργου"
                        multiple
                      ></v-autocomplete>
                      <v-autocomplete
                        clearable
                        v-model="jobResearch"
                        style="width: 200px; margin-left: 10px"
                        :items="$store.state.projects.researches"
                        item-value="id"
                        item-text="name"
                        label="Μελέτη"
                        multiple
                      ></v-autocomplete>
                    </div>
                    <div class="d-flex">
                      <v-autocomplete
                        clearable
                        v-model="jobPhase"
                        style="width: 200px; margin-left: 10px"
                        :items="$store.state.projects.phases"
                        item-value="id"
                        item-text="name"
                        label="Φάση έργου"
                        multiple
                      ></v-autocomplete>
                      <v-autocomplete
                          clearable
                          v-model="year"
                          style="width: 200px; margin-left: 10px"
                          :items="years"
                          label="'Ετος 'Ενταξης"
                          multiple
                      ></v-autocomplete>
                    </div>
                    <div class="d-flex">
                      <v-autocomplete
                        clearable
                        v-model="jobCategory"
                        style="width: 200px; margin-left: 10px"
                        :items="$store.state.projects.categories"
                        item-value="id"
                        item-text="name"
                        label="Κατηγορία έργου"
                        multiple
                      ></v-autocomplete>
                      <v-autocomplete
                          clearable
                          v-model="unit"
                          style="width: 200px; margin-left: 10px"
                          :items="$store.state.projects.units"
                          item-value="id"
                          item-text="name"
                          label="Περιφερειακή ενότητα"
                          multiple
                      ></v-autocomplete>
                    </div>
                    <div class="d-flex">
                      <v-text-field
                          clearable
                          class="flex-grow-0"
                          label="ΚΑΕ"
                          v-model="kae"
                          style="width: 200px; margin-left: 10px"
                          append-icon="mdi-magnify"
                      ></v-text-field>
                      <v-text-field
                          clearable
                          class="flex-grow-0"
                          label="Τίτλος"
                          v-model="title"
                          style="width: 200px; margin-left: 10px"
                          append-icon="mdi-magnify"
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <v-btn icon class="mx-2" @click="customSearch = !customSearch">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn
                  text
                  outlined
                  @click="
                    searched = true;
                    updatePagination({ page: 1 });
                  "
                  >αναζητηση</v-btn
                >
                <v-btn
                  v-if="searched"
                  class="ml-5"
                  text
                  outlined
                  @click="onCancel"
                  >ακυρωση</v-btn>

              </div>
            </div>
          </v-card-title>

          <div
            style="display: flex; justify-content: center; width: 100%"
            v-if="$store.state.loading"
          >
            <v-progress-circular
              size="100"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

          <v-simple-table style="white-space: nowrap" dense class="mt-12">
            <thead>
              <tr>
                <th></th>
                <th>Περιφερειακή ενότητα/τομέας</th>
                <th>Φάση έργου αρχή έτους</th>
                <th>Φάση έργου</th>
                <th>Τίτλος</th>
                <th>Μελέτη</th>
                <th>Είδος έργου</th>
                <th>Δήμος</th>
                <th>ΚΑΕ</th>
                <th>Φορέας αρμοδιότητας</th>
                <th>Φορέας υλοποίησης</th>
                <th>Διευθύνουσα υπηρεσία</th>

                <th>Προϋπολογισμός (προ δημοπράτησης)</th>
                <!-- <th>Παρατηρήσεις</th> -->
                <!-- <th>Ανάδοχος</th>
          
                <th>Αντισυμβαλλόμενοι</th>

        
                <th>Θέση</th>
                
               
             
                <th>Κατηγορία έργου</th>
              
                <th>Αιτήματα που συσχετίζονται με το έργο</th>
               
              
                <th>Άξονας</th>
                <th>Μέτρο</th>
                <th>Στόχος</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in projects" :key="item.id">
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="edit(item)">mdi-eye</v-icon>
                    </template>
                    <span>Προβολή</span>
                  </v-tooltip>
                </td>
                <td>{{ item.unit ? item.unit.name : "" }}</td>
                <td>
                  {{ item.newYearJobPhase ? item.newYearJobPhase.name : "" }}
                </td>
                <td>{{ item.jobPhase ? item.jobPhase.name : "" }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>
                <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                <td>{{ item.place ? item.place : "" }}</td>
                <td>{{ item.kae }}</td>
                <td>{{ item.propertyEntity }}</td>
                <td>{{ item.implementingEntity }}</td>
                <td>{{ item.managementService }}</td>

                <td>{{ thousands_separators(item.budgetBeforeAuction) }}</td>
                <!-- <td>{{ item.comments }}</td> -->
                <!-- <td>{{ item.contractor }}</td>
               
                <td>{{ item.jobPartners }}</td>

            
                <td>{{ item.place }}</td>

              
              
              
                <td>{{ item.jobCategory ? item.jobCategory.name : '' }}</td>
             
                <td>{{ item.jobRequests ? extract(item.jobRequests) : '' }}</td>

          
           
                <td>{{ item.jobAxis ? item.jobAxis.name : '' }}</td>
                <td>{{ item.jobMeasure ? item.jobMeasure.name : '' }}</td>
                <td>{{ item.jobGoal ? item.jobGoal.name : '' }}</td> -->

                <!-- <td>{{ item.requestAttachments }}</td> -->
              </tr>
            </tbody>
          </v-simple-table>

          <div
            style="display: flex; width: 100%; align-items: center"
            class="px-12 py-5"
          >
            <v-tooltip top v-if="page > 1">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="
                    page -= 1;
                    updatePagination({ page: page });
                  "
                  >mdi-arrow-left</v-icon
                >
              </template>
              <span>Προηγούμενο</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <p style="justify-self: center">
              Σελίδα {{ page }} από {{ pages }}
            </p>
            <v-spacer></v-spacer>

            <v-tooltip top v-if="page < pages">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="page < pages"
                  @click="
                    page += 1;
                    updatePagination({ page: page });
                  "
                  >mdi-arrow-right</v-icon
                >
              </template>
              <span>Επόμενο</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      itemToEdit: "",
      dialog: null,
      page: 1,
      totalProjects: 0,
      query: "",
      searched: null,
      projects: [],
      customSearch: false,
      years: [],

      jobType: [],
      jobResearch: [],
      jobPhase: [],
      newYearJobPhase: [],
      jobCategory: [],
      jobAxis: [],
      jobMeasure: [],
      jobGoal: [],
      unit: [],
      year: [],
      kae: null,
      title: null,

      financialDetailsImport: [],
      peeJobImport: [],

      requiredRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
    };
  },
  created() {
    this.$store.state.loading = true;
    axios
      .get(`${process.env.VUE_APP_BASEURL}/api/pee/job/list/counter`)
      .then((res) => {
        this.totalProjects = res.data.data;
        return axios.get(
          `${process.env.VUE_APP_BASEURL}/api/pee/job/list/paging?page=1`
        );
      })
      .then((res) => {
        this.projects = res.data.data;
        this.$store.state.loading = false;

        return this.$store.dispatch("getNewPeeJobData");
      })
      .then(() => {
        this.getYears();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    pages() {
      return Math.ceil(this.totalProjects / 20);
    },
  },
  methods: {
    onCancel() {
      this.searched = false;
      this.updatePagination({ page: 1 });
      this.query = null;
      this.jobType = null;
      this.jobResearch = null;
      this.jobPhase = null;
      this.newYearJobPhase = null;
      this.jobCategory = null;
      this.jobAxis = null;
      this.jobMeasure = null;
      this.jobGoal = null;
      this.unit = null;
      this.year = null;
      this.kae = null;
      this.title = null;
    },

    importFinancialExcel(){
      const formData = new FormData();
      formData.append('financialExportFile', this.financialDetailsImport);

      console.log(formData);

      axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/excel/import/financial`, formData)
          .then(response => {
            // Handle response from backend
            console.log('Upload successful:', response.data);
          })
          .catch(error => {
            // Handle error
            console.error('Error uploading file:', error);
          });
      // res = await axios.get(`${process.env.VUE_APP_BASEURL}/api/pee/excel/import/financial`)
    },

    importExcel(){
      const formData = new FormData();
      formData.append('peeJobExportFile', this.peeJobImport);

      axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/excel/import`, formData)
          .then(response => {
            // Handle response from backend
            console.log('Upload successful:', response.data);
          })
          .catch(error => {
            // Handle error
            console.error('Error uploading file:', error);
          });
    },

    thousands_separators(num) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    edit(itemSelected) {
      this.$router.push(`/projects/${itemSelected.id}/edit-project`);

      // this.$store
      //   .dispatch("openEditProjectDialog")
      //   .then(() => {
      //     //   alert('dispatched')
      //   })
      //   .catch(() => {
      //     alert("error");
      //   });
    },
    updatePagination(pagination) {
      // this.page = pagination.page;
      // axios
      //   .get(
      //     `${process.env.VUE_APP_BASEURL}/api/pee/job/list/paging?page=${this.page}`
      //   )
      //   .then(res => {
      //     this.projects = res.data.data;
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      // this.page = pagination.page;
      this.page = pagination.page;
      // console.log("page to search", this.page);
      // console.log("query to serarch", this.query);

      if (!this.searched) {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/api/pee/job/list/counter`)
          .then((res) => {
            this.totalProjects = res.data.data;
            return axios.get(
              `${process.env.VUE_APP_BASEURL}/api/pee/job/list/paging?page=${this.page}`
            );
          })
          .then((res) => {
            this.projects = res.data.data;
            // console.log(this.projects);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$store
          .dispatch("searchProject", {
            query: this.query,
            jobType: this.jobType,
            jobResearch: this.jobResearch,
            jobPhase: this.jobPhase,
            newYearJobPhase: this.newYearJobPhase,
            jobCategory: this.jobCategory,
            jobAxis: this.jobAxis,
            jobMeasure: this.jobMeasure,
            jobGoal: this.jobGoal,
            unit: this.unit,
            year: this.year,
            page: this.page,
            kae: this.kae,
            title: this.title,
          })
          .then((res) => {
            console.log(res);
            this.projects = res.data.data.documents;
            this.totalProjects = res.data.data.resultsCounter;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    search() {
      this.$store
        .dispatch("searchProject", { query: this.query, page: this.page })
        .then((res) => {
          console.log(res);
          this.projects = res.data.data.documents;
          this.totalProjects = res.data.data.resultsCounter;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    extract(jobRequests) {
      let stringRequests = "";
      for (let i in jobRequests) {
        stringRequests = stringRequests + jobRequests[i].id + ", ";
      }
      return stringRequests;
    },

    getYears() {
      // console.log(moment().year());

      for (let i = moment().year(); i >= moment().year() - 10; i--) {
        this.years.push(i);
      }
    },
  },
};
</script>

<style scoped>
.vl {
  border-left: 2px solid grey;
  height: 100%;
}

.title {
  width: 40%;
}
</style>
