import errors from '../errors.json';
export default {
  state: {
    errors: null,
  },

  mutations: {
    getErrors(state, errors) {
      state.errors = errors;
    },
  },

  actions: {
    getErrors({ commit }) {
      commit('getErrors', errors);
    },

    errorHandler({ commit, state }, error) {
      console.log('errorHandler', error);
      if (error in state.errors)
        commit('toggleSnackbar', {
          open: true,
          text: `${state.errors[error.toString()]}`,
        });
      else {
        commit('toggleSnackbar', {
          open: true,
          text: `Η ενέργεια απέτυχε`,
        });
      }
    },
  },
};
