<template>
  <div>
    <v-breadcrumbs
      :items="[{ text: 'ΠΕΕ' }, { text: 'ΕΝΗΜΕΡΩΣΗ ΟΙΚΟΝΟΜΙΚΩΝ ΣΤΟΙΧΕΙΩΝ' }]"
      divider=">"
    ></v-breadcrumbs>
    <v-card>
      <v-card-title> Οδηγίες γραμμογράφησης αρχείου: </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column ml-10 mt-4">
          <div class="d-flex align-start mb-2">
            <v-icon size="8" color="black" class="mr-2 pt-2">mdi-circle</v-icon>
            <span class="body-1 font-weight-regular"
              >Το αρχείο να έχει format .xls ή .xlsx</span
            >
          </div>

          <div class="d-flex align-start mb-2" :style="'width:80%'">
            <v-icon size="8" color="black" class="mr-2 pt-2">mdi-circle</v-icon>
            <span class="body-1 font-weight-regular"
              >Το αρχείου πρέπει να περιέχει τις εξής στήλες: "Κωδικός
              Λογαριασμού", "ΚΑΕ Έργου", "Περιγραφή", "Εγκεκριμένος",
              "Αναμορφώσεις Σύνολο", "Διαμορφωμένος Σύνολο", "Δεσμευθέντα
              Σύνολο", "Τιμολογηθέντα Σύνολο", "Ενταλθέντα Σύνολο", "Πληρωθέντα
              Σύνολο", "ΔΙΠΛΟΙ ΚΑΕ"</span
            >
          </div>

          <div class="d-flex align-start" :style="'width:80%'">
            <v-icon size="8" color="black" class="mr-2 pt-2">mdi-circle</v-icon>
            <span class="body-1 font-weight-regular"
              >Η πρώτη γραμμή του αρχείου αγνοείται απο τη διαδικασία, καθώς
              θεωρείται οτι θα περιλαμβάνει τα ονόματα των στηλών</span
            >
          </div>
        </div>
        <v-row class="ml-6">
          <v-col cols="6">
            <v-form
              ref="peeJobFinancialDetailsForm"
              class="d-flex align-center justify center mt-4"
            >
              <v-file-input
                v-model="peeJobFinancialDetails"
                label=" Ενημέρωση οικονομικών στοιχείων"
                :rules="requiredRules"
              >
              </v-file-input>

              <v-btn
                dark
                icon
                @click="uploadPeeJobFinancialDetails"
                :loading="peeJobFinancialDetailsUploadLoader"
                color="primary darken-2"
              >
                <v-icon large color="primary darken-2"
                  >mdi-upload</v-icon
                ></v-btn
              >
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <span class="ml-6 body-1 font-weight-bold"
      ></span
    > -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      peeJobFinancialDetailsUploadLoader: false,
      peeJobFinancialDetails: null,
      requiredRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
    };
  },

  methods: {
    async uploadPeeJobFinancialDetails() {
      if (this.$refs.peeJobFinancialDetailsForm.validate()) {
        try {
          const formData = new FormData();
          formData.append("file", this.peeJobFinancialDetails);
          this.peeJobFinancialDetailsUploadLoader = true;
          await axios.post(
            `${process.env.VUE_APP_BASEURL}/api/pee/job/financial/details/mass/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          this.peeJobFinancialDetailsUploadLoader = false;
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        } catch (e) {
          this.peeJobFinancialDetailsUploadLoader = false;
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });

          console.log(e);
        }
      }
    },
  },
};
</script>

<style></style>
