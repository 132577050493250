<template>
  <div>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-2">Εξαγωγη Excel</v-btn>
        <!-- <v-btn>Εξαγωγη PDF</v-btn> -->
      </v-card-title>
      <v-data-table :headers="test.headers" :items="test.content" :items-per-page="5">
        <p slot="no-results" :value="true" color="error">Η Αναζήτηση σας δεν έχει αποτελέσματα.</p>
      </v-data-table>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-account</v-icon>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      test: {
        headers: [
          { text: "ΠΕΡΙΦΕΡΕΙΑΚΗ ΕΝΟΤΗΤΑ", value: "one" },
          {
            text: "ΠΡΟΥΠΟΛΟΓΙΣΜΟΣ ΠΡΟ ΔΗΜΟΠΡΑΤΗΣΗΣ (με ΦΠΑ)",
            value: "two"
          },
          {
            text: "ΕΓΚΕΚΡΙΜΕΝΟΣ ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ (ΝΟΜΙΚΗ ΔΕΣΜΕΥΣΗ)",
            value: "three"
          },
          {
            text: "ΠΡΟΤΕΙΝΟΜΕΝΟΣ ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ (ΝΟΜΙΚΗ ΔΕΣΜΕΥΣΗ)",
            value: "four"
          },
          { text: "ΕΓΚΕΚΡΙΜΕΝΕΣ ΠΙΣΤΩΣΕΙΣ 2018", value: "five" },
          { text: "ΠΡΟΤΕΙΝΟΜΕΝΕΣ ΠΙΣΤΩΣΕΙΣ 2018", value: "six" },
          { text: "ΠΛΗΘΟΣ ΕΡΓΩΝ (ΠΕΕ2017Γ)", value: "seven" }
        ],
        content: [
          {
            one: "ΠΕ1: ΚΕΝΤΡΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
            two: "468,412,740.95",
            three: "439,477,129.43",
            four: "438,655,110.21",
            five: "67,206,656.49",
            six: "67,206,656.49",
            seven: "193"
          },
          {
            one: "ΠΕ2: ΒΟΡΕΙΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
            two: "209,196,981.35",
            three: "187,787,979.99",
            four: "184,196,695.30",
            five: "9,781,778.76",
            six: "9,781,778.76",
            seven: "91"
          },
          {
            one: "ΠΕ3: ΔΥΤΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
            two: "134,202,369.98",
            three: "109,195,452.58",
            four: "107,620,635.61",
            five: "16,008,236.01",
            six: "16,008,236.01",
            seven: "96"
          },
          {
            one: "ΠΕ3: ΔΥΤΙΚΟΥ ΤΟΜΕΑ ΑΘΗΝΩΝ",
            two: "134,202,369.98",
            three: "109,195,452.58",
            four: "107,620,635.61",
            five: "16,008,236.01",
            six: "16,008,236.01",
            seven: "96"
          }
        ]
      }
    };
  }
};
</script>