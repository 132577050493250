<template>
  <div>
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>

    <v-card v-else class="elevation-0">
      <span
        v-if="this.programmatikhSymvashContractStatus != 1"
        class="body-1 font-weight-medium black--text ml-6"
        >Τροποποιήσεις προγραμματικής σύμβασης μπορούν να εισαχθούν μόνο σε
        υπογεγραμμένες προγραμματικές συμβάσεις.</span
      >
      <v-row justify="center">
        <v-col cols="8">
          <v-tabs v-model="tab" show-arrows>
            <v-tabs-slider></v-tabs-slider>

            <v-tab> Γενικες πληροφοριες </v-tab>

            <v-tab :disabled="this.programmatikhSymvashContractStatus != 1">
              Τροποποιησεις
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form
                @submit.prevent="submit"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row justify="center">
                  <v-col cols="8">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Ημερομηνία υπογραφής*"
                          :rules="endDateRules"
                          :value="
                            greekFormatDate(
                              programmatikhSumvashObj.signatureDate
                            )
                          "
                          append-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        show-current
                        v-model="programmatikhSumvashObj.signatureDate"
                        locale="el-GR"
                        @change="save2"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Ημερομηνία λήξης*"
                          :value="
                            greekFormatDate(programmatikhSumvashObj.endDate)
                          "
                          :rules="endDateRules"
                          append-icon="mdi-calendar"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="programmatikhSumvashObj.endDate"
                        locale="el-GR"
                        @change="save"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Ημερομηνία τελευταίας τροποποίησης"
                          :value="
                            greekFormatDate(
                              programmatikhSumvashObj.lastModificationDate
                            )
                          "
                          append-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          :disabled="true"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="programmatikhSumvashObj.lastModificationDate"
                        locale="el-GR"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>

                    <v-autocomplete
                      :items="programmaticPhases"
                      item-text="name"
                      item-value="id"
                      label="Φάση προγραμματικής σύμβασης*"
                      v-model="
                        programmatikhSumvashObj.jobProgrammaticContractPhase.id
                      "
                      :rules="contractPhaserules"
                    ></v-autocomplete>

                    <!-- <v-autocomplete
                      label="Κατάσταση προγραμματικής σύμβασης*"
                      :items="programmaticStatuses"
                      item-text="name"
                      :rules="endDateRules"
                      item-value="id"
                      v-model="
                        programmatikhSumvashObj.jobProgrammaticContractStatus.id
                      "
                    ></v-autocomplete> -->

                    <v-text-field
                      label="Ποσό προγραμματικής σύμβασης"
                      v-model="
                        programmatikhSumvashObj.jobProgrammaticContractAmount
                      "
                      :hint="
                        programmatikhSumvashObj.jobProgrammaticContractAmount
                          | currency
                      "
                      persistent-hint
                      :error-messages="jobProgrammaticContractAmountErrors"
                      class="mb-4"
                    >
                    </v-text-field>

                    <v-text-field
                      label="Συγχρηματοδότηση (ποσό)"
                      v-model="
                        programmatikhSumvashObj.jobProgrammaticContractCoFinancingAmount
                      "
                      :hint="
                        programmatikhSumvashObj.jobProgrammaticContractCoFinancingAmount
                          | currency
                      "
                      persistent-hint
                      :error-messages="
                        jobProgrammaticContractCoFinancingAmountErrors
                      "
                      class="mb-4"
                    ></v-text-field>

                    <v-text-field
                      label="Αριθμός πρωτοκόλλου γνωμοδότησης (νομική υπηρεσία)"
                      v-model="
                        programmatikhSumvashObj.legalOpinionProtocolNumber
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Αριθμός πρωτοκόλλου υποβολής (νομική υπηρεσία)"
                      v-model="
                        programmatikhSumvashObj.legalSubmissionProtocolNumber
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Αριθμός πρωτοκόλλου αρμόδιου συλλογικού οργάνου"
                      v-model="programmatikhSumvashObj.pesyProtocolNumber"
                    ></v-text-field>
                    <!-- OLD TEXT Αριθμός πρωτοκόλλου εισήγησης περιφερειακού συμβουλίου -->
                    <v-text-field
                      label="Αριθμός απόφασης συλλογικού οργάνου"
                      v-model="programmatikhSumvashObj.pesyDecisionNumber"
                    ></v-text-field>
                    <!-- OLD TEXT PANW Αριθμός απόφασης περιφερειακού συμβουλίου -->

                    <v-card class="my-5">
                      <v-card-title>
                        <h1 class="title">Αντισυμβαλλόμενοι</h1>
                      </v-card-title>
                      <v-card-text>
                        <div class="d-flex align-center">
                          <v-autocomplete
                            v-model="antisimvalomenos"
                            :items="entityOrganizations"
                            item-text="name"
                            item-value="id"
                            :return-object="
                              programmatikhSumvashObj.id != null ? false : true
                            "
                            label="Αντισυμβαλλόμενοι"
                            clearable
                            class="flex-grow-1"
                          ></v-autocomplete>

                          <v-btn
                            :disabled="antisimvalomenos == null"
                            @click="addAntisimbalomenos"
                            width="200"
                            class="ml-4"
                          >
                            Προσθηκη
                          </v-btn>
                        </div>

                        <v-data-table
                          :footer-props="{
                            'disable-items-per-page': true,
                          }"
                          :options.sync="options"
                          :server-items-length="antisymbalomenoiCounter"
                          :headers="antisymvalomenoiHeaders"
                          :items="programmatikhSymvashEntityOrganizations"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex align-center">
                              <v-btn
                                icon
                                @click="
                                  programmatikhSumvashObj.id != null
                                    ? deleteAntisimbalomenos(item.id)
                                    : deleteAntisimbalomenos(
                                        item.entityOrganizationId
                                      )
                                "
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>

                    <v-text-field
                      label="Απόφαση αντισυμβαλλόμενου"
                      v-model="programmatikhSumvashObj.partnerDecision"
                    >
                    </v-text-field>

                    <v-text-field
                      label="Αριθμός πράξης ελεγκτικού συνεδρίου (ελεγκτικό συνέδριο)"
                      v-model="
                        programmatikhSumvashObj.courtAcceptanceProtocolNumber
                      "
                    ></v-text-field>
                    <!-- OLD TEXT Αριθμός πρωτοκόλλου έγκρισης -->
                    <v-text-field
                      label="Αριθμός πρωτοκόλλου υποβολής (ελεγκτικό συνέδριο)"
                      v-model="
                        programmatikhSumvashObj.courtSubmissionProtocolNumber
                      "
                    ></v-text-field>

                    <v-text-field
                      label="Εισηγητής"
                      v-model="programmatikhSumvashObj.rapporteur"
                    ></v-text-field>

                    <v-card class="my-5">
                      <v-card-title>
                        <h1 class="title">Αρχεία</h1>
                      </v-card-title>
                      <v-card-text>
                        <div class="d-flex align-center">
                          <v-file-input
                            chips
                            multiple
                            label="Αρχεία"
                            v-model="files"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                close
                                @click:close="deleteChip(index, text)"
                                >{{ text }}</v-chip
                              >
                            </template>
                          </v-file-input>

                          <v-btn
                            :disabled="files.length < 1"
                            @click="Antisimbalomenoi"
                            width="200"
                            class="ml-4"
                          >
                            Αρχειοθετηση</v-btn
                          >
                        </div>

                        <v-data-table
                          hide-default-footer
                          :headers="headers"
                          :items="
                            programmatikhSumvashObj.jobProgrammaticContractAttachments
                          "
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex align-center">
                              <v-btn icon @click="deleteFile(item.id)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn icon @click="download(item)">
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>

                    <!-- <v-text-field
            label="Ημερομηνία υπογραφής "
            v-model="programmatikhSumvashObj.signatureDate"
            :rules="signatureDateRules"
          ></v-text-field>-->

                    <v-textarea
                      class="mt-2"
                      label="Παρατηρήσεις"
                      outlined
                      v-model="programmatikhSumvashObj.comments"
                    >
                    </v-textarea>

                    <v-row>
                      <v-btn
                        tile
                        color="primary darken-2"
                        @click="submitProgrammatikh"
                        >αποθηκευση</v-btn
                      >
                      <!-- <v-btn
                        class="ml-2"
                        tile
                        color="primary darken-2"
                        @click="$router.go(-1)"
                        >ΑΚΥΡΟ</v-btn
                      > -->
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-card
                      v-if="
                        this.programmatikhSumvashObj.id != null &&
                        (this.programmatikhSymvashContractStatus == 1 ||
                          this.committees.length > 0)
                      "
                    >
                      <div class="py-10 ml-5" style="display: flex">
                        <v-btn @click="adding = true">ΝΕΟ</v-btn>
                        <!-- <v-btn
                v-else-if="adding"
                @click="submit"
                :loading="submitProgrammatikhButtonLoading"
              >Προσθηκη</v-btn>
              <v-btn class="ml-5" v-if="adding" @click="adding=false">Ακυρωση</v-btn>
              <v-btn class="ml-5" @click="commiteeDialog=true;">Επεξεργασια</v-btn>-->
                      </div>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>Απόφαση κοινής επιτροπής ΑΔΑ</th>
                            <th>Ημερομηνία σύστασης επιτροπής</th>
                            <th>Πρόεδρος</th>

                            <th>Μέλη επιτροπής</th>
                            <th>
                              Μέλη διεύθυνσης αναπτυξιακού προγραμματισμού
                            </th>
                            <th>Αναπληρωτής πρόεδρος</th>
                            <th>
                              Αναπληρωματικά μέλη διεύθυνσης αναπτυξιακού
                              προγραμματισμού
                            </th>
                            <th>Παρατηρήσεις</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr v-if="adding">
                  <td>
                    <v-text-field v-model="ada"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="chairCommittee"></v-text-field>
                  </td>

                  <td>
                    <v-text-field v-model="committeeMembers"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="membersPlanningDepartment"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="viceChairCommittee"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="viceMembersPlanningDepartment"></v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="comments"></v-text-field>
                  </td>
                </tr>-->
                          <tr v-for="item in committees" :key="item.id">
                            <td>
                              <p>{{ item ? item.ada : "" }}</p>
                            </td>
                            <td>
                              <p>
                                {{
                                  item
                                    ? new Date(
                                        item.establishmentDate
                                      ).toLocaleDateString("el")
                                    : ""
                                }}
                              </p>
                            </td>
                            <td>
                              <p>{{ item ? item.chairCommittee : "" }}</p>
                            </td>

                            <td>
                              <p>{{ item ? item.committeeMembers : "" }}</p>
                            </td>
                            <td>
                              <p>
                                {{ item ? item.membersPlanningDepartment : "" }}
                              </p>
                            </td>
                            <td>
                              <p>{{ item ? item.viceChairCommittee : "" }}</p>
                            </td>
                            <td>
                              <p>
                                {{
                                  item ? item.viceMembersPlanningDepartment : ""
                                }}
                              </p>
                            </td>
                            <td>
                              <p>{{ item ? item.comments : "" }}</p>
                            </td>
                            <td>
                              <v-btn
                                icon
                                @click="
                                  editingCommitee = { ...item };
                                  commiteeDialog = true;
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>

              <v-dialog v-model="adding" width="600">
                <v-card>
                  <v-card-title>Προσθηκη</v-card-title>
                  <v-card-text>
                    <v-form @submit.prevent="submit">
                      <v-text-field
                        :error-messages="newCommitteeAdaErrors"
                        v-model="newCommittee.ada"
                        label="Απόφαση κοινής επιτροπής ΑΔΑ*"
                      ></v-text-field>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="
                              greekFormatDate(newCommittee.establishmentDate)
                            "
                            label="Ημερομηνία σύστασης επιτροπής"
                            append-icon="mdi-calendar"
                            readonly
                            class="mt-4"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="newCommittee.establishmentDate"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                      <v-text-field
                        :error-messages="newCommitteChairCommiteeErrors"
                        v-model="newCommittee.chairCommittee"
                        label="Πρόεδρος*"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCommittee.committeeMembers"
                        label="Μέλη επιτροπής"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCommittee.membersPlanningDepartment"
                        label="Μέλη διεύθυνσης αναπτυξιακού προγραμματισμού"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCommittee.viceChairCommittee"
                        label="Αναπληρωτής πρόεδρος"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="newCommittee.viceMembersPlanningDepartment"
                        label="Αναπληρωματικά μέλη διεύθυνσης αναπτυξιακού προγραμματισμού"
                      ></v-text-field>
                      <v-text-field
                        v-model="newCommittee.comments"
                        label="Παρατηρήσεις"
                      ></v-text-field>

                      <v-card class="mb-6">
                        <v-card-title>
                          <h1 class="title">Αρχεία</h1>
                        </v-card-title>
                        <v-card-text>
                          <v-file-input
                            v-model="addingFiles"
                            chips
                            multiple
                            label="Αρχεία"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                close
                                @click:close="deletedAddingChip(index, text)"
                                >{{ text }}</v-chip
                              >
                            </template>
                          </v-file-input>

                          <v-btn
                            class="my-5"
                            :disabled="addingFiles.length < 1"
                            @click="AntisimbalomenoiAdding"
                          >
                            Αρχειοθετηση</v-btn
                          >

                          <v-data-table
                            hide-default-footer
                            :headers="headers"
                            :items="
                              newCommittee.jobProgrammaticContractCommitteeAttachments
                            "
                          >
                            <v-btn icon @click="deleteAddingFile(item.id)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                      <v-btn type="submit">Προσθηκη</v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="commiteeDialog">
                <v-card>
                  <v-form @submit.prevent="editCommittee">
                    <v-card-title>ΕΠΕΞΕΡΓΑΣΙΑ</v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="editingCommitee.ada"
                        label="Απόφαση κοινής επιτροπής ΑΔΑ"
                        :error-messages="editingCommiteeAdaErrors"
                      ></v-text-field>
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="
                              greekFormatDate(editingCommitee.establishmentDate)
                            "
                            label="Ημερομηνία σύστασης επιτροπής"
                            append-icon="mdi-calendar"
                            readonly
                            class="mt-4"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editingCommitee.establishmentDate"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                      <v-text-field
                        v-model="editingCommitee.chairCommittee"
                        label="Πρόεδρος"
                        :error-messages="editingCommiteeChairCommiteeErrors"
                      ></v-text-field>

                      <v-text-field
                        v-model="editingCommitee.committeeMembers"
                        label="Μέλη επιτροπής"
                      ></v-text-field>
                      <v-text-field
                        v-model="editingCommitee.membersPlanningDepartment"
                        label="Μέλη διεύθυνσης αναπτυξιακού προγραμματισμού"
                      ></v-text-field>
                      <v-text-field
                        v-model="editingCommitee.viceChairCommittee"
                        label="Αναπληρωτής πρόεδρος"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="editingCommitee.viceMembersPlanningDepartment"
                        label="Αναπληρωματικά μέλη διεύθυνσης αναπτυξιακού προγραμματισμού"
                      ></v-text-field>
                      <v-text-field
                        v-model="editingCommitee.comments"
                        label="Παρατηρήσεις"
                      ></v-text-field>

                      <v-card class="mb-6">
                        <v-card-title>
                          <h1 class="title">Αρχεία</h1>
                        </v-card-title>
                        <v-card-text>
                          <v-file-input
                            v-model="editingFiles"
                            chips
                            multiple
                            label="Αρχεία"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                close
                                @click:close="deletedEditingChip(index, text)"
                                >{{ text }}</v-chip
                              >
                            </template>
                          </v-file-input>

                          <v-btn
                            class="my-5"
                            :disabled="editingFiles.length < 1"
                            @click="AntisimbalomenoiEditing"
                          >
                            Αρχειοθετηση</v-btn
                          >

                          <v-data-table
                            hide-default-footer
                            :headers="headers"
                            :items="
                              editingCommitee.jobProgrammaticContractCommitteeAttachments
                            "
                          >
                            <template v-slot:[`item.actions`]="{ item }">
                              <div class="d-flex align-center">
                                <v-btn icon @click="deleteEditingFile(item.id)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn icon @click="download(item)">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn type="submit">αποθηκευση</v-btn>
                      <v-btn @click="commiteeDialog = false">ακυρωση</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <div class="d-flex align-center justify-center">
            <v-card class="my-5" width="1000px" max-width="1000px">
              <v-card-text>
                <v-data-table
                  :footer-props="{
                    'disable-items-per-page': true,
                  }"
                  :options.sync="modificationsOptions"
                  :server-items-length="modificationsCounter"
                  :headers="modificationsHeaders"
                  :items="modifications"
                >
                  <template v-slot:[`item.expiringDate`]="{ item }">
                    <span>{{
                      new Date(item.expiringDate).toLocaleDateString("el")
                    }}</span>
                  </template>

                  <template v-slot:[`item.lastModificationDate`]="{ item }">
                    <span>{{
                      new Date(item.lastModificationDate).toLocaleDateString(
                        "el"
                      )
                    }}</span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex align-center">
                      <v-btn
                        icon
                        @click="
                          editModificationDialog = true;
                          modification.expiringDate = item.expiringDate;
                          modification.lastModificationDate =
                            item.lastModificationDate;
                          modificationId = item.id;
                        "
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>

                      <v-btn icon @click="deleteModification(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
                <div class="d-flex flex-grow-1 ml-auto">
                  <v-btn
                    @click="
                      addModificationDialog = true;
                      modification.expiringDate = null;
                      modification.lastModificationDate = null;
                    "
                    class="ml-auto"
                    >ΠΡΟΣΘΗΚΗ</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </div>

          <v-dialog
            v-if="addModificationDialog"
            v-model="addModificationDialog"
            width="600"
          >
            <v-card>
              <v-card-title>Προσθήκη</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="submit">
                  <v-menu
                    ref="menuExpiringDate"
                    v-model="menuExpiringDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="greekFormatDate(modification.expiringDate)"
                        label="Ημερομηνία λήξης"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                        :error-messages="modificationExpiringDateErrors"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="modification.expiringDate"
                      @input="menuExpiringDate = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menuLastModificationDate"
                    v-model="menuLastModificationDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          greekFormatDate(modification.lastModificationDate)
                        "
                        label="Ημερομηνία τελευταίας τροποποίησης"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                        :error-messages="modificationLastModificationDateErrors"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="modification.lastModificationDate"
                      @input="menuLastModificationDate = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-btn
                    @click="addModification"
                    :loading="addModificationLoader"
                    class="ml-auto"
                    >Προσθηκη</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editModificationDialog" width="600">
            <v-card>
              <v-card-title>Επεξεργασία</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="submit">
                  <v-menu
                    ref="menuEditExpiringDate"
                    v-model="menuEditExpiringDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="greekFormatDate(modification.expiringDate)"
                        label="Ημερομηνία λήξης"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="modification.expiringDate"
                      @input="menuEditExpiringDate = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menuEditLastModificationDate"
                    v-model="menuEditLastModificationDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="
                          greekFormatDate(modification.lastModificationDate)
                        "
                        label="Ημερομηνία τελευταίας τροποποίησης"
                        append-icon="mdi-calendar"
                        readonly
                        class="mt-4"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="modification.lastModificationDate"
                      @input="menuEditLastModificationDate = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-btn
                    @click="editModification"
                    :loading="addModificationLoader"
                    class="ml-auto"
                    >Επεξεργασια</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, decimal } from "vuelidate/lib/validators";
export default {
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu5(val) {
      val && setTimeout(() => (this.$refs.picker5.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = "YEAR"));
    },
    menu3(val) {
      val && setTimeout(() => (this.$refs.picker3.activePicker = "YEAR"));
    },
    menu4(val) {
      val && setTimeout(() => (this.$refs.picker4.activePicker = "YEAR"));
    },

    commiteeDialog(val) {
      if (!val) {
        this.$v.editingCommitee.$reset();
      }
    },
    async options() {
      await this.handler();
    },

    async modificationsOptions() {
      await this.modificationsHandler();
    },
  },

  async created() {
    const res = await this.$store.dispatch(
      "getProgrammatikhSumvash",
      this.$route.params.project_id
    );

    if (res.data.status == 0) {
      this.programmatikhSumvashObj = res.data.data;
      this.modification.jobProgrammaticContractId = res.data.data.id;
      this.programmatikhSymvashContractStatus =
        res.data.data.jobProgrammaticContractPhase.id;
      this.programmatikhEdit = true;
      await this.handler();
      await this.modificationsHandler();
    } else {
      this.programmatikhSumvashObj.jobProgrammaticContractAmount =
        this.$store.state.projects.projectEditing.budgetBeforeAuction;
    }

    const res3 = await axios.get(
      `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/phase/list`
    );
    this.programmaticPhases = res3.data.data;

    const res1 = await axios.get(
      `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/status/list`
    );
    this.programmaticStatuses = res1.data.data;

    const res2 = await axios.get(
      `${process.env.VUE_APP_BASEURL}/api/entity/organization/list`
    );
    this.entityOrganizations = res2.data.data;

    // axios
    //   .get(
    //     `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/partner/list/paging/${this.programmatikhSumvashObj.id}`,
    //     {
    //       params: {
    //         page: 1,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log("programmatikh symbash foreis", res);

    //     this.programmatikhSymvashEntityOrganizations =
    //       res.data.data;
    //   });

    if (this.programmatikhSumvashObj.id != null) {
      const res = await axios.get(
        `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/committee/programmatic/contract/${this.programmatikhSumvashObj.id}`
      );
      this.committees = res.data.data;
    }

    this.pageLoader = false;

    // this.programmatikhSumvashDialog = true;
  },
  data() {
    return {
      pageLoader: true,

      options: {
        itemsPerPage: 20,
        page: 1,
      },

      antisymbalomenoiCounter: null,

      sortBy: "entityOrganizationName",
      sortDesc: true,
      modificationsOptions: {
        itemsPerPage: 20,
        page: 1,
      },
      modificationsCounter: null,

      tab: null,
      submitProgrammatikhButtonLoading: false,
      valid2: true,
      programmatikhSymvashContractStatus: null,
      requiredRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],

      editModificationDialog: false,
      modificationId: null,
      addModificationDialog: false,
      addModificationLoader: false,
      modifications: [],
      menuExpiringDate: false,
      menuLastModificationDate: false,
      menuEditExpiringDate: false,
      menuEditLastModificationDate: false,
      modification: {
        expiringDate: null,
        jobProgrammaticContractId: null,
        lastModificationDate: null,
      },
      modificationsHeaders: [
        {
          text: "Ημερομηνία λήξης",
          value: "expiringDate",
          sortable: false,
        },

        {
          text: "Ημερομηνία τελευταίας τροποποίησης",
          value: "lastModificationDate",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],

      commiteeDialog: null,
      adding: false,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      committees: [],
      antisimvalomenos: null,
      entityOrganizations: [],
      programmatikhSymvashEntityOrganizations: [],
      programmatikhSumvashObj: {
        id: null,

        comments: "",
        courtAcceptanceProtocolNumber: "",
        courtSubmissionProtocolNumber: "",
        endDate: "",
        lastModificationDate: "",
        jobProgrammaticContractAmount: null, //new
        jobProgrammaticContractCoFinancingAmount: null,
        jobProgrammaticContractAttachments: [],
        jobProgrammaticContractPhase: {
          id: "",
          name: "",
        },

        legalOpinionProtocolNumber: "",
        legalSubmissionProtocolNumber: "",
        partnerDecision: "",
        pesyDecisionNumber: "",
        pesyProtocolNumber: "",
        rapporteur: "",
        signatureDate: "",
      },
      programmatikhEdit: false,
      programmaticPhases: [],
      programmaticStatuses: [],

      newCommittee: {
        ada: "",
        chairCommittee: "",
        comments: "",
        establishmentDate: "",
        committeeMembers: "",
        membersPlanningDepartment: "",
        viceChairCommittee: "",
        viceMembersPlanningDepartment: "",
        jobProgrammaticContractCommitteeAttachments: [],
      },

      editingCommitee: {
        ada: "",
        chairCommittee: "",
        establishmentDate: "",
        comments: "",
        committeeMembers: "",
        membersPlanningDepartment: "",
        viceChairCommittee: "",
        viceMembersPlanningDepartment: "",
        jobProgrammaticContractCommitteeAttachments: [],
      },
      // editAda: "",
      // editChairCommittee: "",
      // editComments: "",
      // editCommitteeMembers: "",
      // editMembersPlanningDepartment: "",
      // editViceChairCommittee: "",
      // editViceMembersPlanningDepartment: "",

      files: [],
      antisymvalomenoiHeaders: [
        {
          text: "Όνομα",
          value: "entityOrganizationName",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      headers: [
        {
          text: "Όνομα",
          value: "filename",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      valid: true,
      endDateRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
      signatureDateRules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
      contractPhaserules: [(v) => !!v || "Υποχρεωτικό πεδίο"],

      addingFiles: [],
      editingFiles: [],
    };
  },
  methods: {
    async handler() {
      try {
        if (this.programmatikhSumvashObj.id != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/partner/list/paging/${this.programmatikhSumvashObj.id}`,
            {
              params: {
                page: this.options.page,
              },
            }
          );
          this.programmatikhSymvashEntityOrganizations = res.data.data;

          const res1 = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/partner/list/counter/${this.programmatikhSumvashObj.id}`
          );
          this.antisymbalomenoiCounter = res1.data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async modificationsHandler() {
      try {
        if (this.programmatikhSumvashObj.id != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/modification/list/paging/${this.programmatikhSumvashObj.id}`,
            {
              params: {
                page: this.modificationsOptions.page,
              },
            }
          );
          this.modifications = res.data.data;
          if (this.modifications.length > 0)
            this.programmatikhSumvashObj.lastModificationDate =
              this.modifications[
                this.modifications.length - 1
              ].lastModificationDate;
          // console.log(this.programmatikhSumvashObj.lastModificationDate);

          const res1 = await axios.get(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/modification/list/counter/${this.programmatikhSumvashObj.id}`
          );

          this.modificationsCounter = res1.data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },

    submitProgrammatikh() {
      this.$v.programmatikhSumvashObj.$touch();

      if (
        this.$refs.form.validate() &&
        !this.$v.programmatikhSumvashObj.$invalid
      ) {
        const credentials = {
          obj: {
            comments: this.programmatikhSumvashObj.comments,
            courtAcceptanceProtocolNumber:
              this.programmatikhSumvashObj.courtAcceptanceProtocolNumber,
            courtSubmissionProtocolNumber:
              this.programmatikhSumvashObj.courtSubmissionProtocolNumber,

            endDate: this.programmatikhSumvashObj.endDate,
            fundingEntity: this.programmatikhSumvashObj.fundingEntity,
            jobProgrammaticContractAmount:
              this.programmatikhSumvashObj.jobProgrammaticContractAmount,
            jobProgrammaticContractCoFinancingAmount:
              this.programmatikhSumvashObj
                .jobProgrammaticContractCoFinancingAmount,
            jobProgrammaticContractAttachmentsIds:
              this.jobProgrammaticContractAttachmentsIds,
            jobProgrammaticContractPhaseId:
              this.programmatikhSumvashObj.jobProgrammaticContractPhase.id,
            // jobProgrammaticContractStatusId:
            //   this.programmatikhSumvashObj.jobProgrammaticContractStatus.id,
            legalOpinionProtocolNumber:
              this.programmatikhSumvashObj.legalOpinionProtocolNumber,
            legalSubmissionProtocolNumber:
              this.programmatikhSumvashObj.legalSubmissionProtocolNumber,
            partnerDecision: this.programmatikhSumvashObj.partnerDecision,
            peeJobId: this.$store.state.projects.projectEditing.id,
            pesyDecisionNumber: this.programmatikhSumvashObj.pesyDecisionNumber,
            pesyProtocolNumber: this.programmatikhSumvashObj.pesyProtocolNumber,
            rapporteur: this.programmatikhSumvashObj.rapporteur,
            signatureDate: this.programmatikhSumvashObj.signatureDate,
            entityOrganizationId:
              this.programmatikhSymvashEntityOrganizations.map(
                (item) => item.entityOrganizationId
              ),
          },
          id: this.programmatikhSumvashObj.id,
        };
        if (this.programmatikhSumvashObj.lastModificationDate != "")
          credentials.lastModificationDate =
            this.programmatikhSumvashObj.lastModificationDate;

        if (this.programmatikhEdit) {
          this.$store
            .dispatch("editProgrammatikhSumbash", credentials)
            .then((res) => {
              if (res.data.status === -1) {
                this.$store.dispatch("errorHandler", res.data.message);
                return;
              }
              this.$router.push(
                `/projects/${this.$route.params.project_id}/edit-project`
              );
              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });
            });
        } else {
          console.log(credentials);
          this.$store
            .dispatch("setProgrammatikhSumbash", credentials)
            .then((res) => {
              if (res.data.status === -1) {
                this.$store.dispatch("errorHandler", res.data.message);
                return;
              }
              this.$router.push(
                `/projects/${this.$route.params.project_id}/edit-project`
              );

              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$store.commit("toggleSnackbar", {
                open: true,
                text: "Αποτυχία",
              });
            });
        }
      }
    },

    submit() {
      this.$v.newCommittee.$touch();

      if (!this.$v.newCommittee.$invalid) {
        this.submitProgrammatikhButtonLoading = true;
        console.log("test");
        // console.log("ididiidi",this.programmatikhSumvashObj.id);
        const credentials = {
          jobProgrammaticContractId: this.programmatikhSumvashObj.id,
          ada: this.newCommittee.ada,
          chairCommittee: this.newCommittee.chairCommittee,
          comments: this.newCommittee.comments,
          establishmentDate: this.newCommittee.establishmentDate,
          committeeMembers: this.newCommittee.committeeMembers,
          membersPlanningDepartment:
            this.newCommittee.membersPlanningDepartment,
          viceChairCommittee: this.newCommittee.viceChairCommittee,
          viceMembersPlanningDepartment:
            this.newCommittee.viceMembersPlanningDepartment,
          jobProgrammaticContractCommitteeAttachmentsIds:
            this.jobProgrammaticContractAttachmentsIds,
        };
        this.$store
          .dispatch("submitNewCommittee", credentials)
          .then((res) => {
            // console.log(res);
            //   this.committees.push(credentials);
            axios
              .get(
                `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/committee/programmatic/contract/${this.programmatikhSumvashObj.id}`
              )
              .then((res) => {
                // console.log(res);
                this.committees = res.data.data;
                this.submitProgrammatikhButtonLoading = false;
                this.adding = false;
                this.newCommittee.ada = "";
                this.newCommittee.chairCommitee = "";
                this.newCommittee.committeeMembers = "";
                this.newCommittee.membersPlanningDepartment = "";
                this.newCommittee.viceChairCommittee = "";
                this.newCommittee.viceMembersPlanningDepartment = "";
                this.newCommittee.comments = "";
                // this.$store.dispatch('successSnack')
              })
              .catch((err) => {
                this.submitProgrammatikhButtonLoading = false;
                console.log(err);
              });
          })
          .catch((err) => {
            this.submitProgrammatikhButtonLoading = false;
            console.log(err);
          });
      }
    },

    editCommittee() {
      this.$v.editingCommitee.$touch();
      if (!this.$v.editingCommitee.$invalid) {
        const credentials = {
          obj: {
            jobProgrammaticContractId: this.programmatikhSumvashObj.id,
            ada: this.editingCommitee.ada,
            chairCommittee: this.editingCommitee.chairCommittee,
            comments: this.editingCommitee.comments,
            committeeMembers: this.editingCommitee.committeeMembers,
            membersPlanningDepartment:
              this.editingCommitee.membersPlanningDepartment,
            viceChairCommittee: this.editingCommitee.viceChairCommittee,
            viceMembersPlanningDepartment:
              this.editingCommitee.viceMembersPlanningDepartment,
            jobProgrammaticContractCommitteeAttachmentsIds:
              this.editingJobProgrammaticContractCommitteeAttachmentsIds,
          },
          id: this.editingCommitee.id,
        };
        this.$store
          .dispatch("submitEditCommittee", credentials)
          .then((res) => {
            // console.log(res);
            axios
              .get(
                `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/committee/programmatic/contract/${this.programmatikhSumvashObj.id}`
              )
              .then((res) => {
                this.committees = res.data.data;
                this.commiteeDialog = false;
                this.$store.commit("toggleDialog", {
                  open: true,
                  text: "Επιτυχία",
                });
              })
              .catch((err) => {
                this.$store.commit("toggleSnackbar", {
                  open: true,
                  text: "Αποτυχία",
                });

                console.log(err);
              });
          })
          .catch((err) => {
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });

            console.log(err);
          });
      }
    },

    greekFormatDate(date) {
      if (!date) {
        return "";
      } else {
        return moment(moment.utc(date).toDate()).local().format("DD/MM/YYYY");
      }
    },

    deleteChip(index) {
      // Prompt here with text if required
      this.files.splice(index, 1);
    },
    deleteAddingChip(index) {
      // Prompt here with text if required
      this.addingFiles.splice(index, 1);
    },
    deleteEditingChip(index) {
      this.editingFiles.splice(index, 1);
    },

    async addAntisimbalomenos() {
      if (this.programmatikhSumvashObj.id != null) {
        try {
          const res = await axios.post(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/partner/add`,

            {
              entityOrganizationId: this.antisimvalomenos,
              jobProgrammaticContractId: this.programmatikhSumvashObj.id,
            }
          );

          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);
            return;
          }
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });

          await this.handler();
        } catch (e) {
          console.log(e);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        }
      } else {
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });

        const duplicate = this.programmatikhSymvashEntityOrganizations.find(
          (el) => el.entityOrganizationId === this.antisimvalomenos.id
        );

        if (duplicate) return;

        this.programmatikhSymvashEntityOrganizations.push({
          entityOrganizationId: this.antisimvalomenos.id,
          entityOrganizationName: this.antisimvalomenos.name,
        });
        this.antisymbalomenoiCounter++;
      }
    },

    async deleteAntisimbalomenos(id) {
      if (this.programmatikhSumvashObj.id != null) {
        try {
          const res = await axios.delete(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/partner/delete/${id}`,

            {
              entityOrganizationId: this.antisimvalomenos,
              jobProgrammaticContractId: this.programmatikhSumvashObj.id,
            }
          );
          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);
            return;
          }
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });

          await this.handler();
        } catch (e) {
          console.log(e);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        }
      } else {
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });

        const index = this.programmatikhSymvashEntityOrganizations.findIndex(
          (el) => {
            return el.entityOrganizationId === id;
          }
        );

        this.programmatikhSymvashEntityOrganizations.splice(index, 1);
        this.antisymbalomenoiCounter--;
      }
    },

    Antisimbalomenoi() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });

      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          res.data.data.forEach((item) => {
            this.programmatikhSumvashObj.jobProgrammaticContractAttachments.push(
              item
            );
            this.$store.commit("toggleDialog", {
              open: true,
              text: "Επιτυχία",
            });
          });
          this.files = [];
        })
        .catch(() => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    AntisimbalomenoiAdding() {
      let formData = new FormData();
      this.addingFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          res.data.data.forEach((item) => {
            this.newCommittee.jobProgrammaticContractCommitteeAttachments.push(
              item
            );
          });
          this.addingFiles = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    AntisimbalomenoiEditing() {
      let formData = new FormData();
      this.editingFiles.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          res.data.data.forEach((item) => {
            this.editingCommitee.jobProgrammaticContractCommitteeAttachments.push(
              item
            );
          });
          this.editingFiles = [];

          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    deleteFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobProgrammaticContractId: this.programmatikhSumvashObj.id
                ? this.programmatikhSumvashObj.id
                : 0,
            },
          }
        )
        .then(() => {
          this.programmatikhSumvashObj.jobProgrammaticContractAttachments.splice(
            this.programmatikhSumvashObj.jobProgrammaticContractAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    deleteAddingFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobProgrammaticContractCommitteeId: 0,
            },
          }
        )
        .then(() => {
          this.newCommittee.jobProgrammaticContractCommitteeAttachments.splice(
            this.newCommittee.jobProgrammaticContractCommitteeAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    deleteEditingFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              jobProgrammaticContractCommitteeId: this.editCommittee.id
                ? this.editCommittee.id
                : 0,
            },
          }
        )
        .then(() => {
          this.editingCommitee.jobProgrammaticContractCommitteeAttachments.splice(
            this.editingCommitee.jobProgrammaticContractCommitteeAttachments.findIndex(
              (item) => item.id == id
            ),
            1
          );
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    download(item) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/download/${item.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async addModification() {
      try {
        this.$v.modification.$touch();
        if (!this.$v.modification.$invalid) {
          this.addModificationLoader = true;
          const res = await axios.post(
            `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/modification/add`,
            this.modification
          );
          this.addModificationLoader = false;
          this.addModificationDialog = false;
          if (res.data.status === -1) {
            this.$store.dispatch("errorHandler", res.data.message);
            return;
          }
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });

          await this.modificationsHandler();
        }
      } catch (e) {
        this.addModificationLoader = false;

        console.log(e);
        this.$store.commit("toggleSnackbar", { open: true, text: "Αποτυχία" });
      }
    },

    async editModification() {
      try {
        this.addMofificationLoader = true;
        const res = await axios.put(
          `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/modification/edit/${this.modificationId}`,
          this.modification
        );
        this.addModificationLoader = false;
        this.editModificationDialog = false;
        if (res.data.status === -1) {
          this.$store.dispatch("errorHandler", res.data.message);
          return;
        }
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });

        await this.modificationsHandler();
      } catch (e) {
        this.addModificationLoader = false;
        this.$store.commit("toggleSnackbar", { open: true, text: "Αποτυχία" });

        console.log(e);
      }
    },

    async deleteModification(id) {
      try {
        const res = await axios.delete(
          `${process.env.VUE_APP_BASEURL}/api/job/programmatic/contract/modification/delete/${id}`,
          this.modification
        );
        if (res.data.status === -1) {
          this.$store.dispatch("errorHandler", res.data.message);
          return;
        }
        await this.modificationsHandler();
        this.$store.commit("toggleDialog", {
          open: true,
          text: "Επιτυχία",
        });
      } catch (e) {
        console.log(e);
        this.$store.commit("toggleSnackbar", { open: true, text: "Αποτυχία" });
      }
    },
  },

  validations: {
    newCommittee: {
      ada: { required },
      chairCommittee: { required },
    },

    editingCommitee: {
      ada: { required },
      chairCommittee: { required },
    },

    programmatikhSumvashObj: {
      jobProgrammaticContractAmount: { decimal },
      jobProgrammaticContractCoFinancingAmount: { decimal },
    },

    modification: {
      expiringDate: { required },
      lastModificationDate: { required },
    },
  },

  computed: {
    jobProgrammaticContractAttachmentsIds() {
      if (
        this.programmatikhSumvashObj.jobProgrammaticContractAttachments
          .length == 0
      )
        return [];
      return this.programmatikhSumvashObj.jobProgrammaticContractAttachments.map(
        (item) => item.id
      );
    },
    jobProgrammaticContractCommitteeAttachmentsIds() {
      if (
        this.newCommittee.jobProgrammaticContractCommitteeAttachments.length ==
        0
      )
        return [];
      return this.newCommittee.jobProgrammaticContractCommitteeAttachments.map(
        (item) => item.id
      );
    },
    editingJobProgrammaticContractCommitteeAttachmentsIds() {
      if (
        this.editingCommitee.jobProgrammaticContractCommitteeAttachments
          .length == 0
      )
        return [];
      return this.editingCommitee.jobProgrammaticContractCommitteeAttachments.map(
        (item) => item.id
      );
    },

    modificationExpiringDateErrors() {
      const errors = [];
      if (!this.$v.modification.expiringDate.$dirty) return errors;
      if (!this.$v.modification.expiringDate.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    modificationLastModificationDateErrors() {
      const errors = [];
      if (!this.$v.modification.lastModificationDate.$dirty) return errors;
      if (!this.$v.modification.lastModificationDate.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    newCommitteeAdaErrors() {
      const errors = [];
      if (!this.$v.newCommittee.ada.$dirty) return errors;
      if (!this.$v.newCommittee.ada.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    newCommitteChairCommiteeErrors() {
      const errors = [];
      if (!this.$v.newCommittee.chairCommittee.$dirty) return errors;
      if (!this.$v.newCommittee.chairCommittee.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    editingCommiteeAdaErrors() {
      const errors = [];
      if (!this.$v.editingCommitee.ada.$dirty) return errors;
      if (!this.$v.editingCommitee.ada.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    editingCommiteeChairCommiteeErrors() {
      const errors = [];
      if (!this.$v.editingCommitee.chairCommittee.$dirty) return errors;
      if (!this.$v.editingCommitee.chairCommittee.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    jobProgrammaticContractAmountErrors() {
      //new
      const errors = [];
      if (!this.$v.programmatikhSumvashObj.jobProgrammaticContractAmount.$dirty)
        return errors;
      if (
        !this.$v.programmatikhSumvashObj.jobProgrammaticContractAmount.decimal
      )
        errors.push("Πρέπει να είναι αριθμός");
      return errors;
      // if(this.$v.programmatikhSumvashObj.jobProgrammaticContractAmount != $store.state.projects.projectEditing.budgetBeforeAuction){
      //    return error.push("Ποσό διαφορετικό του προϋπολογισμού προ δημοπράτησης");
      // }
    },

    jobProgrammaticContractCoFinancingAmountErrors() {
      const errors = [];
      if (
        !this.$v.programmatikhSumvashObj
          .jobProgrammaticContractCoFinancingAmount.$dirty
      )
        return errors;
      if (
        !this.$v.programmatikhSumvashObj
          .jobProgrammaticContractCoFinancingAmount.decimal
      )
        errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },
  },
};
</script>
