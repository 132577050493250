import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import { el, en } from 'vuetify/lib/locale'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { el, en },
    current: 'el'
  }
});
