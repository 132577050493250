<!-- TODO: globalize breadcrumbs with home button -->

<template>
  <v-app>
    <Header />

    <v-main class="py-5">
      <v-container fluid>
        <v-row>
          <v-col cols="12" offset="0">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
      <Snackbar />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Snackbar from "@/components/Snackbar";
export default {
  mounted() {
    window.setInterval(() => {
      if (this.isAuth) {
        this.$store
          .dispatch("refresh")
          .then((res) => {
            console.log("REFRESH", res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 1000 * 60 * 30);
  },

  async created() {
    try {
      await this.getErrors();
    } catch (e) {
      console.log(e);
    }
  },

  components: {
    Header,
    Footer,
    Snackbar,
  },
  computed: {
    ...mapGetters(["isAuth"]),
  },

  methods: {
    ...mapActions(["getErrors"]),
  },
};
</script>

<style lang="scss">
.v-main {
  background: #fffdfa;
}

// remove text-field message padding
.no-message {
  .v-text-field__details,
  .v-messages {
    min-height: 0px !important;
    height: 0px !important;
  }

  .v-input__slot {
    margin-bottom: 0px !important;
  }

  .v-text-field__details {
    display: none !important;
  }
}

.flex-1 {
  flex: 1 1 auto
}

a {
  transition: all 0.2s;
}
a:hover {
  opacity: 0.8;
}
</style>
