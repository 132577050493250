<template>
  <div>
    <v-row justify="center">
      <v-col cols="4">
        <v-form @submit.prevent="login">
          <v-text-field
            v-model="username"
            label="Όνομα χρήστη"
            :error-messages="usernameErrors"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Κωδικός πρόσβασης"
            :error-messages="passwordErrors"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
          >
            ></v-text-field
          >
          <v-btn
            :loading="$store.state.loading"
            type="submit"
            class="mt-2"
            block
            color="primary darken-2"
            >Συνδεση</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
const loginFail = function () {
  return !this.fail;
};
export default {
  data() {
    return {
      show: false,
      username: "",
      password: "",
      fail: false,
    };
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const credentials = {
          username: this.username,
          password: this.password,
        };
        this.$store
          .dispatch("login", credentials)
          .then(() => {
            this.$router.push("/");
          })
          .catch((err) => {
            console.log(err);
            this.fail = true;
          });
      }
    },
  },

  validations: {
    username: { required, loginFail },
    password: { required, loginFail },
  },

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.username.loginFail) errors.push("Αποτυχής σύνδεση");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.password.loginFail) errors.push("Αποτυχής σύνδεση");
      return errors;
    },
  },

  watch: {
    username(v1, v2) {
      if (v1 != v2) {
        this.fail = false;
      }
    },

    password(v1, v2) {
      if (v1 != v2) {
        this.fail = false;
      }
    },
  },
};
</script>