var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.pageLoader)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"size":"100","indeterminate":"","color":"primary"}})],1):_c('div',{staticClass:"card mt-5"},[_c('v-alert',{attrs:{"text":"","tile":"","dense":"","color":"primary"}},[_vm._v("Ειδοποιήσεις")]),(_vm.notificationsCounter > 0)?_c('v-row',{staticClass:"mx-2",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"body-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.notificationsCounter))]),_vm._v(" υπογεγραμμένες προγραμματικές συμβάσεις χωρίς επιτροπή.")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary darken-3"},on:{"click":_vm.openDialog}},[_vm._v("ΛΙΣΤΑ ΕΡΓΩΝ")])],1)],1):_c('v-row',{style:('height:60px')}),_c('v-dialog',{attrs:{"width":"700px","max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":{
              'disable-items-per-page': true,
            },"options":_vm.options,"server-items-length":_vm.notificationsCounter,"headers":_vm.headers,"items":_vm.notifications,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("ΠΡΟΒΟΛΗ")])])],1)]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }