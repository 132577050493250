<template>
  <div>
    <v-breadcrumbs :items="[{ text: 'ΑΙΤΗΜΑΤΑ' }]" divider=">"></v-breadcrumbs>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="mx-10">
          <v-card-title>
            <div class="d-flex align-start flex-grow-1">
              <v-btn
                dark
                tile
                color="primary darken-2"
                @click="$router.push('/requests/newrequest')"
              >
                <v-icon>mdi-plus</v-icon>Νεο Αιτημα
              </v-btn>

              <v-spacer></v-spacer>
              <div class="d-flex flex-column">
                <v-text-field
                  class="mt-3 flex-grow-0"
                  label="Αναζήτηση"
                  v-model="query"
                  append-icon="mdi-magnify"
                  style="width: 400px"
                ></v-text-field>

                <div class="d-flex flex-column" v-if="customSearch">
                  <v-autocomplete
                    v-model="unit"
                    clearable
                    :items="$store.state.requests.units"
                    item-text="name"
                    item-value="id"
                    style="width: 400px"
                    label="Περιφερειακή ενότητα"
                    class="flex-grow-0"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="municipality"
                    clearable
                    :items="$store.state.requests.municipalities"
                    item-text="name"
                    item-value="id"
                    style="width: 400px"
                    label="Δήμος"
                    class="flex-grow-0"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="year"
                    clearable
                    :items="years"
                    label="'Ετος"
                  ></v-autocomplete>
                </div>
              </div>
              <v-btn icon class="mx-2" @click="customSearch = !customSearch"
                ><v-icon>mdi-chevron-down</v-icon></v-btn
              >
              <v-btn
                text
                outlined
                @click="
                  searched = true;
                  updatePagination({ page: 1 });
                "
                >αναζητηση</v-btn
              >
              <v-btn
                v-if="searched"
                class="ml-5"
                text
                outlined
                @click="
                  (query = ''), (searched = false);
                  updatePagination({ page: 1 });
                "
                >ακυρωση</v-btn
              >
            </div>
          </v-card-title>
          <div
            style="display: flex; justify-content: center; width: 100%"
            v-if="$store.state.loading"
          >
            <v-progress-circular
              :size="100"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-simple-table style="white-space: nowrap" dense class="mt-12">
            <thead>
              <tr>
                <th></th>
                <th>Δήμος</th>
                <th>Περιφερειακή ενότητα</th>
                <th>Τύπος έργου</th>
                <th>Τίτλος αιτήματος</th>
                <!-- <th>Θέση</th> -->
                <th>Πρωτόκολλο</th>
                <th>Προϋπολογισμός</th>
                <th>Έχει ενταχθεί;</th>
                <th>Μελέτη</th>

                <!-- <th>αρχεία</th> -->
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in requests" :key="item.id">
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="edit(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Επεξεργασία</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          itemToDelete = item;
                          deleteDialog = true;
                        "
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </td>
                <td>{{ item.municipality ? item.municipality.name : "" }}</td>
                <td>{{ item.unit ? item.unit.name : "" }}</td>
                <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                <td>
                  {{
                    item.title.length > 50
                      ? item.title.substring(0, 50) + "..."
                      : item.title
                  }}
                </td>
                <!-- <td>{{ item.place }}</td> -->
                <td>{{ formatProtocol(item) }}</td>
                <!-- <td>{{ item.protocolNumber }}</td> -->
                <td>{{ thousands_separators(item.budget) }}</td>
                <td>
                  {{ item.jobRequestStatus ? item.jobRequestStatus.name : "" }}
                </td>
                <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>

                <!-- <td>{{ item.protocolNumberDate }}</td> -->

                <!-- <td>{{ item.requestAttachments }}</td> -->
              </tr>
            </tbody>
            <!-- <div v-else>
              <v-progress-circular :size="200" indeterminate></v-progress-circular>
            </div>-->
          </v-simple-table>
          <div
            style="display: flex; width: 100%; align-items: center"
            class="px-12 py-5"
          >
            <v-tooltip top v-if="page > 1">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="
                    page -= 1;
                    updatePagination({ page: page });
                  "
                  >mdi-arrow-left</v-icon
                >
              </template>
              <span>Προηγούμενο</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <p style="justify-self: center">
              Σελίδα {{ page }} από {{ pages }}
            </p>
            <v-spacer></v-spacer>

            <v-tooltip top v-if="page < pages">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="
                    page += 1;
                    updatePagination({ page: page });
                  "
                  >mdi-arrow-right</v-icon
                >
              </template>
              <span>Επόμενο</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog width="400" v-model="deleteDialog">
      <v-card>
        <v-card-title>ΔΙΑΓΡΑΦΗ</v-card-title>
        <v-card-text>Θέλετε να προχωρήσετε με τη διαγραφή;</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              remove(itemToDelete);
              deleteDialog = false;
            "
            text
            color="red"
            >NAI</v-btn
          >
          <v-btn @click="deleteDialog = false" text color="primary">OXI</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      page: 1,
      totalrequests: 0,
      requests: [],
      options: {},
      query: "",
      searched: null,
      deleteDialog: null,
      itemToDelete: {},
      years: [],

      unit: null,
      municipality: null,
      year: null,
      kae: null,
      title: null,

      customSearch: false,
    };
  },
  created() {
    this.$store
      .dispatch("test", 1)
      .then((data) => {
        this.requests = data.items;
        this.totalrequests = data.total;
        this.loading = false;

        return this.$store.dispatch("getNewRequestData");
      })
      .then(() => {
        this.getYears();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    pages() {
      return Math.ceil(this.totalrequests / 20);
    },
  },
  methods: {
    thousands_separators(num) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    formatProtocol(item) {
      const d = new Date(item.protocolNumberDate);
      const greekDate =
        d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
      return item.protocolNumber + "/" + greekDate;
    },
    edit(item) {
      this.$store
        .dispatch("setRequestEdit", item)
        .then((res) => {
          // console.log(res);
          this.$router.push("/requests/edit-request");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    remove(item) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/job/request/delete/${item.id}`
        )
        .then((res) => {
          // console.log(res);
          this.$store
            .dispatch("test", this.page)
            .then((data) => {
              this.requests = data.items;
              this.totalrequests = data.total;
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updatePagination(pagination) {
      // this.page = pagination.page;
      this.page = pagination.page;
      // console.log("page to search", this.page);
      // console.log("query to serarch", this.query);

      if (!this.searched) {
        this.$store
          .dispatch("test", this.page)
          .then((data) => {
            this.requests = data.items;
            this.totalrequests = data.total;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$store
          .dispatch("searchRequest", {
            query: this.query,
            municipality: this.municipality,
            unit: this.unit,
            year: this.year,
            page: this.page,
            kae: this.kae,
          })
          .then((res) => {
            // console.log(res);
            this.requests = res.data.data.documents;
            this.totalrequests = res.data.data.resultsCounter;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getYears() {
      // console.log(moment().year());

      for (let i = moment().year(); i >= moment().year() - 10; i--) {
        this.years.push(i);
      }
    },
  },
};
</script>


<style scoped>
.vl {
  border-left: 2px solid grey;
  height: 100%;
}

.title {
  width: 40%;
}
</style>