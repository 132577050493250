<template>
  <v-snackbar v-model="snackbar.open">
    {{ snackbar.text }}

    <template #action>
      <v-btn
        text
        icon
        small
        color="pink"
        @click="$store.commit('toggleSnackbar', { open: false })"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar,
    }),
  },
};
</script>
