import axios from "axios";

//const axios = require('axios');

export default {
    state: {
        token: localStorage.getItem('perifereiaJwtToken') || '',
        refresher: null,
        user: JSON.parse(localStorage.getItem('perifereiaUser')) || null
    },
    getters: {
        isAuth: state => !!state.token
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user
            localStorage.setItem('perifereiaUser', JSON.stringify(user))
        },
        logout(state) {
            state.token = '';
            localStorage.removeItem('perifereiaUser')
            state.user = null

        }
    },
    actions: {
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true);
                axios.post(`${process.env.VUE_APP_BASEURL}/login`, { ...credentials })
                    .then(res => {
                        const token = res.data.token;
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        localStorage.setItem('perifereiaJwtToken', token);
                        commit('setToken', token.toString());
                        commit('setLoading', false);

                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/user/profile`)
                    })

                    .then(res => {
                        commit('setUser', res.data.data)
                        resolve();
                    })
                    .catch((err) => {
                        localStorage.removeItem('perifereiaJwtToken');
                        commit('logout');
                        delete axios.defaults.headers.common['Authorization'];
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        refresh({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/refresh`)
                    .then(res => {
                        const token = res.data.token;
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        localStorage.setItem('perifereiaJwtToken', token);
                        commit('setToken', token);
                        resolve(res)
                    })
                    .catch(err => {
                        localStorage.removeItem('perifereiaJwtToken');
                        commit('logout');
                        delete axios.defaults.headers.common['Authorization'];
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('perifereiaJwtToken');
                delete axios.defaults.headers.common['Authorization']
                resolve();
            })
        }
    }
}