<template>
  <div>
    <v-breadcrumbs
      :items="[{ text: 'ΠΕΕ' }, { text: 'ΑΝΑΦΟΡΕΣ' }]"
      divider=">"
    ></v-breadcrumbs>
    <!-- <v-row justify="center" class="my-12">
            <h1 class="display-1">ΑΝΑΦΟΡΕΣ</h1>
        </v-row> -->
    <!-- <v-row justify="center">
            <v-btn @click="$router.push('/')" dark color="primary darken-2"> Αρχικη σελιδα</v-btn>
        </v-row> -->
    <v-row justify="center" class="mt-5">
      <v-col cols="3">
        <v-autocomplete
          :items="array"
          label="Επιλέξτε αναφορά"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="ml-10">
        <v-btn
          @click="$router.push('/reports/test')"
          block
          class="primary darken-1"
          tile
          >Ανοιγμα</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: [
        "Αναλυτική ανά είδος έργου",
        "Αναλυτική ανά ΠΕ και Δήμο",
        "Επισκόπηση ανά ΠΕ",
        "Επισκόπηση ανά είδος έργου",
      ],
    };
  },
};
</script>