<template>
  <div>
    <v-breadcrumbs
      :items="[
        { text: 'ΠΕΕ' },
        {
          text: 'ΕΡΓΑ',
          disabled: false,
          link: true,
          exact: true,
          to: '/projects',
        },
        { text: 'ΝΕΟ ΕΡΓΟ' },
      ]"
      divider=">"
    ></v-breadcrumbs>
    <v-form @submit.prevent="submit">
      <v-row justify="center">
        <v-col cols="5">
          <v-text-field label="ΚΑΕ" v-model="kae"></v-text-field>

          <v-text-field
            label="Τίτλος*"
            :error-messages="titleErrors"
            v-model="title"
          ></v-text-field>

          <v-autocomplete
            label="Περιφερειακή ενότητα/τομέας*"
            item-value="id"
            :error-messages="unitIdErrors"
            item-text="name"
            :items="this.$store.state.projects.units"
            v-model="unitId"
          ></v-autocomplete>

          <v-autocomplete
            label="Φάση έργου αρχή έτους"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.phases"
            v-model="newYearJobPhaseId"
          ></v-autocomplete>

          <v-autocomplete
            label="Δήμος"
            item-value="id"
            item-text="name"
            :items="availableMunicipalities"
            v-model="municipalityId"
          ></v-autocomplete>

          <v-autocomplete
            label="Φάση έργου*"
            item-value="id"
            :error-messages="jobPhaseIdErrors"
            item-text="name"
            :items="this.$store.state.projects.phases"
            v-model="jobPhaseId"
          ></v-autocomplete>

          <v-autocomplete
            label="Μελέτη"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.researches"
            v-model="jobResearchId"
          ></v-autocomplete>

          <v-autocomplete
            label="Είδος έργου*"
            :error-messages="jobTypeIdErrors"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.types"
            v-model="jobTypeId"
          ></v-autocomplete>

          <!-- <v-text-field label="Θέση" v-model="place"></v-text-field> -->

          <v-autocomplete
            label="Φορέας αρμοδιότητας"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            clearable
            v-model="propertyEntity"
          ></v-autocomplete>

          <v-autocomplete
            label="Φορέας υλοποίησης"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            clearable
            v-model="implementingEntity"
          ></v-autocomplete>
          <!-- TODO -->
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-text-field
              class="mr-5"
              label="Αιτήματα που συσχετίζονται με το έργο"
              v-model="searchText"
            ></v-text-field>
            <v-btn class="mr-2" text outlined @click="search(searchText)"
              >Αναζητηση</v-btn
            >
            <v-btn text outlined @click="searchResults = []">αποκρυψη</v-btn>
          </div>

          <!-- end todo -->
        </v-col>

        <v-col cols="5" offset="1">
          <v-text-field
            label="Διευθύνουσα υπηρεσία"
            v-model="managementService"
          ></v-text-field>

          <v-text-field
            label="Προϋπολογισμός (προ δημοπράτησης)*"
            :error-messages="budgetBeforeAuctionErrors"
            v-model="budgetBeforeAuction"
            :hint="budgetBeforeAuction | currency"
            persistent-hint
          >
            <template v-slot:hint>{{
              budgetBeforeAuction | currency
            }}</template>
          </v-text-field>

          <v-text-field
            label="Πιστώσεις*"
            v-model="credits"
            :hint="credits | currency"
            :error-messages="creditsErrors"
            persistent-hint
          ></v-text-field>
          <!-- <v-text-field label="Ανάδοχος" class="mt-4" v-model="contractor"></v-text-field> -->

          <v-autocomplete
            label="Κατηγορία έργου*"
            item-value="id"
            :error-messages="jobCategoryIdErrors"
            item-text="name"
            :items="this.$store.state.projects.categories"
            v-model="jobCategoryId"
          ></v-autocomplete>

          <!-- <v-text-field label="Αντισυμβαλλόμενοι" v-model="jobPartners"></v-text-field> -->

          <v-autocomplete
            label="Άξονας"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.axis"
            v-model="jobAxisId"
          ></v-autocomplete>
          <v-autocomplete
            label="Μέτρο"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.measures"
            v-model="jobMeasureId"
          ></v-autocomplete>
          <v-autocomplete
            label="Στόχος"
            item-value="id"
            item-text="name"
            :items="this.$store.state.projects.goals"
            v-model="jobGoalId"
          ></v-autocomplete>

          <!-- <v-text-field label="Φορέας χρηματοδότησης" v-model="fundingEntity"></v-text-field> -->
          <v-text-field label="Ένταξη στο ΠΕΕ" v-model="joinPee"></v-text-field>

          <v-row>
            <v-col>
              <v-file-input chips multiple label="Αρχεία" v-model="files">
                <template v-slot:selection="{ index, text }">
                  <v-chip close @click:close="deleteChip(index, text)">{{
                    text
                  }}</v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- TODO: change file implementation -->
              <v-btn :disabled="files.length < 1" @click="upload"
                >Αρχειοθετηση</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <h1 class="title">Αρχεία</h1>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    hide-default-footer
                    :headers="fileHeaders"
                    :items="peeJobAttachments"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="deleteFile(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10">
          <v-card v-if="selected.length > 0">
            <v-card-title>
              <h3 class="headline">
                ΕΠΙΛΕΓΜΕΝΑ ΑΙΤΗΜΑΤΑ ΠΟΥ ΣΧΕΤΙΖΟΝΤΑΙ ΜΕ ΤΟ ΕΡΓΟ
              </h3>
            </v-card-title>
            <v-card-text>
              <v-simple-table dense class="my-2">
                <thead>
                  <tr>
                    <th>ενεργειες</th>
                    <th>ΤΙΤΛΟΣ</th>
                    <th>ΤΟΜΕΑΣ</th>
                    <th>ΔΗΜΟΣ</th>
                    <th>ΠΡΩΤΟΚΟΛΛΟ</th>
                    <th>ΕΡΕΥΝΑ</th>
                    <th>ΕΙΔΟΣ ΕΡΓΟΥ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in selected" :key="item.id">
                    <td>
                      <div style="display: flex">
                        <v-icon @click="seeMoreInfo(item)"
                          >mdi-information</v-icon
                        >
                        <v-icon @click="remove(item)" class="ml-3"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.unit ? item.unit.name : "" }}</td>
                    <td>
                      {{ item.municipality ? item.municipality.name : "" }}
                    </td>
                    <td>{{ item.protocolNumber }}</td>
                    <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>
                    <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-divider v-if="selected.length > 0"></v-divider>

          <v-card class="mt-10" v-if="searchResults.length > 0">
            <v-card-title>ΑΠΟΤΕΛΕΣΜΑΤΑ</v-card-title>
            <v-card-text>
              <v-simple-table class="my-2">
                <thead>
                  <tr>
                    <th>επιλογη</th>
                    <th>ΤΙΤΛΟΣ</th>
                    <th>ΤΟΜΕΑΣ</th>
                    <th>ΔΗΜΟΣ</th>
                    <th>ΠΡΩΤΟΚΟΛΛΟ</th>
                    <th>ΕΡΕΥΝΑ</th>
                    <th>ΕΙΔΟΣ ΕΡΓΟΥ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in searchResults" :key="item.id">
                    <td>
                      <v-icon
                        v-if="check(item) && selected.length < 1"
                        @click="add(item)"
                        >mdi-plus</v-icon
                      >
                    </td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.unit ? item.unit.name : "" }}</td>
                    <td>
                      {{ item.municipality ? item.municipality.name : "" }}
                    </td>
                    <td>{{ item.protocolNumber }}</td>
                    <td>{{ item.jobResearch ? item.jobResearch.name : "" }}</td>
                    <td>{{ item.jobType ? item.jobType.name : "" }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>

            <div
              style="
                display: flex;
                background-color: white;
                padding-top: 50px;
                padding-bottom: 40px;
              "
            >
              <v-icon
                v-if="page > 1"
                @click="
                  page -= 1;
                  search(searchText);
                "
                class="ml-12 pl-12"
                >mdi-arrow-left</v-icon
              >
              <v-spacer></v-spacer>
              <v-icon
                v-if="page < pages"
                @click="
                  page += 1;
                  nextSearch(searchText);
                "
                class="mr-12 pr-12"
                >mdi-arrow-right</v-icon
              >
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="11">
          <v-textarea
            outlined
            label="Παρατηρήσεις"
            v-model="comments"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row justify="center" class="mt-10">
        <v-btn
          width="30%"
          dark
          tile
          color="primary darken-2"
          :loading="$store.state.loading"
          type="submit"
          >Αποθηκευση</v-btn
        >
      </v-row>
    </v-form>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>ΛΕΠΤΟΜΕΡΕΙΕΣ</v-card-title>
        <v-card-text>
          <v-text-field
            disabled
            label="ΤΙΤΛΟΣ"
            :value="detailedItem ? detailedItem.title : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΕΙΔΟΣ ΕΡΓΟΥ"
            :value="detailedItem.jobType ? detailedItem.jobType.name : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΕΡΕΥΝΑ"
            :value="
              detailedItem.jobResearch ? detailedItem.jobResearch.name : ''
            "
          ></v-text-field>
          <!-- <v-text-field disabled label="ΘΕΣΗ" :value="detailedItem ? detailedItem.place : ''"></v-text-field> -->
          <v-text-field
            disabled
            label="ΤΟΜΕΑΣ"
            :value="detailedItem.unit ? detailedItem.unit.name : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΔΗΜΟΣ"
            :value="
              detailedItem.municipality ? detailedItem.municipality.name : ''
            "
          ></v-text-field>
          <v-text-field
            disabled
            label="ΑΡΙΘΜΟΣ ΠΡΩΤΟΚΟΛΛΟΥ"
            :value="detailedItem ? detailedItem.protocolNumber : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΗΜΕΡΟΜΗΝΙΑ ΠΡΩΤΟΚΟΛΛΟΥ"
            :value="detailedItem ? detailedItem.protocolNumberDate : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ"
            :value="detailedItem ? detailedItem.budget : ''"
          ></v-text-field>
          <v-text-field
            disabled
            label="ΠΑΡΑΤΗΡΗΣΕΙΣ"
            :value="detailedItem ? detailedItem.comments : ''"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
export default {
  //mixins: [validationMixin],
  data() {
    return {
      valid: true,
      requireRule: [(v) => !!v || "Υποχρεωτικό πεδίο"],

      dialog: null,
      detailedItem: {},

      budgetBeforeAuction: null,
      credits: null,
      comments: "",
      // contractor: "",
      implementingEntity: "",
      // jobPartners: "",
      kae: "",
      managementService: "",
      // place: "",
      propertyEntity: "",
      title: "",
      unitId: "",
      jobCategoryId: "",
      jobPhaseId: "",
      jobResearchId: "",
      jobTypeId: "",
      jobAxisId: "",
      jobMeasureId: "",
      jobGoalId: "",
      newYearJobPhaseId: "",
      municipalityId: "",
      // fundingEntity: "",
      joinPee: "",

      searchText: "",
      searchResults: [],
      selected: [],
      page: 1,

      peeJobAttachments: [],

      fileHeaders: [
        {
          text: "Όνομα",
          value: "filename",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      files: [],

      resultsCounter: 0,
    };
  },

  created() {
    this.$store
      .dispatch("getNewPeeJobData")
      .then(() => {
        console.log("done");
        console.log(this.$store.state.settings.units);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    upload() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store.dispatch("uploadFiles", formData).then((res) => {
        console.log(res);
        res.data.data.forEach((item) => {
          this.peeJobAttachments.push(item);
        });
        this.files = [];
      });
    },
    what() {
      const temp = this.budgetBeforeAuction.split(".").join("");
      const ready = new Intl.NumberFormat("de-DE").format(
        temp.replace(",", ".")
      );
      console.log("ready", ready);
      //this.budgetBeforeAuction = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val.replace(',','.'))
      this.budgetBeforeAuction = ready;

      console.log("budget", this.budgetBeforeAuction);
    },
    please(ready) {
      this.budgetBeforeAuction = ready;
    },
    add(item) {
      console.log(item);
      if (!this.selected.some((e) => e.id == item.id)) {
        this.selected.push(item);

        this.title = item.title;
        this.comments = item.comments;
        this.unitId = item.unit.id;
        this.municipalityId = item.municipality.id;
        this.jobTypeId = item.jobType.id;
        this.budgetBeforeAuction = item.budget;

        return true;
      }
    },
    check(item) {
      if (!this.selected.some((e) => e.id == item.id)) {
        return true;
      }
    },
    remove(item) {
      for (let i in this.selected) {
        if (this.selected[i] == item) {
          this.selected.splice(i, 1);
          break;
        }
      }
    },
    seeMoreInfo(item) {
      this.detailedItem = item;
      this.dialog = true;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let jobRequestsIds = [];
        for (let item in this.selected) {
          jobRequestsIds.push(this.selected[item].id);
        }

        console.log("idz", jobRequestsIds);

        const peeJobParameters = {
          budgetBeforeAuction: parseFloat(this.budgetBeforeAuction),
          credits: parseFloat(this.credits),
          comments: this.comments,
          // contractor: this.contractor,
          implementingEntity: this.implementingEntity,
          jobAxisId: parseInt(this.jobAxisId),
          jobCategoryId: parseInt(this.jobCategoryId),
          jobGoalId: parseInt(this.jobGoalId),
          jobMeasureId: parseInt(this.jobMeasureId),
          // jobPartners: this.jobPartners,
          jobPhaseId: parseInt(this.jobPhaseId),
          municipalityId: parseInt(this.municipalityId),
          //todo
          jobRequestId: jobRequestsIds.length > 0 ? jobRequestsIds[0] : null,
          //end todo
          jobResearchId: parseInt(this.jobResearchId),
          jobTypeId: parseInt(this.jobTypeId),
          kae: this.kae,
          managementService: this.managementService,
          newYearJobPhaseId: parseInt(this.newYearJobPhaseId),
          // place: this.place,
          propertyEntity: this.propertyEntity,
          title: this.title,
          unitId: this.unitId,
          // fundingEntity: this.fundingEntity,
          joinPee: this.joinPee,

          peeJobAttachmentsIds: this.peeJobAttachmentsIds,
        };
        this.$store
          .dispatch("newPeeProject", peeJobParameters)
          .then((res) => {
            console.log(res);
            if (res.data.status == 0) {
              this.$router.go(-1);
              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });
            } else {
              this.$store.dispatch("errorHandler", res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });
          });
      }
    },
    search(text) {
      this.page = 1;
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/search/job/request?query=${text}&page=${this.page}`
        )
        .then((res) => {
          console.log("searchRes", res);
          this.searchResults = res.data.data.documents;
          this.resultsCounter = res.data.data.resultsCounter;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextSearch(text) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/search/job/request?query=${text}&page=${this.page}`
        )
        .then((res) => {
          console.log("searchRes", res);
          this.searchResults = res.data.data.documents;
          this.resultsCounter = res.data.data.resultsCounter;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  //  watch:{
  //   budgetBeforeAuction(val) {
  //     const ready =  new Intl.NumberFormat('de-DE').format(val)
  //     console.log('ready', ready)
  //    //this.budgetBeforeAuction = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val.replace(',','.'))
  //    this.budgetBeforeAuction = ready

  //    console.log('budget',this.budgetBeforeAuction)
  //   }
  // },
  computed: {
    availableMunicipalities() {
      return this.$store.state.requests.municipalities.filter(
        (municipality) => {
          if (municipality.unit != null)
            if (municipality.unit.id == this.unitId) return municipality;
        }
      );
    },

    entitiesAndMunicipalities() {
      return this.$store.state.requests.municipalities.concat(
        this.$store.state.requests.entities
      );
    },
    budgetBeforeAuctionOnlyNumbers() {
      let temp = this.budgetBeforeAuction.replace(",", "");
      return temp.replace(".", "");
    },
    pages() {
      return Math.ceil(this.resultsCounter / 20);
    },

    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      if (!this.$v.title.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    unitIdErrors() {
      const errors = [];
      if (!this.$v.unitId.$dirty) return errors;
      if (!this.$v.unitId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobPhaseIdErrors() {
      const errors = [];
      if (!this.$v.jobPhaseId.$dirty) return errors;
      if (!this.$v.jobPhaseId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    jobTypeIdErrors() {
      const errors = [];
      if (!this.$v.jobTypeId.$dirty) return errors;
      if (!this.$v.jobTypeId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    budgetBeforeAuctionErrors() {
      const errors = [];
      if (!this.$v.budgetBeforeAuction.$dirty) return errors;
      if (!this.$v.budgetBeforeAuction.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.budgetBeforeAuction.decimal)
        errors.push("Πρέπει να είνια αριθμός");
      return errors;
    },
    jobCategoryIdErrors() {
      const errors = [];
      if (!this.$v.jobCategoryId.$dirty) return errors;
      if (!this.$v.jobCategoryId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    creditsErrors() {
      const errors = [];
      if (!this.$v.credits.$dirty) return errors;
      if (!this.$v.credits.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.credits.decimal) errors.push("Πρέπει να είναι αριθμός");
      return errors;
    },

    peeJobAttachmentsIds() {
      if (this.peeJobAttachments.length == 0) return [];
      return this.peeJobAttachments.map((item) => item.id);
    },
  },

  validations: {
    title: { required },
    unitId: { required },
    jobPhaseId: { required },
    jobTypeId: { required },
    budgetBeforeAuction: { required, decimal },
    jobCategoryId: { required },
    credits: { decimal, required },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}
</style>
