import Vue from 'vue';
import Vuex from 'vuex';
import requests from './requests';
import projects from './projects';
import settings from './settings'
import errors from './errors';
import auth from './auth';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    snackbar: {
      open: false,
      text: '',
    }
  },
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    toggleSnackbar(state, { open = true, text }) {
      state.snackbar = { open, text };
    },
  },
  actions: {
    uploadFiles({ commit }, formData) {
      commit('setLoading', false);
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_BASEURL}/api/repository/file/upload`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  modules: {
    requests,
    projects,
    settings,
    errors,
    auth,
  },
});
