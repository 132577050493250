import axios from 'axios';
export default {
    state: {
        jobTypes: [],
        municipalities: [],
        units: [],
        status: [],
        research: [],
        requestEdit: {},
        entities: []
    },
    mutations: {
        setJobTypes(state, payload) {
            state.jobTypes = payload;
        },
        setMunicipalities(state, payload) {
            state.municipalities = payload;
        },
        setUnits(state, payload) {
            state.units = payload;
        },
        setStatus(state, payload) {
            state.status = payload;
        },
        setResearch(state, payload) {
            state.research = payload;
        },
        setRequestEdit(state, payload) {
            state.requestEdit = payload;
            console.log(state.requestEdit);
        },
        setEntities(state, payload){
            state.entities = payload
        }
    },
    actions: {
        getNewRequestData({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/type/list`)
                    .then(res => {
                        commit('setJobTypes', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/municipality/list`);
                    })
                    .then(res => {
                        commit('setMunicipalities', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/unit/list`);
                    })
                    .then(res => {
                        commit('setUnits', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/request/status/list`);
                    })
                    .then(res => {
                        commit('setStatus', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/research/list`);
                    })
                    .then(res => {
                        commit('setResearch', res.data.data);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/entity/organization/list`)
                     //  resolve();
                    })
                    .then(res => {
                        commit('setEntities', res.data.data)
                        resolve()
                    })
                    .catch(err => {
                        
                        reject(err);
                    });

            })
        },
        newRequest({ commit }, credentials) {
            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_BASEURL}/api/job/request/add`, { ...credentials })
                    .then(res => {
                        commit('setLoading', false);
                        console.log('in promise', res);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        console.log(err);
                        reject();
                    });
            });
        },
        test({ commit }, id) {
            commit('setLoading', true);
            let data = {};
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/job/request/list/counter`)
            
                    .then(res => {
                        data.total = res.data.data;
                        commit('setLoading',false);
                        return axios.get(`${process.env.VUE_APP_BASEURL}/api/job/request/list/paging?page=${id}`);

                    })
                    .then(res => {
                        data.items = res.data.data;
                        commit('setLoading', false);
                        resolve(data);
                    })
                    .catch(err => {
                        console.log(err);
                        commit('setLoading', false);
                        reject();
                    })
            })
        },
        
        setRequestEdit({ commit }, credentials) {
            commit('setRequestEdit', credentials);
        },
        editRequest({commit}, credentials){
            commit('setLoading', true);
            console.log(credentials.credentials)
            return new Promise((resolve, reject) => {
                axios.put(`${process.env.VUE_APP_BASEURL}/api/job/request/edit/${credentials.id}`,credentials.credentials)
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    })
            })
        },
        searchRequest({commit}, credentials){
            commit('setLoading', true);
            return new Promise((resolve,reject) => {
                axios.get(`${process.env.VUE_APP_BASEURL}/api/search/job/request`, {
                    params: {
                        query: credentials.query,
                        page: credentials.page,
                        unit: credentials.unit,
                        municipality: credentials.municipality,
                        year: credentials.year
                    }
                })
                    .then(res => {
                        commit('setLoading', false);
                        resolve(res);
                    })
                    .catch(err => {
                        commit('setLoading', false);
                        reject(err);
                    });
            });
        }
    }
    
}