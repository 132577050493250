<template>
  <div>
    <v-breadcrumbs
      :items="[{ text: 'ΠΕΕ' }, { text: 'ΝΕΟ ΕΡΓΟ' }]"
      divider=">"
    ></v-breadcrumbs>
    <v-form @submit.prevent="submit" ref="form">
      <v-row>
        <v-col cols="6" offset="1">
          <v-text-field
            v-model="year"
            label="ΕΤΟΣ*"
            :rules="rules"
          ></v-text-field>
          <v-autocomplete
            v-model="serialNumber"
            label="Α/Α*"
            :items="serialNumbers"
            :rules="rules"
          ></v-autocomplete>
          <v-text-field
            v-model="pesyDecisionNumber"
            label="Απόφαση Περιφερειακού Συμβουλίου*"
            :rules="rules"
          ></v-text-field>

          <!-- <v-file-input v-model="files" label="Άλλα αρχεία" multiple prepend-icon="mdi-paperclip">
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>-->

          <v-card>
            <v-card-title>ΕΚΚΡΕΜΕΙΣ ΤΡΟΠΟΠΟΙΗΣΕΙΣ*</v-card-title>
            <v-card-text>
              <p @click="select">Επιλογή όλων</p>
              <div v-for="item in items" :key="item.id">
                <v-checkbox
                  :label="item.title"
                  :value="item"
                  v-model="selected"
                  :error-messages="selectedErrors"
                ></v-checkbox>
              </div>
            </v-card-text>
          </v-card>

          <v-file-input chips multiple label="Αρχεία" v-model="files">
            <template v-slot:selection="{ index, text }">
              <v-chip close @click:close="deleteChip(index, text)">{{
                text
              }}</v-chip>
            </template>
          </v-file-input>
          <v-btn :disabled="files.length < 1" @click="upload"
            >αρχειοθετηση</v-btn
          >

          <v-card class="my-5">
            <v-card-title>
              <h1 class="title">Αρχεία</h1>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :items="peeAttachments"
                :headers="headers"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div class="d-flex align-center">
                    <v-btn icon @click="deleteFile(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="download(item)"></v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <v-textarea
            class="mt-5"
            v-model="comments"
            outlined
            label="Παρατηρησεις"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row justify="center">
        <v-col cols="2">
          <v-btn
            :loading="$store.state.loading"
            class="primary darken-1 mt-5"
            block
            @click="submit"
            >αποθηκευση</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const axios = require("axios");
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  created() {
    axios
      .get(
        `${process.env.VUE_APP_BASEURL}/api/pee/job/list/with/pending/modifications`
      )
      .then((res) => {
        this.items = res.data.data;

        return axios.get(
          `${process.env.VUE_APP_BASEURL}/api/pee/list/${moment().year()}`
        );
      })
      .then((res) => {
        console.log(res);
        res.data.data.forEach((sn) => {
          for (let i = 0; i < this.serialNumbers.length; i++) {
            if (this.serialNumbers[i] == sn.serialNumber) {
              this.serialNumbers.splice(i, 1);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      items: [],
      selected: [],
      isAllChecked: false,
      year: "",
      comments: "",
      pesyDecisionNumber: "",
      serialNumber: "",
      peeAttachments: [],

      files: [],

      headers: [
        {
          text: "Όνομα",
          value: "filename",
        },
        {
          text: "",
          value: "actions",
        },
      ],

      serialNumbers: [
        "Α",
        "Β",
        "Γ",
        "Δ",
        "Ε",
        "ΣΤ",
        "Ζ",
        "Η",
        "Θ",
        "Ι",
        "Κ",
        "Λ",
        "Μ",
        "Ν",
        "Ξ",
        "Ο",
        "Π",
        "Ρ",
        "Σ",
        "Τ",
        "Υ",
        "Φ",
        "Χ",
        "Ψ",
        "Ω",
        "ΑΑ",
        "ΑΒ",
        "ΑΓ",
        "ΑΔ",
        "ΑΕ",
        "ΑΖ",
        "ΑΗ",
        "ΑΘ",
        "ΑΙ",
        "ΑΚ",
        "ΑΛ",
        "ΑΜ",
        "ΑΝ",
        "ΑΞ",
        "ΑΟ",
        "ΑΠ",
      ],
      rules: [(v) => !!v || "Υποχρεωτικό πεδίο"],
    };
  },

  validations: {
    selected: { required },
  },

  methods: {
    select() {
      if (this.isAllChecked) {
        this.selected = [];
      } else {
        this.selected = this.items;
      }
      this.isAllChecked = !this.isAllChecked;
    },
    submit() {
      this.$v.$touch();

      if (this.$refs.form.validate() && !this.$v.$invalid) {
        const credentials = {
          comments: this.comments,
          peeAttachmentsIds: this.peeAttachmentsIds,
          peeJobsIds: this.peeJobsIds,
          pesyDecisionNumber: this.pesyDecisionNumber,
          serialNumber: this.serialNumber,
          year: parseInt(this.year),
        };
        // axios.post(`${process.env.VUE_APP_BASEURL}/api/pee/add`, credentials)
        this.$store
          .dispatch("newPee", credentials)
          .then((res) => {
            console.log(res);

            if (res.data.status === -1) {
              this.$store.dispatch("errorHandler", res.data.message);

              console.log(res.data.message);
            } else {
              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });

              this.$router.push(`/projects`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    upload() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store.dispatch("uploadFiles", formData).then((res) => {
        console.log(res);
        res.data.data.forEach((item) => {
          this.peeAttachments.push(item);
        });
        this.files = [];
      });
    },
    download(item) {
      axios
        .get(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/download/${item.id}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteFile(id) {
      axios
        .delete(
          `${process.env.VUE_APP_BASEURL}/api/repository/file/remove/${id}`,
          {
            data: {
              peeId: 0,
            },
          }
        )
        .then(() => {
          this.peeAttachments.splice(
            this.peeAttachments.findIndex((item) => item.id == id),
            1
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    selectedErrors() {
      const errors = [];
      if (!this.$v.selected.$dirty) return errors;
      if (!this.$v.selected.required) errors.push("");
      return errors;
    },

    peeJobsIds() {
      const array = this.selected.map((a) => a.id);
      return array;
    },
    peeAttachmentsIds() {
      if (this.peeAttachments.length == 0) return [];
      return this.peeAttachments.map((item) => item.id);
    },
  },
};
</script>
