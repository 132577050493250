<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems" divider=">"></v-breadcrumbs>
    <v-tabs centered show-arrows>
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.path" exact>{{
          tab.name
      }}</v-tab>
    </v-tabs>

    <router-view class="mt-6"></router-view>
  </div>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch(
      "setProjectEditing",
      this.$route.params.project_id
    );

    this.breadcrumbItems.push(
      { text: "ΠΕΕ" },
      {
        text: "ΕΡΓΑ",
        disabled: false,
        link: true,
        exact: true,
        to: "/projects",
      },

      {
        text:
          this.$store.state.projects.projectEditing.title +
          "/" +
          this.$store.state.projects.projectEditing.kae,
      }
    );
    this.tabs.push(
      {
        name: `ΣΤΟΙΧΕΙΑ ΕΡΓΟΥ`,
        path: `/projects/${this.$route.params.project_id}/edit-project`,
      },
      {
        name: `ΕΡΓΟΛΑΒΙΚΗ ΣΥΜΒΑΣΗ`,
        path: `/projects/${this.$route.params.project_id}/edit-project/ergolavikh-symbash-ergou`,
      },

      {
        name: `ΠΡΟΓΡΑΜΜΑΤΙΚΗ ΣΥΜΒΑΣΗ`,
        path: `/projects/${this.$route.params.project_id}/edit-project/programmatikh-symvash-ergou`,
      },

      {
        name: `ΔΙΑΧΕΙΡΙΣΗ ΟΙΚΟΝΟΜΙΚΩΝ ΣΤΟΙΧΕΙΩΝ`,
        path: `/projects/${this.$route.params.project_id}/edit-project/financial-details`,
      },

      {
        name: `ΙΣΤΟΡΙΚΟ ΤΡΟΠΟΠΟΙΗΣΕΩΝ`,
        path: `/projects/${this.$route.params.project_id}/edit-project/istoriko-tropopoihsewn`,
      },

      {
        name: `ΤΡΟΠΟΠΟΙΗΣΕΙΣ ΕΡΓΟΥ`,
        path: `/projects/${this.$route.params.project_id}/edit-project/tropopoihsh-stoixeiwn-ergou`,
      }
    );
  },

  data() {
    return {
      breadcrumbItems: [],
      tabs: [],
    };
  },
};
</script>

<style scoped lang="scss">
</style>