<template>
  <div>
    <v-breadcrumbs
      :items="[
        {
          text: 'ΑΙΤΗΜΑΤΑ',
          disabled: false,
          link: true,
          exact: true,
          to: '/requests',
        },
        { text: 'ΝΕΟ ΑΙΤΗΜΑ' },
      ]"
      divider=">"
    ></v-breadcrumbs>
    <v-form @submit.prevent="submit" ref="form" lazy-validation>
      <v-row class="text-center">
        <v-col>
          <h1 class="headline">Νέο Αίτημα</h1>
          <v-divider class="my-12"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="5">
          <v-autocomplete
            label="Περιφερειακή ενότητα/Τομέας*"
            :items="this.$store.state.requests.units"
            item-value="id"
            item-text="name"
            @change="municipalityId = null"
            :rules="validationRule"
            v-model="unitId"
          ></v-autocomplete>

          <!-- <v-text-field label="Τίτλος έργου" :error-messages="titleErrors" v-model="title"></v-text-field> -->
          <v-autocomplete
            label="Είδος έργου*"
            :items="this.$store.state.requests.jobTypes"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="jobTypeId"
          ></v-autocomplete>
          <!-- <v-text-field label="Θέση έργου" v-model="place"></v-text-field> -->
          <v-autocomplete
            label="­Δήμος*"
            :items="availableMunicipalities"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="municipalityId"
          ></v-autocomplete>
          <!-- 
          <v-text-field label="Φορέας/Δήμος"
          v-model="entityMunicipality"></v-text-field>

          {{availableMunicipalities.length}} -->

          <v-autocomplete
            label="Φορέας/Δήμος"
            :items="entitiesAndMunicipalities"
            item-text="name"
            item-value="name"
            clearable
            v-model="entityMunicipality"
          ></v-autocomplete>

          <v-row no-gutters>
            <v-text-field
              label="Αριθμός πρωτοκόλλου"
              v-model="protocolNumber"
              class="mr-3"
            ></v-text-field>

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateFormat(protocolNumberDate)"
                  v-on="on"
                  readonly
                  label="Ημερομηνία"
                  append-icon="mdi-calendar"
                  hint="ΗΗ/ΜΜ/ΕΕΕΕ"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="protocolNumberDate"
                locale="el-GR"
                @change="save"
              ></v-date-picker>
            </v-menu>

            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <h1 class="title">Αρχεία</h1>
                </v-card-title>
                <v-card-text>
                  <v-file-input chips multiple label="Αρχεία" v-model="files">
                    <template v-slot:selection="{ index, text }">
                      <v-chip close @click:close="deleteChip(index, text)">{{
                        text
                      }}</v-chip>
                    </template>
                  </v-file-input>

                  <v-btn :disabled="files.length < 1" @click="upload"
                    >Αρχειοθετηση</v-btn
                  >

                  <v-data-table
                    hide-default-footer
                    :headers="fileHeaders"
                    :items="requestAttachments"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn icon @click="deleteFile(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5" offset="0">
          <v-text-field
            label="Τίτλος αιτήματος*"
            :rules="validationRule"
            v-model="title"
          ></v-text-field>

          <v-autocomplete
            label="Έχει ενταχθεί;*"
            :items="this.$store.state.requests.status"
            item-value="id"
            item-text="name"
            :rules="validationRule"
            v-model="jobRequestStatusId"
          ></v-autocomplete>
          <v-text-field
            label="Προϋπολογισμός"
            :hint="budget | currency"
            persistent-hint
            v-model="budget"
            type="number"
          >
          </v-text-field>

          <v-autocomplete
            label="Κατάσταση μελέτης"
            :items="this.$store.state.requests.research"
            item-value="id"
            item-text="name"
            class="mt-4"
            v-model="jobResearchId"
          ></v-autocomplete>

          <!-- <v-text-field label="Κατάσταση" v-model="katastash"></v-text-field> -->
          <!-- <v-file-input v-model="files" label="Άλλα αρχεία" multiple prepend-icon="mdi-paperclip">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">{{ text }}</v-chip>
            </template>
          </v-file-input>-->
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10">
          <v-textarea
            outlined
            label="Παρατηρήσεις"
            v-model="comments"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row justify="center" class="mt-5">
        <v-btn width="20%" dark tile color="primary darken-2" @click="submit">
          <span v-if="!this.$store.state.loading">Αποθηκευση</span>
          <span v-else>
            <v-progress-circular :size="30" indeterminate></v-progress-circular>
          </span>
        </v-btn>

        <v-btn
          class="ml-2"
          width="20%"
          dark
          tile
          color="primary darken-2"
          @click="$router.go(-1)"
          >ακυρο</v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      hi: "",
      unitId: "",
      jobTypeId: "",
      municipalityId: "",
      entityMunicipality: "",
      jobRequestStatusId: "",
      comments: "",
      budget: "",
      // place: "",
      protocolNumber: "",
      protocolNumberDate: "",
      jobResearchId: "",
      title: "",
      menu: false,
      validationRule: [(v) => !!v || "Υποχρεωτικό πεδίο"],
      requestAttachments: [],

      fileHeaders: [
        {
          text: "Όνομα",
          value: "filename",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      files: [],
    };
  },
  created() {
    this.$store
      .dispatch("getNewRequestData")
      .then((res) => {
        console.log(res);
      })
      .catch(() => {});
  },
  computed: {
    // greekFormatDate() {
    //   if (this.protocolNumberDate == null) {
    //     return "";
    //   } else {
    //     const d = new Date(this.protocolNumberDate);
    //     return (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + "/" + (d.getMonth() + 1 < 10 ? '0' +(d.getMonth() + 1) : d.getMonth() + 1) + "/" + d.getFullYear();
    //   }
    // },
    entitiesAndMunicipalities() {
      return this.$store.state.requests.municipalities.concat(
        this.$store.state.requests.entities
      );
    },
    availableMunicipalities() {
      return this.$store.state.requests.municipalities.filter(
        (municipality) => {
          if (municipality.unit != null)
            if (municipality.unit.id == this.unitId) return municipality;
        }
      );
    },
    requestAttachmentsIds() {
      if (this.requestAttachments.length == 0) return [];
      return this.requestAttachments.map((item) => item.id);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    dateFormat(date) {
      if (!date) return;
      return moment(date).format("DD/MM/YYYY");
    },

    onChange(event) {
      event.target.files.forEach((file) => {
        this.files.push(file);
        console.log(file);
      });
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
    deleteChip(index) {
      // Prompt here with text if required
      this.files.splice(index, 1);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    submit() {
      if (this.$refs.form.validate()) {
        const credentials = {
          budget: parseInt(this.budget),
          comments: this.comments,
          jobRequestStatusId: parseInt(this.jobRequestStatusId),
          jobTypeId: parseInt(this.jobTypeId),
          jobResearchId: parseInt(this.jobResearchId),
          municipalityId: parseInt(this.municipalityId),
          entityMunicipality: this.entityMunicipality,
          // place: this.place,
          protocolNumber: this.protocolNumber,
          protocolNumberDate: this.protocolNumberDate,
          title: this.title,
          unitId: parseInt(this.unitId),
          requestAttachmentsIds: this.requestAttachmentsIds,
        };
        this.$store
          .dispatch("newRequest", credentials)
          .then((res) => {
            if (res.data.status == 0) {
              this.$router.go(-1);
              this.$store.commit("toggleDialog", {
                open: true,
                text: "Επιτυχία",
              });
            } else {
              this.$store.dispatch("errorHandler", res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });
          });
      }
    },
    upload() {
      let formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
      this.$store
        .dispatch("uploadFiles", formData)
        .then((res) => {
          console.log(res);
          res.data.data.forEach((item) => {
            this.requestAttachments.push(item);
          });
          this.files = [];
          this.$store.commit("toggleDialog", {
            open: true,
            text: "Επιτυχία",
          });
        })
        .catch((e) => {
          this.$store.commit("toggleSnackbar", {
            open: true,
            text: "Αποτυχία",
          });
        });
    },

    deleteFile(id) {
      this.requestAttachments.splice(
        this.requestAttachments.findIndex((item) => item.id == id),
        1
      );
      this.$store.commit("toggleDialog", {
        open: true,
        text: "Επιτυχία",
      });
    },
  },
};
</script>
