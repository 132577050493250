<template>
  <div>
    <v-row justify="center" v-if="pageLoader">
      <v-progress-circular
        size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-row v-else justify="center" class="mb-10">
      <v-btn
        v-if="!addingDialog"
        class="primary darken-2 mr-4"
        @click="addingDialog = true"
        >Προσθηκη</v-btn
      >
    </v-row>
    <v-simple-table dense v-if="!pageLoader">
      <thead>
        <tr>
          <th>
            <p></p>
          </th>
          <th>
            <p class="headline font-weight-bold">Έτος</p>
          </th>

          <th>
            <p>Προϋπολογισμός προ δημοπράτησης</p>
          </th>
          <th>
            <p>Μέση έκπτωση</p>
          </th>
          <th>
            <p>Εργολαβική σύμβαση</p>
          </th>
          <th>
            <p>Τελευταίος ΑΠΕ</p>
          </th>
          <th>
            <p>Συμβατικό αντικείμενο</p>
          </th>
          <th>
            <p>Εγκεκριμένος προϋπολογισμός (νομική δέσμευση)</p>
          </th>
          <th>
            <p>Προτεινόμενος προϋπολογισμός (νομική δέσμευση)</p>
          </th>
          <th>
            <p>Σύνολο πληρωμών προηγούμενης χρονιάς</p>
          </th>
          <th>
            <p>Υπόλοιπο προϋπολογισμού (Ν/Δ) (Αρχή έτους)</p>
          </th>
          <th>
            <p>(Ανεκτέλεστο) Υπόλοιπο συμβατικού αντικειμένου (Αρχή έτους)</p>
          </th>
          <th>
            <p>Εγκεκριμένες πιστώσεις</p>
          </th>
          <th>
            <p>Προτεινόμενες πιστώσεις</p>
          </th>
          <th>
            <p>Πληρωμές</p>
          </th>
          <th>
            <p>Τιμολογήσεις</p>
          </th>
          <th>
            <p>Τρέχον υπόλοιπο προϋπολογισμού</p>
          </th>
          <th>
            <p>Τρέχον υπόλοιπο συμβατικού αντικειμένου</p>
          </th>
          <th>
            <p>Τρέχον υπόλοιπο πιστώσεων</p>
          </th>
          <th>
            <p>Προτεινόμενες πιστώσεις για ΠΕΕ νέου έτους</p>
          </th>
          <th>
            <p>Τροποποίηση πιστώσεων</p>
          </th>
          <th>
            <p>Προβλεπόμενες πληρωμές τρέχοντος έτους</p>
          </th>
          <th>
            <p>Αιτιολογία τροποποίησης</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in details" :key="item.id">
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="onEditItem(item)"
                  v-on="on"
                  v-if="item.year >= currentYear.getFullYear()"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>
          </td>
          <td class="font-weight-bold">{{ item.year }}</td>

          <td v-if="$store.state.projects.projectEditing != null">
            {{
              thousands_separators(
                $store.state.projects.projectEditing.budgetBeforeAuction
              )
            }}
          </td>
          <td v-if="$store.state.projects.projectEditing != null">
            {{
              thousands_separators(
                $store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .meanDiscount
                  : null
              )
            }}
          </td>
          <td v-if="$store.state.projects.projectEditing != null">
            {{
              thousands_separators(
                $store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .amount
                  : null
              )
            }}
          </td>
          <td v-if="$store.state.projects.projectEditing != null">
            {{
              thousands_separators(
                $store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .lastApe
                  : null
              )
            }}
          </td>
          <td>{{ thousands_separators(item.conventionalObject) }}</td>
          <td>{{ thousands_separators(item.confirmedBudget) }}</td>
          <td>{{ thousands_separators(item.suggestedBudget) }}</td>
          <td>{{ thousands_separators(item.previousYearTotalPayments) }}</td>
          <td>{{ thousands_separators(item.restBudget) }}</td>
          <td>{{ thousands_separators(item.restConventionalObject) }}</td>
          <td>{{ thousands_separators(item.confirmedCredits) }}</td>
          <td>{{ thousands_separators(item.confirmedCredits + item.modificationCredits) }}</td>
          <td>{{ thousands_separators(item.totalPayments) }}</td>
          <td>{{ thousands_separators(item.totalInvoices) }}</td>
          <td>{{ thousands_separators(item.currentBudgetBalance) }}</td>
          <td>{{ thousands_separators(item.currentContractBalance) }}</td>
          <td>{{ thousands_separators(item.currentCreditBalance) }}</td>
          <td>{{ thousands_separators(item.creditControlAdjustment) }}</td>
          <td>{{ thousands_separators(item.modificationCredits) }}</td>
          <td>{{ thousands_separators(item.plannedPayments) }}</td>
          <td>{{ item.peeModification2015 }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-dialog v-model="addingDialog" width="800">
      <v-card v-if="addingDialog">
        <v-card-text>
          <v-form @submit.prevent="submit">
            <div class="d-flex flex-column">
              <v-text-field
                label="Έτος"
                v-model="correctYear"
                readonly
              ></v-text-field>

              <v-text-field
                label="Προϋπολογισμός προ δημοπράτησης"
                :value="
                  $store.state.projects.projectEditing.budgetBeforeAuction
                    | currency
                "
                disabled
              ></v-text-field>

              <v-text-field
                label="Μέση έκπτωση"
                :value="
                  (!!$store.state.projects.projectEditing.jobConstructorContract
                    ? $store.state.projects.projectEditing
                        .jobConstructorContract.meanDiscount
                    : 0) | currency
                "
                disabled
              ></v-text-field>

              <v-text-field
                label="Εργολαβική σύμβαση"
                :value="
                  (!!$store.state.projects.projectEditing.jobConstructorContract
                    ? $store.state.projects.projectEditing
                        .jobConstructorContract.amount
                    : 0) | currency
                "
                disabled
              ></v-text-field>

              <v-text-field
                label="Τελευταίος ΑΠΕ"
                :value="
                  (!!$store.state.projects.projectEditing.jobConstructorContract
                    ? $store.state.projects.projectEditing
                        .jobConstructorContract.lastApe
                    : 0) | currency
                "
                disabled
              ></v-text-field>

              <v-text-field
                label="Συμβατικό αντικείμενο"
                :value="addingDetail.conventionalObject | currency"
                disabled
              >
              </v-text-field>

              <v-text-field
                label="Εγκεκριμένος προϋπολογισμός (νομική δέσμευση)"
                :value="addingDetail.confirmedBudget | currency"
                disabled
              ></v-text-field>

              <v-text-field
                label="Προτεινόμενος προϋπολογισμός (νομική δέσμευση)"
                v-model="addingDetail.suggestedBudget"
                :hint="addingDetail.suggestedBudget | currency"
                persistent-hint
                :error-messages="addingDetailSuggestedBudgetErrors"
              ></v-text-field>

              <v-text-field
                label="Σύνολο πληρωμών προηγούμενης χρονιάς"
                :value="addingDetail.previousYearTotalPayments | currency"
                disabled
              ></v-text-field>

              <v-text-field
                label="Υπόλοιπο προϋπολογισμού (Ν/Δ) (Αρχή έτους)"
                :value="addingDetail.restBudget | currency"
                disabled
              >
              </v-text-field>

              <v-text-field
                label="(Ανεκτέλεστο) Υπόλοιπο συμβατικού αντικειμένου (Αρχή έτους)"
                :value="addingDetail.restConventionalObject"
                disabled
              ></v-text-field>

              <v-text-field
                label="Εγκεκριμένες πιστώσεις"
                :value="addingDetail.confirmedCredits | currency"
                disabled
              >
              </v-text-field>

              <v-text-field
                readonly
                label="Προτεινόμενες πιστώσεις"
                :value="
                  parseInt(
                    addingDetail.confirmedCredits +
                      addingDetail.modificationCredits
                  )
                "
                :hint="
                  parseInt(
                    addingDetail.confirmedCredits +
                      addingDetail.modificationCredits
                  ) | currency
                "
                persistent-hint
              ></v-text-field>

              <v-text-field
                :disabled="!hasErgolabikh"
                label="Πληρωμές"
                v-model="addingDetail.totalPayments"
                :hint="addingDetail.totalPayments | currency"
                persistent-hint
                :error-messages="addingTotalPaymentsErrors"
              ></v-text-field>

              <v-text-field
                label="Τιμολογήσεις"
                v-model="addingDetail.totalInvoices"
                :hint="addingDetail.totalInvoices | currency"
                persistent-hint
              ></v-text-field>

              <v-text-field
                  label="Τρέχον υπόλοιπο προϋπολογισμού"
                  v-model="addingDetail.currentBudgetBalance"
                  :hint="addingDetail.currentBudgetBalance | currency"
                  persistent-hint
              ></v-text-field>

              <v-text-field
                  label="Τρέχον υπόλοιπο συμβατικού αντικειμένου"
                  v-model="addingDetail.currentContractBalance"
                  :hint="addingDetail.currentContractBalance | currency"
                  persistent-hint
              ></v-text-field>

              <v-text-field
                  label="Τρέχον υπόλοιπο πιστώσεων"
                  v-model="addingDetail.currentCreditBalance"
                  :hint="addingDetail.currentCreditBalance | currency"
                  persistent-hint
              ></v-text-field>

              <v-text-field
                label="Προτεινόμενες πιστώσεις για ΠΕΕ νέου έτους"
                v-model="addingDetail.creditControlAdjustment"
                :hint="addingDetail.creditControlAdjustment | currency"
                persistent-hint
              ></v-text-field>

              <v-text-field
                  label="Τροποποίηση πιστώσεων"
                  v-model="addingDetail.modificationCredits"
                  :hint="addingDetail.modificationCredits | currency"
                  persistent-hint
              ></v-text-field>

              <v-text-field
                label="Αναλήψεις"
                v-model="addingDetail.withdrawals"
                :hint="addingDetail.withdrawals | currency"
                persistent-hint
              ></v-text-field>

              <v-text-field
                  label="Προβλεπόμενες πληρωμές τρέχοντος έτους"
                  v-model="addingDetail.plannedPayments"
                  :hint="addingDetail.plannedPayments | currency"
                  persistent-hint
              ></v-text-field>

              <v-list shaped elevation="5" class="my-4 pa-2">
                <h1 class="title mb-2">Αιτιολογίες Τροποποίησης</h1>
                <v-list-item-group
                  v-model="addingDetail.peeJobPendingModificationReasonIds"
                  multiple
                >
                  <template v-for="(item, i) in reasons">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item.id"
                      active-class="primary--text"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <v-row>
                <v-col cols="4">
                  <v-btn type="submit">Αποθηκευση</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="800">
      <v-card v-if="editDialog">
        <v-card-text>
          <v-form @submit.prevent="submitEdit" class="py-5">
            <v-text-field
              label="Έτος"
              :value="editItem.year"
              disabled
            ></v-text-field>

            <v-text-field
              label="Προϋπολογισμός προ δημοπράτησης"
              :value="
                $store.state.projects.projectEditing.budgetBeforeAuction
                  | currency
              "
              disabled
            ></v-text-field>

            <v-text-field
              label="Μέση έκπτωση"
              :value="
                (!!$store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .meanDiscount
                  : 0) | currency
              "
              disabled
            ></v-text-field>

            <v-text-field
              label="Εργολαβική σύμβαση"
              :value="
                (!!$store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .amount
                  : 0) | currency
              "
              disabled
            ></v-text-field>

            <v-text-field
              label="Τελευταίος ΑΠΕ"
              :value="
                (!!$store.state.projects.projectEditing.jobConstructorContract
                  ? $store.state.projects.projectEditing.jobConstructorContract
                      .lastApe
                  : 0) | currency
              "
              disabled
            ></v-text-field>

            <v-text-field
              label="Συμβατικό αντικείμενο"
              :value="editItem.conventionalObject | currency"
              disabled
            >
            </v-text-field>

            <v-text-field
              label="Εγκεκριμένος προϋπολογισμός (νομική δέσμευση)"
              :value="editItem.confirmedBudget | currency"
              disabled
            ></v-text-field>

            <v-text-field
              label="Προτεινόμενος προϋπολογισμός (νομική δέσμευση)"
              v-model="editItem.suggestedBudget"
              :hint="editItem.suggestedBudget | currency"
              persistent-hint
              :error-messages="editItemSuggestedBudgetErrors"
              :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
              label="Σύνολο πληρωμών προηγούμενης χρονιάς"
              :value="editItem.previousYearTotalPayments | currency"
              disabled
            ></v-text-field>

            <v-text-field
              label="Υπόλοιπο προϋπολογισμού (Ν/Δ) (Αρχή έτους)"
              :value="editItem.restBudget | currency"
              disabled
            >
            </v-text-field>

            <v-text-field
              label="(Ανεκτέλεστο) Υπόλοιπο συμβατικού αντικειμένου (Αρχή έτους)"
              :value="editItem.restConventionalObject"
              disabled
            ></v-text-field>

            <v-text-field
              label="Εγκεκριμένες πιστώσεις"
              :value="editItem.confirmedCredits | currency"
              disabled
            ></v-text-field>


            Προτεινόμενες πιστώσεις για ΠΕΕ νέου έτους
            <v-text-field
              label="Προτεινόμενες πιστώσεις"
              readonly
              :value="
                parseInt(
                  !!editItem.confirmedCredits ? editItem.confirmedCredits : 0
                ) +
                parseInt(
                  !!editItem.modificationCredits
                    ? editItem.modificationCredits
                    : 0
                )
              "
              :hint="
                parseInt(
                  editItem.confirmedCredits + editItem.modificationCredits
                ) | currency
              "
              persistent-hint
              :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <!-- :disabled="!hasErgolabikh" -->
            <v-text-field
              :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
              label="Πληρωμές"
              v-model="editItem.totalPayments"
              :hint="editItem.totalPayments | currency"
              persistent-hint
              :error-messages="editItemTotalPaymentsErrors"
            >
            </v-text-field>

            <v-text-field
              label="Τιμολογήσεις"
              v-model="editItem.totalInvoices"
              :hint="editItem.totalInvoices | currency"
              persistent-hint
              :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
                label="Τρέχον υπόλοιπο προϋπολογισμού"
                v-model="editItem.currentBudgetBalance"
                :hint="editItem.currentBudgetBalance | currency"
                persistent-hint
                :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
                label="Τρέχον υπόλοιπο συμβατικού αντικειμένου"
                v-model="editItem.currentContractBalance"
                :hint="editItem.currentContractBalance | currency"
                persistent-hint
                :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
                label="Τρέχον υπόλοιπο πιστώσεων"
                v-model="editItem.currentCreditBalance"
                :hint="editItem.currentCreditBalance | currency"
                persistent-hint
                :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
                label="Προτεινόμενες πιστώσεις για ΠΕΕ νέου έτους"
                v-model="editItem.creditControlAdjustment"
                :hint="editItem.creditControlAdjustment | currency"
                persistent-hint
                :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
              label="Τροποποίηση πιστώσεων"
              v-model="editItem.modificationCredits"
              :hint="editItem.modificationCredits | currency"
              persistent-hint
              :disabled="
                editItem.year > currentYear.getFullYear() ? true : false
              "
            ></v-text-field>

            <v-text-field
              label="Αναλήψεις"
              v-model="editItem.withdrawals"
              :hint="editItem.withdrawals | currency"
              persistent-hint
            ></v-text-field>

            <v-list shaped elevation="5" class="my-4 pa-2">
              <h1 class="title mb-2">Αιτιολογίες Τροποποίησης</h1>
              <v-list-item-group
                v-model="editItem.peeJobPendingModificationReasonIds"
                multiple
              >
                <template v-for="(item, i) in reasons">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                  <v-list-item
                    :disabled="
                      editItem.year > currentYear.getFullYear() ? true : false
                    "
                    v-else
                    :key="`item-${i}`"
                    :value="item.id"
                    active-class="primary--text"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-btn type="submit">Αποθηκευση</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { numeric, maxValue } from "vuelidate/lib/validators";
export default {
  created() {
    this.$store
      .dispatch("getFinancialDetails", this.$route.params.project_id)
      .then((res) => {
        if (res.data.data != null) this.details = res.data.data;
        console.log("details", this.details);
        this.addingDetail.year = this.correctYear;
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `${process.env.VUE_APP_BASEURL}/api/job/constructor/contract/peejob/${this.$route.params.project_id}`
      )
      .then((res) => {
        if (res.data.status == 0) {
          this.hasErgolabikh = true;
        }
        // console.log("ergolabos", res);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `${process.env.VUE_APP_BASEURL}/api/pee/job/pending/modification/reason/list`
      )
      .then((res) => {
        if (res.data.status == 0) {
          this.reasons = res.data.data;
          this.pageLoader = false;
        }
      });
  },

  data() {
    return {
      pageLoader: true,
      currentYear: new Date(),
      hasErgolabikh: false,

      reasons: [],

      bba: null,
      details: [],
      addingDialog: false,
      editDialog: false,
      editItem: {
        id: null,
        modificationCredits: null,
        creditControlAdjustment: null,
        peeJobId: null,
        suggestedBudget: null,
        confirmedCredits: null,
        totalInvoices: null,
        currentBudgetBalance: null,
        currentContractBalance: null,
        currentCreditBalance: null,
        totalPayments: null,
        year: null,
        withdrawals: null,
        peeJobPendingModificationReasonIds: [],
        plannedPayments: null,
        previousYearTotalPayments: null,
        restConventionalObject: null,
        restBudget: null,
      },
      addingDetail: {
        modificationCredits: 0,
        creditControlAdjustment: 0,
        peeModification2015: '',
        peeJobId: this.$route.params.project_id,
        suggestedBudget: 0,
        suggestedCredits: 0,
        confirmedCredits: 0,
        totalInvoices: 0,
        currentCreditBalance: 0,
        currentBudgetBalance: 0,
        currentContractBalance: 0,
        totalPayments: 0,
        year: this.correctYear,
        withdrawals: 0,
        peeJobPendingModificationReasonIds: [],
        plannedPayments: 0,
        previousYearTotalPayments: 0,
        restConventionalObject: null,
        restBudget: null,
      },
    };
  },

  methods: {

    thousands_separators(num) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },

    onEditItem(item) {
      console.log(item);
      this.editItem = { ...item };
      if (item.peeJobPendingModificationReasons != null)
        this.editItem.peeJobPendingModificationReasonIds =
          item.peeJobPendingModificationReasons.map((el) => el.id);
      console.log(this.editItem);

      this.editDialog = true;
    },

    submit() {
      this.$v.addingDetail.$touch();

      if (!this.$v.addingDetail.$invalid) {
        const addd = {
          modificationCredits: parseFloat(this.addingDetail.modificationCredits),
          creditControlAdjustment: parseFloat(this.addingDetail.creditControlAdjustment),
          peeJobPendingModificationReasonIds: this.addingDetail.peeJobPendingModificationReasonIds,
          peeJobId: this.$route.params.project_id,
          suggestedBudget: parseFloat(this.addingDetail.suggestedBudget),
          suggestedCredits: parseFloat(this.addingDetail.suggestedCredits),
          totalInvoices: parseFloat(this.addingDetail.totalInvoices),
          currentBudgetBalance: parseFloat(this.addingDetail.currentBudgetBalance),
          currentContractBalance: parseFloat(this.addingDetail.currentContractBalance),
          currentCreditBalance: parseFloat(this.addingDetail.currentCreditBalance),
          totalPayments: parseFloat(this.addingDetail.totalPayments),
          year: this.correctYear,
          withdrawals: parseFloat(this.addingDetail.withdrawals),
          plannedPayments:  parseFloat(this.addingDetail.plannedPayments),
        };
        this.$store
          .dispatch("addFinancialDetails", addd)
          .then((res) => {
            if (res.data.status === -1) {
              this.$store.dispatch("errorHandler", res.data.message);

              return;
            }
            this.$store.commit("toggleDialog", {
              open: true,
              text: "Επιτυχία",
            });

            console.log("Res", res);
            this.$store
              .dispatch("getFinancialDetails", this.$route.params.project_id)
              .then((res) => {
                this.details = res.data.data;
                this.addingDetail.year = this.correctYear;
                this.adding = false;
                this.addingDialog = false;
              })
              .catch((err) => {
                console.log(err);
                this.$store.commit("toggleSnackbar", {
                  open: true,
                  text: "Αποτυχία",
                });
              });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });
          });
      }
    },

    submitEdit() {
      this.$v.editItem.$touch();
      if (!this.$v.editItem.$invalid) {
        const credentials = {
          id: parseInt(this.editItem.id),
          body: {
            peeJobPendingModificationReasonIds:
              this.editItem.peeJobPendingModificationReasonIds,
            modificationCredits: parseFloat(this.editItem.modificationCredits),
            creditControlAdjustment: parseFloat(this.editItem.creditControlAdjustment),
            peeJobId: parseInt(this.$route.params.project_id),
            suggestedBudget: parseFloat(this.editItem.suggestedBudget),
            totalInvoices: parseFloat(this.editItem.totalInvoices),
            currentBudgetBalance: parseFloat(this.editItem.currentBudgetBalance),
            currentContractBalance: parseFloat(this.editItem.currentContractBalance),
            currentCreditBalance: parseFloat(this.editItem.currentCreditBalance),
            totalPayments: parseFloat(this.editItem.totalPayments),
            year: parseFloat(this.editItem.year),
            withdrawals: parseFloat(this.editItem.withdrawals),
            plannedPayments: parseFloat(this.editItem.plannedPayments),
          },
        };
        this.$store
          .dispatch("editFinancialDetails", credentials)
          .then((res) => {
            if (res.data.status === -1) {
              this.$store.dispatch("errorHandler", res.data.message);
              return;
            }
            this.$store.commit("toggleDialog", {
              open: true,
              text: "Επιτυχία",
            });

            this.$store
              .dispatch("getFinancialDetails", this.$route.params.project_id)
              .then((res) => {
                this.details = res.data.data;
                this.addingDetail.year = this.correctYear;
                // this.adding = false;
                this.editDialog = false;
              })
              .catch((err) => {
                console.log(err);
                this.$store.commit("toggleSnackbar", {
                  open: true,
                  text: "Αποτυχία",
                });
              });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("toggleSnackbar", {
              open: true,
              text: "Αποτυχία",
            });
          });
      }
    },
  },

  computed: {
    correctYear() {
      if (this.details.length > 1) {
        return this.details[0].year + 1;
      } else {
        const date = new Date();
        return date.getFullYear();
      }
    },
    editingItem() {
      return this.details[0];
    },

    // budgetBeforeAuction(){
    //  // console.log( this.$store.state.projects.projectEditing.budgetBeforeAuction)
    //   return this.$store.state.projects.projectEditing.budgetBeforeAuction
    // },

    editItemSuggestedBudgetErrors() {
      const errors = [];
      if (!this.$v.editItem.suggestedBudget.$dirty) return errors;
      if (!this.$v.editItem.suggestedBudget.numeric)
        errors.push("Πρέπει να είναι αριθμός");
      if (!this.$v.editItem.suggestedBudget.maxValue)
        errors.push(
          "Πρέπει να είναι μικρότερο απο τον 'Προϋπολογισμό προ δημοπράτησης'"
        );
      return errors;
    },
    addingDetailSuggestedBudgetErrors() {
      const errors = [];
      if (!this.$v.addingDetail.suggestedBudget.$dirty) return errors;
      if (!this.$v.addingDetail.suggestedBudget.numeric)
        errors.push("Πρέπει να είναι αριθμός");
      if (!this.$v.addingDetail.suggestedBudget.maxValue)
        errors.push(
          "Πρέπει να είναι μικρότερο απο τον 'Προϋπολογισμό προ δημοπράτησης'"
        );
      return errors;
    },
    editItemTotalPaymentsErrors() {
      const errors = [];
      if (!this.$v.editItem.totalPayments.$dirty) return errors;
      if (!this.$v.editItem.totalPayments.numeric)
        errors.push("Πρέπει να είναι αριθμός");
      if (!this.$v.editItem.totalPayments.maxValue)
        errors.push("Πρέπει να είναι μικρότερο από τις 'Τιμολογήσεις'");
      return errors;
    },
    addingTotalPaymentsErrors() {
      const errors = [];
      if (!this.$v.addingDetail.totalPayments.$dirty) return errors;
      if (!this.$v.addingDetail.totalPayments.numeric)
        errors.push("Πρέπει να είναι αριθμός");
      if (!this.$v.addingDetail.totalPayments.maxValue)
        errors.push("Πρέπει να είναι μικρότερο από τις 'Τιμολογήσεις'");
      return errors;
    },
  },

  validations() {
    return {
      editItem: {
        suggestedBudget: {
          numeric,
          maxValue: maxValue(
            this.$store.state.projects.projectEditing.budgetBeforeAuction
          ),
        },
        totalPayments: {
          numeric,
          maxValue: maxValue(this.editItem.totalInvoices),
        },
      },

      addingDetail: {
        suggestedBudget: {
          numeric,
          maxValue: maxValue(
            this.$store.state.projects.projectEditing.budgetBeforeAuction
          ),
        },
        totalPayments: {
          numeric,
          maxValue: maxValue(this.addingDetail.totalInvoices),
        },
      },
    };
  },
};
</script>

<style></style>
